._2q7694SdUQVAxvaqv_ouzT {
  position: fixed;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  padding: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--color-background-asset);
}

._1Ao7x65M570jdOKuHOQkLb {
  width: 160px;
  height: 160px;
  border-radius: 160px;
  background: var(--color-player-default3);
  fill: var(--color-background-asset);
}

._1P_4KoAFXZiEqDK5zlU-Yv {
  font-weight: 700;
  color: var(--color-player-default1);
}
