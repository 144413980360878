._2EunF68Deg5yTQdRiV6zLG {
    max-width: 1024px;
    height: 100%;
    margin: 0 auto;
  }
  
  ._1NDa4CPZJgCv7MoFKbOwgs {
    font-weight: 600;
    margin-bottom: 70px;
  }
  
  ._1agwQgnpRHW0nFZLYZ3ASy {
    margin: 0 20px;
  }
  
  ._20VjCjGVVLUykrNFYBCGHy {
    font-size: var(--fontSize-m);
    font-weight: normal;
    color: var(--color-content-default1);
    margin-bottom: 50px;
  }
  
  ._20VjCjGVVLUykrNFYBCGHy:nth-child(2n) {
    margin-bottom: 20px;
    color: var(--color-content-highlight-hover);
    font-weight: bold;
  }
  .Collapsible__trigger ._3yodlInpayupUR8mBmkw1u {
    width: 20px;
    height: 20px;
    transform: rotate(-270deg);
  }
  
  .Collapsible__trigger ._3yodlInpayupUR8mBmkw1u path {
    stroke: var(--color-content-default1);
  }
  
  .Collapsible__trigger.is-open ._3yodlInpayupUR8mBmkw1u {
    transform: rotate(270deg);
  }
  ._2navfED22CrIN_bZHttDI5{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid var(--color-primary3);
    margin-bottom: 20px;
    width: 100%;
  }
  ._1vNt_actMBn77-OqvcOE_m{
    width: 100%;
  }
  ._38McK6KujvRLTwEOIT8yqw {
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-primary1);
  }
  ._2RFHL8qwNXE09ESOxQhcwT {
    justify-content: space-between;
    padding: 0;
  }
  .xCR8COS3lg1A_0rPF50ox{
    font-size: 14px;
    color: var(--color-primary2);
  }
  ._3mxogdg52RQrXcVleUIxzm{
    min-height: 80px;
    border-bottom: 1px solid var(--color-primary3);
    width: 100%;
    display: grid;
    color: var(--color-primary1);

  }
  ._2kpbQKgsEAhJW_6SMAY3Tc{
    width: 100%;
    display: flex;
    justify-content: space-between;

  }
  ._3Q_VnQxgfJt_9_sSpv6TB-{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  ._10zKkDP2-SHUCO04Yre2Gh{
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:var(--color-content-highlight-hover)
  }
  ._1Xw-fYCcWq3mh_D2e1LPBi {
    cursor: pointer;
  }
  ._1O6i0Sc7WfLp3FQ2lKw_cT {
    max-width: 490px;
    color: var(--color-content-default1);
  }
  @media(max-width: 767px) {
    ._1NDa4CPZJgCv7MoFKbOwgs {
      margin-bottom: 40px;
    }
  
    ._20VjCjGVVLUykrNFYBCGHy {
      font-size: var(--fontSize-s);
      margin-bottom: 30px;
    }
  
    ._20VjCjGVVLUykrNFYBCGHy:nth-child(2n) {
      margin-bottom: 10px;
    }
  }