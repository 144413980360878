._a4UAoSWLtzvSb1wGbEI3 {
  cursor: pointer;
  text-decoration: none;
  margin: 0 var(--marginBetweenThumbnails) 0 0;
  position: relative;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
  overflow: hidden;
  min-height: 266px;
  min-width: 200px;
}
._34iPMt5HK_B5OboSixGjmz {
  margin: 0;
}
._3yagyDSiFkpxPir0wcLkki {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
  width: 40px;
  height: 40px;
  text-align: left;
}
._1Sa7Qkt40_lpboszX-AJQc {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}
._1Pk5ou78VdDBUXmJ6cnP1L {
  transform: scale3d(1.1, 1.1, 1.1);
  z-index: 10;
}

._19HzcC-Bak61uExvO4XseY {
  position: relative;
}
._7FIpQSXNEsw1zj8LCaX6b {
  position: relative;
  background-color: var(--color-save-bar-bg);
}

._7FIpQSXNEsw1zj8LCaX6b:hover {
  background-color: var(--color-secondary1);
}

._2ztEEgvJO5TFQ8vw-6MFan img {
  object-fit: unset !important;
}

._3Z6G6d40duWbFhU2yNFhy7._1Pk5ou78VdDBUXmJ6cnP1L {
  transform: scale3d(1.05, 1.05, 1.05);
  z-index: 10;
}

._3-gPDzHMrmCewL0hclC3k_,
._129U-tNiF4pwMzz7387IO9 {
  position: absolute;
  bottom: 0;
  z-index: 50;
}

._2uuGI6AlE6Yp5Nz0QdsCWC {
  background: var(--color-content-overlay3);
}

.AomVtg9n33CLI3656PUeA {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
._35gAwdrf_HOfOJMvPWhu9- {
  fill: var(--color-thumbnail-highlight-hover1);
}
._3wp__zTD8X9duRLFkO4Dqg {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
._1RACkZOyS8CZq8J6mG4YYi {
  position: absolute;
  margin-left: 24px;
  margin-top: 14px;
  width: 12px;
  height: 11px;
  display: flex;
  align-items: center;
  object-fit: contain;
  fill: var(--color-primary1);
  z-index: 5;
  bottom: 5px;
  right: 5px;
}

._23nidp2-4RWVmLGEp4BH3F {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
  width: 40px;
  height: 40px;
}

._3-gPDzHMrmCewL0hclC3k_,
._129U-tNiF4pwMzz7387IO9 {
  position: absolute;
  bottom: 0;
  z-index: 50;
}

._1Pk5ou78VdDBUXmJ6cnP1L ._129U-tNiF4pwMzz7387IO9 {
  bottom: 3px;
}
._1tnjNqr2ZyLTCF3dAhbwBm {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}
._1tnjNqr2ZyLTCF3dAhbwBm::before,
._1tnjNqr2ZyLTCF3dAhbwBm::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

._1tnjNqr2ZyLTCF3dAhbwBm::before {
  border-width: 20px;
}

._1tnjNqr2ZyLTCF3dAhbwBm::after {
  border-width: 20px;
  border-right-color: var(--color-border-right-padlock-container);
  border-top-color: var(--color-border-top-padlock-container);
}

._1c8orABkc6HMqwrhAxgOBb {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}

._1HNlMumOH4kZF23R7zBwXV > svg {
  width: 16px;
  fill: var(--color-icon-playlock-fill);
}

._25K9o8SaaztM8YHYwIWx_i {
  border-radius: var(--border-radius-thumbnail-small);
}

._1mo09bhIfA8CevqYz9pVd1 {
  border-radius: var(--border-radius-thumbnail-medium);
}

.owR3HkPlRG2UEKXKib4Jv {
  border-radius: var(--border-radius-thumbnail-large);
}

.c8VGrMMVdhIfhPd2lG6E3 {
  box-shadow: var(--shadow-thumbnail-small);
}

.XN1ANJzvQVlXiRF_iH6DG {
  box-shadow: var(--shadow-thumbnail-medium);
}

._1DxN5LPc2kM-1T66rp4qb0 {
  box-shadow: var(--shadow-thumbnail-large);
}
