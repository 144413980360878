._2j2XbZCBzeu0xvX3I5ebyI {
  margin-bottom: 0;
}

._2FBN3lJs5RRfmImAsm5qYk {
  margin: 0;
  margin-bottom: 30px;
  color: var(--color-content-default3);
}

._1Lx3amvyWo7bkNRrJflYSv {
  margin: 20px 0 40px;
}
