:root {
  --FormProfile-img-width: 80px;
}

._2m_jXgw3QfRQtWloGUv4Gs {
  position: relative;
}

._2iRkvObx0ZTBh3NFtM6eox {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--FormProfile-img-width), auto)
  );
  grid-column-gap: 11px;
  grid-row-gap: 10px;
}

._2QJEl4GJod3bwPglCBJVZ8 {
  display: flex;
  align-items: center;
  position: relative;
  width: 90px;
  height: 90px;
}

.jI5QqKuRxNC_R4y51Y6tz {
  padding-left: 5px;
  margin-top: 30px;
  font-size: var(--fontSize-xl);
  font-weight: bold;
  color: var(--color-content-default1);
}

._3lfkP-Xfnv-nujQkLlONKD {
  margin-top: 5px;
  margin-bottom: 50px;
  display: grid;
}

.xUPvJMPhQNFWJu6vYIHZ4 {
  color: var(--color-content-default3);
  vertical-align: middle;
}

.xUPvJMPhQNFWJu6vYIHZ4 > span {
  color: #fff;
  cursor: pointer;
}

._1E-tcloiGMpPKOR0km2Kz8 {
  justify-content: space-between;
}

._2I7D7ViP6KAtppU6hZ7B_g {
  padding-left: 10px;
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  line-height: 1.43;
}

._1nBisS3Fpplts8AejujpBW {
  padding: 10px;
}

._23k50EPt2bH9rz4DVF4eQt {
  padding-left: 10px;
  font-size: var(--fontSize-m);
  color: var(--color-content-default1);
  max-width: 100%;
}

._3Dgco2IfmYfT07pl7Kcuyz {
  display: block;
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  margin-top: 10px;
}

._1ckPEhw9wh4Z-YKuscod69 {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-save-bar-bg);
  padding: 20px 50px;
  text-align: right;
}

._34NIulGrX0t57w_PDozD2E {
  margin-right: 40px;
}

._2GzgcJoQFRsMw9kHE2GIep {
  margin-right: 40px;
  min-width: 200px;
  font-size: var(--fontSize-s);
  font-weight: 600;
}

._1aILz3Pcy0kbzWRVKDTK8a {
  font-size: var(--fontSize-s);
  min-width: 200px;
  font-weight: 600;
}

._3_b-PpRRZyjzhHlb8pJUcr {
  font-size: var(--fontSize-s);
  min-width: 200px;
  font-weight: 600;
  display: block;
  margin: auto;
}

.ndoGDJ8tl8GmjrPnHevQr {
  background: transparent url(/assets/profile_default.png)
    no-repeat !important;
  width: 80px;
  height: 80px;
  display: block;
  background-size: contain !important;
  margin: auto;
}

._3oiqYR-c0Lpr_JjsocnRxV {
  margin-right: 10px;
}

/* .buttonContainer {
  position: absolute;
  top: 200px;
  right: 0;
} */

._3lfkP-Xfnv-nujQkLlONKD {
  max-width: 600px;
}

._3ScCPJcyfpUA5eISXJYFXy {
  max-width: calc(100% - 200px);
}

._5eAupXkEf0qRVon_4g6u9 {
  max-width: 300px;
}

._3SET35GtBHAo3cTtWE1Y9C {
  display: flex;
  justify-content: space-between;
}

.t_2TlIgOVLv4pWuDFxR26 {
  border: solid 1px var(--color-content-default3);
  border-radius: 20px;
  width: 66px;
  height: 26px;
  text-align: center;
}

._3n_zCGeDD6VKtm0fJ87f1h {
  table-layout: auto;
  padding-left: 10px;
}

._38bzNVE7_YBtf9luEQtn6I {
  width: 30px;
  vertical-align: top;
  padding-top: 9px;
}

._8-55m1uNHXLYJ-hyu913V {
  width: 4px;
  display: flex;
  justify-content: center;
}

._3Rf8G1s2YXMi-PZSXZtWCP {
  width: auto;
}

._3v5gQV8I1UpkkJ5REoPsVG {
  fill: var(--color-content-default1);
  height: 24px;
  width: 24px;
}

.uYoMEpn38c6LsLrg73KQu {
  width: 1px;
  height: 38px;
  background-color: var(--color-primary4);
}

._3Dgco2IfmYfT07pl7Kcuyz {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
  padding-left: 10px;
}

._17n-8VG21NYiyYHU40bKhT {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  width: fit-content;
}

.axo11Dub9yF8WU0z4hQUy {
  font-weight: bold;
  padding-left: 10px;
  font-size: var(--fontSize-xxl);
  color: var(--color-content-default1);
}

.tnj7ocyWSKBbWvRdl3USv {
  background-color: var(--color-content-highlight-hover);
  text-align: center;
  color: var(--main-profile-label-font, var(--color-background-asset));
  position: absolute;
  border-radius: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  padding: 3px;
  font-size: var(--fontSize-xs);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
}

.cSo6yF8wnAET_a4NLVwSf {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

._3pdsEdqT-yF1rIu_ocWGN_ {
  max-width: 300px;
  border-bottom: 1px solid var(--color-input-border-bottom);
}

._3pdsEdqT-yF1rIu_ocWGN_ details summary {
  width: 285px;
  padding: 0px;
}

._3pdsEdqT-yF1rIu_ocWGN_ details > div {
  left: -11px;
  bottom: 0;
}

@media (max-width: 1023px) {
  ._1ckPEhw9wh4Z-YKuscod69 {
    padding: 20px;
  }

  ._2hZ258V5g_HhVO8xV39h6w,
  ._34NIulGrX0t57w_PDozD2E {
    margin-right: 20px;
  }

  ._2GzgcJoQFRsMw9kHE2GIep,
  ._3VR8CUfwZz_gaN9JilVT_J {
    min-width: 150px;
  }
}

@media (max-width: 767px) {
  ._3BJApIaMo36QAH8cua6G2Q {
    margin-top: 0;
    padding-right: 0;
    position: static;
    text-align: center;
  }

  ._17n-8VG21NYiyYHU40bKhT {
    margin-bottom: 30px;
  }

  .cSo6yF8wnAET_a4NLVwSf {
    width: 80px;
    height: 80px;
  }

  .ndoGDJ8tl8GmjrPnHevQr {
    background-size: 800px;
    width: 80px;
    height: 80px;
  }

  ._3ScCPJcyfpUA5eISXJYFXy {
    max-width: 100%;
  }

  ._1ckPEhw9wh4Z-YKuscod69 {
    display: inline-block;
    text-align: left;
  }

  ._2GzgcJoQFRsMw9kHE2GIep {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ._2m_jXgw3QfRQtWloGUv4Gs {
    height: 15rem;
  }
}
