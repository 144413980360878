._3h31bU_7DdUrxh2xFcOI0v {
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
}

._38PCL8WHiQ3CGy8lopaDfx {
  color: var(--color-thumbnail-primary1);
  font-size: var(--fontSize-m);
  font-weight: 700;
  margin: 10px 0 0;
}

._18FbIIkbA5rGrAGErWQI4p {
  color: var(--color-thumbnail-primary1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 5px 0;
  font-size: var(--fontSize-s);
}

._307qhSP5C8ph7AhIkyWq9y {
  color: var(--color-thumbnail-primary3);
  font-size: var(--fontSize-s);
  margin: 0;
}

._13Y2p0BeAIaRKDDujKdzr- > svg {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  z-index: -1;
}

._13Y2p0BeAIaRKDDujKdzr- path {
  fill: var(--color-thumbnail-primary3);
}

@media (max-width: 767px) {
  ._3h31bU_7DdUrxh2xFcOI0v {
    padding: 10px 10px 15px;
  }

  ._18FbIIkbA5rGrAGErWQI4p,
  ._307qhSP5C8ph7AhIkyWq9y {
    margin: 0;
  }
}
