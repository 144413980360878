._3i3RXKUXX7O9w6cbS-4hmg {
  width: 100%;
  margin-top: 10px;
  z-index: 10;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  font-size: var(--fontSize-s);
  line-height: 18px;
  min-height: 50px;
}

._5KZ_1Oa8fJnUfPR-gAPPf {
  color: var(--color-thumbnail-highlight-hover1);
  font-weight: 700;
  text-transform: uppercase;
}

.iYmBV91vcRdBoPlb8F6Nf {
  color: var(--color-thumbnail-primary1);
  font-weight: 700;
  font-size: var(--fontSize-m);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: ;
}

._5LlZvUFMbX1bIdQU9DLUh {
  color: var(--color-thumbnail-primary3);
  text-transform: uppercase;
}

._2MwZZF7KQ-G3UaSvl65ad_ svg {
  fill: var(--color-slideshow-primary3);
  width: 11px;
  height: 14px;
}

._2MwZZF7KQ-G3UaSvl65ad_ {
  width: 11px;
  height: 14px;
  position: relative;
  top: 3px;
}

._2S5gNUST9BhoM6Xy5WC6B {
  background: rgba(255, 255, 255, 0.25);
  width: calc(100% - 56px);
  margin-left: 28px;
  margin-top: 10px;
}
