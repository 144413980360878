._37v5nSR8vUS7RjGcOWXs8s {
  width: 100%;
  height: 100%;
}

#_2FDR62RPdKoTDUxkHCmIkB {
  height: 75%;
}

.pNrJqmIUffuesxcN1tg8b {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: opacity .03s;
  opacity: 1;
}

._2n7W0CTuUyAexA_UAm5vuk {
  background: var(--color-thumbnail-overlay-background);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 250ms cubic-bezier(.21,.43,.37,.99);
  z-index: 0;
}

.A4LnrxK8FSEEL58XCO7ey {
  opacity: 0.8;
  z-index: 0;
}
