:root {
  --Modal-margin: 30px;
}

@media (max-width: 767px) {
  :root {
    --Modal-margin: 20px;
  }
}

._3WtmqOf8vSu2LHW7zBMXH3 {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

._1c2SWMUItv1xFbzYvjbGaW {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}

._2eBjWPrdOsVMScDLXjy7Rb {
  background-color: blue !important;
  height: 10rem;
}

._183_QinIKZ89EbX7ws8Xnc {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}

._1oTvBiA-16f1O8V9AJIbDD {
  z-index: 99999;
  background-color: var(--color-modal-overlay);
}

.WpbNZWPrGRqCOv7KoiGQr {
  position: fixed;
  background-color: var(--color-content-overlay1);
  border-radius: 3px;
  top: 50%;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

.VEMLAMvcehxFy1Ndo8vgK {
  width: 640px;
}

.NhTrcGoDcOzppNAYpMqy4 {
  width: 490px;
}

._2ebmHRCJZ5R8XcpFqmx8vy {
  min-width: 330px;
}

._3QLhPD9zrRRCixXHXRgvUh {
  padding: var(--Modal-margin);
  max-height: calc(90vh - 28px - (var(--Modal-margin) * 4));
  overflow: auto;
  height: fit-content;
  width: auto;
}

._1Gr4D37uZLvPvAZhooZ_lI {
  max-height: auto;
  /* padding-top: 0; */
  margin-bottom: 17px;
}

._1oqVoQ8NR9VOvZ0C-_Cfqp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: var(--Modal-margin);
  /* padding-right: calc(var(--Modal-margin) + 30px); */
  position: relative;
  text-align: left;
}

._1NNsv4U2IDoq3ZhWNC84B9 {
  flex-direction: row-reverse;
}

._1IVK-YTrxNpYMp0Cl_eIKI {
  text-align: end !important;
}

._1x-sWTl4C6EAPBIRGcIv3L {
  display: inline-block;
  width: 80%;
}

._21Az0t4I40BlkRAXLbx_sR {
  width: 70% !important;
}

._3fSa3l02fddWdRH3mS4MjK {
  width: 61% !important;
}

._1oQAWHAYSSpz0jGd3-28Ru {
  width: 68% !important;
}

._2wSE_3zvHWTg4shhXgou-q {
  display: inline-block;
  width: 20%;
}

._2wSE_3zvHWTg4shhXgou-q ._2SXTXQ50AHgaPInnNaFYID > a {
  height: 0px;
}

._2wSE_3zvHWTg4shhXgou-q ._2SXTXQ50AHgaPInnNaFYID > a > span {
  margin: 0 20px;
}

._2wSE_3zvHWTg4shhXgou-q ._2SXTXQ50AHgaPInnNaFYID > a > div {
  height: auto;
  top: 0px;
}

._2wSE_3zvHWTg4shhXgou-q ._2SXTXQ50AHgaPInnNaFYID > a > div span {
  margin-left: 0;
}

._2wSE_3zvHWTg4shhXgou-q ._2SXTXQ50AHgaPInnNaFYID > div {
  top: 70px !important;
}

._1x-sWTl4C6EAPBIRGcIv3L ._8egyi5ZRoBGAUCV9cjEZS {
  font-size: var(--fontSize-m);
  font-weight: bold;
  margin: 0;
}

._1x-sWTl4C6EAPBIRGcIv3L ._8egyi5ZRoBGAUCV9cjEZS h3 {
  margin: 0 0;
}

._1x-sWTl4C6EAPBIRGcIv3L ._3VMUP-6rl-9_Bgh4IC52fl {
  width: 100px;
}

.VmbiLLdpYTT_no-mLXnAL {
  display: flex;
}

._2QlJsVodlHqD3fqfmNgIbt {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary1);
  text-decoration: none;
  cursor: pointer;
}

._2QlJsVodlHqD3fqfmNgIbt > span {
  bottom: 9px;
  position: relative;
}

._3l2E_NjW5Bc0e0F2DWdOYK {
  width: 24px;
  height: 32px;
  margin: auto 0;
  transform: rotate(180deg);
  stroke: var(--color-player-default1);
  stroke-width: 2px;
  transition: stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

._1oqVoQ8NR9VOvZ0C-_Cfqp ._1s_J9Xej2YC0PSUPDK_VEo {
  position: absolute;
  top: var(--Modal-margin);
  right: 20px;
  width: 24px;
  cursor: pointer;
  fill: var(--color-content-default1);
}

._2SXTXQ50AHgaPInnNaFYID {
  margin-right: 60px;
  z-index: 9999;
}
._34DKHzxGJemnBnYcJE57wv {
  margin-right: 0;
}

.WpbNZWPrGRqCOv7KoiGQr h1 {
  margin-top: 2px;
}

._1BzaI7cjnlxRS_wu7gvnb {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

._2RVvNqbUo9u7vRKMEilHDe {
  /*  width: calc(100% - (2 * var(--Modal-margin))); */
  width: 100%;
  display: inline-block;
  padding: 0 var(--Modal-margin) var(--Modal-margin);
  color: var(--color-content-highlight-hover);
  font-weight: bold;
  font-size: var(--fontSize-s);
  cursor: pointer;
}

@media (max-width: 767px) {
  ._2wSE_3zvHWTg4shhXgou-q {
    width: 40%;
  }
  ._1c2SWMUItv1xFbzYvjbGaW {
    top: 60px;
    height: calc(100% - 60px);
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .WpbNZWPrGRqCOv7KoiGQr {
    max-height: 100vh !important;
    min-width: fit-content !important;
    width: inherit;
    text-align: left;
  }

  ._1oqVoQ8NR9VOvZ0C-_Cfqp ._8egyi5ZRoBGAUCV9cjEZS {
    font-size: var(--fontSize-s);
  }

  ._183_QinIKZ89EbX7ws8Xnc {
    /* transform: scale(0.6); */
    opacity: 1;
    transition: all 0.3s;
  }
}
