._2PxQPO6iwkOWWF3SwYKX60 {
  width: 100%;
  text-align: center;
  color: var(--color-content-default1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

