._34XEzXi88R902qvlQ38PuB {
  cursor: pointer;
  text-decoration: none;
  /* margin: 0 var(--marginBetweenThumbnails) 0 0; */
  position: relative;
  transition: all 250ms cubic-bezier(.21,.43,.37,.99);
  overflow: hidden;
}
._2YhAqJSOmbXnPxpFEODV-b{
  margin: 0;
}
._2DVow9AATxB5a1MddnXmf {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
  width: 40px;
  height: 40px;
  text-align: left;
}
.jIqPBG6NTBWIUmqlqiHMo {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}
._1Em3HP4Kg_Lp_S7lqezEi0 {
  transform: scale3d(1.1, 1.1, 1.1);
  z-index: 10;
}

._e34_40rumHCqEKMQkN9e {
  position: relative;
  min-width: 200px;
}

._3vdaid4fnWPeEHJM_XX9Yw {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
  width: 40px;
  height: 40px;
}

._3d74gkaWX7t49ihDVL2SRl._1Em3HP4Kg_Lp_S7lqezEi0 {
  transform: scale3d(1.05, 1.05, 1.05);
  z-index: 10;
}

.rMfXGXdI-Xdt-CcOps8Y8, .WHDCy9CNSXJaTjoKRNelO {
  position: absolute;
  bottom: 0;
  z-index: 50;
}

._14vnEYCkB0d_Lxrt2WtOFi {
  background: var(--color-content-overlay3);
}

.SZn0R2mZTQIlDR_k4QmPa{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
.TZGwICjUfe491kB-0zT5Y{
  fill: var(--color-thumbnail-highlight-hover1);
}