._1nF7j6_txzD0dfPDHkGwm0 {
  width: 100%;
  height: 148px;
  margin-bottom: 20px;
}

._29i6rLzEKAla7mO7jfqd39 {
  float: left;
  margin-right: 20px;
  position: relative;
  width: 270px;
  cursor: pointer;
}

._3Yl5PpcjOJXD6F2B2kPov2 {
  height: 100%;
  overflow: hidden;
}

._29i6rLzEKAla7mO7jfqd39:hover .Lu9HpqmQn89edyClKAb6p {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.Lu9HpqmQn89edyClKAb6p {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 26%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
}

.Lu9HpqmQn89edyClKAb6p circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._1GjI9_q_jXpVCLGwA3rY4P {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 28px;
  line-height: 28px;
  width: 70px;
  font-size: var(--fontSize-s);
  font-weight: 500;
  text-align: center;
  background-color: var(--color-background-asset);
  opacity: 0.8;
  color: var(--color-content-default3);
}

._2SFNhdmWZZpP0dAPdeRHUn {
  color: var(--color-content-highlight-hover);
  font-size: var(--fontSize-m);
  margin-bottom: 5px;
  display: inline-flex;
}

._2QEzcy0ejcu2YqPvCWWQz4 {
  font-size: var(--fontSize-l);
  color: var(--color-content-default1);
  font-weight: bold;
  margin-bottom: 10px;
}

._2eXmSPubcljRd8kfad_UJ6 {
  height: 59px;
  font-size: var(--fontSize-m);
  color: var(--color-content-default3);
  line-height: 1.25;
  padding: 0 6px 0 0;
  overflow-y: hidden;
}

._2eXmSPubcljRd8kfad_UJ6:hover {
  padding: 0;
  overflow-y: scroll;
}

._15EAMkBRqXy5BnnvNiuuow {
  float: right;
  margin-right: 10px;
}

._2bmxKc__yklESSpRpqFARY {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 0;
  width: 40px;
  height: 40px;
  text-align: left;
}

._2nHyMVchoZO_pJ5X8xdcez {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}

@media (max-width: 767px) {
  ._1nF7j6_txzD0dfPDHkGwm0 {
    margin-bottom: 30px;
  }

  ._29i6rLzEKAla7mO7jfqd39 {
    width: 100%;
  }

  ._3Yl5PpcjOJXD6F2B2kPov2 {
    width: 100%;
    float: left;
  }

  ._2QEzcy0ejcu2YqPvCWWQz4 {
    font-size: var(--fontSize-m);
  }

  ._2SFNhdmWZZpP0dAPdeRHUn,
  ._2eXmSPubcljRd8kfad_UJ6 {
    font-size: var(--fontSize-s);
  }

  ._2eXmSPubcljRd8kfad_UJ6 {
    height: 54px;
  }

  ._2SFNhdmWZZpP0dAPdeRHUn {
    margin-top: 15px;
  }
}
._2dAeBccGJzow8Zj0YHbjSL {
  margin-left: 7px;
  color: var(--color-content-default3);
}
