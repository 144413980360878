:root {
  --PageSearch-gridLandscape-width: 270px;
  --PageSearch-gridPortrait-width: 171px;
  --PageSearch-grid-rowGap: 30px;
}

@media (max-width: 767px) {
  :root {
    --PageSearch-gridLandscape-width: 157px;
    --PageSearch-gridPortrait-width: 131px;
    --PageSearch-grid-rowGap: 15px;
  }
}

._3RGXOA2qvD4YE3wq8xlNPL {
  min-height: calc(100vh - 140px); /* Top margin + bottom margin */
}

._2mxxCUjvPm4KIanDADxD7G {
  margin-top: 20px;
  height: 40px;
  width: 100px;
}

._1sMJ2ZQB_t8o5TykW_QzIf {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageSearch-gridLandscape-width), 1fr)
  );
  grid-row-gap: var(--PageSearch-grid-rowGap);
}

._3yoqMPS831nWTVJ_oxmBd6 {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageSearch-gridPortrait-width), 1fr)
  );
  grid-row-gap: var(--PageSearch-grid-rowGap);
}

._39npahHGnwZvd0WfmVtfoC {
  padding-left: 20px;
  margin-bottom: 70px;
}

._10PyOwgP4Gf7I42BPOboa3 {
  text-align: center;
  margin-top: 100px;
  padding: 5px;
}

.ocKe0AADoNQJXMLBPldcr .react-tabs__tab {
  padding: 0 10px 20px 10px;
  border: none;
  background-color: transparent;
  color: var(--color-content-default3);
  font-weight: bold;
}

.ocKe0AADoNQJXMLBPldcr .react-tabs__tab--selected {
  border-bottom: 4px solid var(--color-content-highlight-hover);
  background-color: transparent;
  color: var(--color-content-default1);
}

.ocKe0AADoNQJXMLBPldcr .react-tabs__tab-list {
  margin: 0 0 20px 0;
}

._327Kf8saOTXwFlTr5uz51u {
  margin-top: 40px;
  font-weight: 900;
}

._1uAogsV80J2RHPC_Bn2b8b {
  text-align: center;
  padding: 20px;
}

.qp2XNkqGpuSprBn5_Dzcp,
.qp2XNkqGpuSprBn5_Dzcp > svg {
  width: 80px;
  transform: rotate(90deg);
}

.qp2XNkqGpuSprBn5_Dzcp path {
  fill: var(--color-content-overlay3);
}

.qp2XNkqGpuSprBn5_Dzcp circle {
  fill: var(--color-content-default3);
}
