:root{
  --progressBarHeight: 3px;
}

._1dCyFjAm5nrcM_ap_TC7SD {
  width: 100%;
  height: var(--progressBarHeight);
  background: var(--color-content-progressbar-buffer-empty1);
  position: relative;
}

._12dI3exFtdJhcT_5g4DUvz {
  cursor: pointer;
}

._1CgemTxfWg8MRqwZtTgmD4 {
  max-width: 100%;
  height: 100%;
  background: var(--color-content-progressbar-main);
  border-radius: 8px;
}

._1-sw5D0JtVuPOi7PigcxAM {
  font-size: var(--fontSize-s);
  font-weight: bold;
  position: absolute;
  top: -8px;
  height: 23px;
  line-height: 23px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background: var(--color-content-highlight-hover);
  box-sizing: border-box;
  text-align: center;
  color: var(--color-secondary3);
  overflow: hidden;
  padding: 0 5px
}

.s7h7Kji2-GERl2fogqnKF {
  position: absolute;
  top: -7px;
  width: 20px;
  height: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 3px var(--color-content-default1);
  border-radius: 24px;
  background: var(--color-content-progressbar-main);
  box-sizing: border-box;
}

@media (max-width: 767px) {
  ._1-sw5D0JtVuPOi7PigcxAM {
    background: var(--color-content-default1);
    font-size: var(--fontSize-xs);
  }
}
