:root {
  --Modal-margin: 30px;
}

@media (max-width: 767px) {
  :root {
    --Modal-margin: 20px;
  }
}

._1tLv3Jk0YnMBJmnAZLGEBv {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

._2-ZAmepR6OQlx1ZpYHhQaj {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}

._32YzYaDRjHm4Rn6QgByne8 {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}

._37RiJMM8aJE7GUsy0hAGYj {
  z-index: 99999;
  background-color: var(--color-modal-overlay);
}

._28ginvauO_omZNa01lQVSK {
  position: fixed;
  background-color: var(--color-content-overlay1);
  border-radius: 3px;
  top: 50%;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 490px;
}

._2bgLIS-DIxEHNgI_jYwnrX {
  width: 640px;
}

._213WVqfZif5qa756gNx0SQ {
  padding: var(--Modal-margin);
  max-height: calc(90vh - 28px - (var(--Modal-margin) * 4));
  overflow: auto;
  height: fit-content;
  width: auto;
}

._213WVqfZif5qa756gNx0SQ h4{
  margin: 30px 0 0 0;
}

._213WVqfZif5qa756gNx0SQ p{
  font-size: 17px;
  letter-spacing: .3px;
  line-height: 1.7;
  margin-top: 15px;
}

._1-viFktHCGi7cDVWhtZSA2 {
  max-height: auto;
}

._2aQ5dfyA0s-nK3WaA6RSA- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  position: relative;
  text-align: left;
}

._3eVdP24AlXoZ-c9Gnqg-Sr {
  flex-direction: row-reverse;
}

._2aQ5dfyA0s-nK3WaA6RSA- ._2foGV9VXZ7Efgp70ZXLcry {
  position: absolute;
  top: var(--Modal-margin);
  right: 20px;
  width: 24px;
  cursor: pointer;
  fill: var(--color-content-default1);
}


._5Q7cH_cUfdyxvRLCzkTEk {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}


._2qLuU3OZRXh8hepOSNTpcG{
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

._2qLuU3OZRXh8hepOSNTpcG ._1Qc3EU30DVz3QDQVbfJV4B{
  line-height: 27px;
  margin: 0 0 0 5px;
}

@media (max-width: 767px) {
  ._2-ZAmepR6OQlx1ZpYHhQaj {
    top: 60px;
    height: calc(100% - 60px);
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  ._28ginvauO_omZNa01lQVSK {
    max-height: 100vh !important;
    min-width: fit-content !important;
    width: inherit;
    text-align: left;
  }
  ._2aQ5dfyA0s-nK3WaA6RSA- ._3rJZ8UTJ7UcoREdm1kHHS2 {
    font-size: var(--fontSize-s);
  }
  ._32YzYaDRjHm4Rn6QgByne8 {
    opacity: 1;
    transition: all 0.3s;
  }
}
