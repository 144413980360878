._2JDDDdeHfq7jJhsDbN-MxO {
  color: var(--color-content-default1);
  width: auto;
  height: auto;
}

@media (max-width: 767px) {
  ._2JDDDdeHfq7jJhsDbN-MxO,
  .Ag7ljVpAE3IDbct6nWu7g {
    width: auto !important;
  }
  ._3lYU1TH1Py8NKJY5bAZTJT {
    margin-bottom: 40px;
  }
}

._1WbDYN0XXv7hVC7WLVkNDz {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  margin-bottom: 55px;
}

.D4eIeHmEjLlJ8MFUnxnv6 {
  font-size: var(--fontSize-s);
  display: block;
  margin: auto;
}

.xksrFJp-1oAwQXmuMmvFe {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  text-align: center;
}

._1oakd0bHJGONUvuDLcrxdE {
  font-size: var(--fontSize-s);
  color: var(--color-content-highlight-hover);
  font-weight: bold;
  cursor: pointer;
}

._2trEUpTdb7vb2AHA2W_D3t {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
}
.Ag7ljVpAE3IDbct6nWu7g {
  width: 328px;
  height: 19px;
  font-size: 14px;
  height: 19px;
  font-size: 14px;
  color: var(--color-primary3);
}
._2n1HMi5s8-BIWXR2X8LWJi {
  height: 19px;
  font-size: 14px;
  color: var(--color-primary3);
}
._1-WF-kehod6iTWrtZvyLto {
  width: 53px;
  height: 19px;
  font-size: 14px;
  color: var(--color-highlight-hover1);
  margin-left: 5px;
  cursor: pointer;
}
._3lYU1TH1Py8NKJY5bAZTJT {
  margin-top: 40px;
  text-align: center;
}
