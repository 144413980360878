:root {
  --width: 100%;
  --imageHeight: 232px;
  --contextHeight: 160px;
}

._8st8AbTvhPJBkmLsb0LCd {
  position: relative;
}

._3mIgNRHSE2ROjI14pgrJEj {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}
._21GNOjhA3L21tOXz38W9h8 {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
  width: 40px;
  height: 40px;
  text-align: center;
}
._1yg2DA52xZH9qc6sr2iW-z {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}
._1KC30FF5WEDI47LFFCloe7 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

._2rmJkG47MiLp0GIUwLQCem {
  background: var(--color-thumbnail-overlay-background);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

._3QLmLhnfS2KF0m52Ru--D3 {
  opacity: 0.8;
}

.j2P-VOeyxcLD1rTRwXybi {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-content-default1);
  margin: 10px;
  font-weight: bold;
  overflow: hidden;
}

._35sjbUvKVs9WoAYbhRAXMx {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 40px;
  height: 40px;
}

._2rhCFMpV6kqbuC__LpPdSa {
  position: absolute;
  bottom: 0;
  color: var(--color-content-default2);
  font-size: var(--fontSize-s);
  margin: 10px;
}

._3jYj_ndz9t4JGJquWzGd3N {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

._2cAl-w-30d-_tfvbLnC58v {
  display: none;
}

._1AHIghd7Oogx0o2fDu0rib {
  z-index: 1;
}

._1AHIghd7Oogx0o2fDu0rib:hover ._36ZT2Omni6gfYQ16JwbbFi {
  display: none;
  opacity: 0;
}

._1AHIghd7Oogx0o2fDu0rib:hover ._2cAl-w-30d-_tfvbLnC58v {
  display: block;
}

._1U3l_zEOFFApZpggPd0d8S {
  display: none;
}

.Q4Nt6udhBS3yW8GCByK8w {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
  opacity: 0;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

.Q4Nt6udhBS3yW8GCByK8w circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

.Q4Nt6udhBS3yW8GCByK8w:hover {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
  transform: scale(1.1);
}

._1O5QS2RYKRfDnqosLs_8qA {
  opacity: 1;
}

._3ITEjSkQVbL2_n-e0ymCqt {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

._3ITEjSkQVbL2_n-e0ymCqt::before,
._3ITEjSkQVbL2_n-e0ymCqt::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

._3ITEjSkQVbL2_n-e0ymCqt::before {
  border-width: 20px;
}

._3ITEjSkQVbL2_n-e0ymCqt::after {
  border-width: 20px;
  border-right-color: var(--color-content-highlight-hover);
  border-top-color: var(--color-content-highlight-hover);
}

._1hrx9t3mgZ5qqjlcpWxXQi {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}

._1SAqEv_hCUr0mtRh8x8OUX > svg {
  width: 16px;
  fill: var(--color-content-default1);
}
