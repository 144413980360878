._3u-FjouaHfLuJ_n2cj6JK9 {
  padding: 10px 0;
  font-size: var(--fontSize-xxl);
  font-weight: 600;
  color: var(--color-content-default1);
}

@media (max-width: 767px) {
  ._3u-FjouaHfLuJ_n2cj6JK9 {
    font-size: var(--fontSize-xl);
  }

}
