._32JroDZEdBZxiDATTQbBHl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: 100vh;
}

._2caBfRzO1GTm-3TZhpqbtb{
    width: 38rem;
    text-align: center;
}

._3lxtMysVHkqae-fqQGWhQf {
    margin: 50px 0 0 0;
    text-align: center;
  }