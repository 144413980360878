.xhxZm2sFIwd89hIadxjf- {
  max-width: 1024px;
  min-height: calc(100vh - 130px); /* margin-top + margin-bottom */
  margin: 0 auto;
}

._2UCQzXNG643FDoAUXQhLhb {
  padding-left: 20px;
  margin-bottom: 70px;
}

.DilU3hKNlbgzALpf1sX1q {
  font-size: var(--fontSize-xl);
  font-weight: 600;
  color: var(--color-content-default1);
  margin-bottom: 20px;
}

.Izx3AAH0P0x3I4EBWTfeZ {
  margin: 0 20px;
}

._2rP4ImX2wRGeRduY0NzwiV {
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-highlight-hover);
  margin-top: 10px;
  padding: 0 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.VgdtZBz34rJF-XBL_qCd_ {
  font-size: var(--fontSize-m);
  font-weight: normal;
  color: var(--color-content-default1);
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  ._2UCQzXNG643FDoAUXQhLhb {
    margin-bottom: 40px;
  }
  .DilU3hKNlbgzALpf1sX1q {
    font-size: var(--fontSize-l);
  }

  ._2rP4ImX2wRGeRduY0NzwiV {
    font-size: var(--fontSize-m);
  }

  .VgdtZBz34rJF-XBL_qCd_ {
    font-size: var(--fontSize-s);
  }
}

