._3bckKm4tFDxunS6A_OBkwt {
  cursor: pointer;
  text-decoration: none;
  margin: 0 var(--marginBetweenThumbnails) 0 0;
  position: relative;
  transition: all 250ms cubic-bezier(.21,.43,.37,.99);
  overflow: hidden;
  min-height: 266px;
  min-width: 200px;
}

._20RRGrBknAKwzHVlsd-m2J {
  border-radius: var(--border-radius-thumbnail-small);
}

._2UO4eIRvv2DEaR9UizU6-N {
  border-radius: var(--border-radius-thumbnail-medium);
}

._2Tzjif0Y9g5LDGO2w0427Q {
  border-radius: var(--border-radius-thumbnail-large);
}

._3Bw-5W-ewT7aVSVlFSqJ5U {
  box-shadow: var(--shadow-thumbnail-small);
}

._6HHuspKIXtwtSRV_hksn7 {
  box-shadow: var(--shadow-thumbnail-medium);
}

.xLp76HaJlqNm321H0xJBR {
  box-shadow: var(--shadow-thumbnail-large);
}

._3-pbLCxHABhZsOOD-2WE2u{
  margin: 0;
}
.tbrnYRyRRTTHQ-Qyro2bi {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
  width: 40px;
  height: 40px;
  text-align: left;
}
._29HLa-RmH3XrpkMvWYA6ip {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}
.Fs18_5wLhBcTlUiM_fgEq {
  transform: scale3d(1.1, 1.1, 1.1);
  z-index: 10;
}

.o-z0sVgZlOyBmadl1uyTy {
  position: relative;
}

.rfzXQInjhX1vJBOa0aCAw {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
  width: 40px;
  height: 40px;
}

._1xXMP2G1z6dk6HdvihfPsV.Fs18_5wLhBcTlUiM_fgEq {
  transform: scale3d(1.05, 1.05, 1.05);
  z-index: 10;
}

._2D1SSACKkHU1qcNgDl3_TB, ._1IajvQEeqvzjaJ3YR4lTuR {
  position: absolute;
  bottom: 0;
  z-index: 50;
}

._3mGVmJPjJDF1SFcHclJOKB {
  background: var(--color-content-overlay3);
}

._2j_PL5u7fQ2twSmRVskoi_{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
._35fvdKFZFFrDYz-zu33gzx{
  fill: var(--color-thumbnail-highlight-hover1);
}