._31f95qJQJVXtJ18qrYyIKg {
  overflow: auto;
}

._10J_Hv6fEKqTyEOve5YCKw {
  width: 100%;
  border-collapse: collapse;
  color: var(--color-content-default1);
}

._10J_Hv6fEKqTyEOve5YCKw th {
  text-align: left;
  font-weight: normal;
  font-size: var(--fontSize-s);
}

._10J_Hv6fEKqTyEOve5YCKw th._3zgHY_2aVXxubctExQW1DH {
  text-align: right;
}

._10J_Hv6fEKqTyEOve5YCKw th,
._10J_Hv6fEKqTyEOve5YCKw td {
  padding: 20px 10px;
  border-bottom: 1px solid var(--color-content-default3);
}

._10J_Hv6fEKqTyEOve5YCKw th._1zHCTIug2iML2uJdpB4JfB,
._10J_Hv6fEKqTyEOve5YCKw td._1zHCTIug2iML2uJdpB4JfB {
  font-weight: bold;
}

._10J_Hv6fEKqTyEOve5YCKw td .O0zKh37Goa4woB7vaDPOm {
  display: flex;
  justify-content: flex-end;
}

._10J_Hv6fEKqTyEOve5YCKw td .O0zKh37Goa4woB7vaDPOm span > * {
  cursor: pointer;
}
