._ilJnMIqBA6q9kPetIt8G {
  cursor: pointer;
  vertical-align: middle;
}

._2LLDoQmVYpi_RMUUsv7JSg,
._1aJ7wSNigLx32xopO65TzQ {
  height: 24px;
  width: 24px;
}

._2LLDoQmVYpi_RMUUsv7JSg {
  fill: var(--color-content-default1);
}

._1aJ7wSNigLx32xopO65TzQ {
  fill: var(--color-content-highlight-hover);
}

._2bSvJfqokXk-Pj9aBz0p4k {
  line-height: 23px;
}
._1Sf37_ZARmbROo-A8aMz_F {
  display: inline-block;
  font-size: var(--fontSize-s);
  font-weight: normal;
}

.TbL53i6D3J3mhzOsWnnLK {
  display: flex;
  cursor: pointer;
}

.TbL53i6D3J3mhzOsWnnLK > fieldset {
  padding: 0;
}

.TbL53i6D3J3mhzOsWnnLK > fieldset > details > summary {
  align-items: center;
  color: var(--color-button-bordered-font);
  background: var(--color-button-bordered-bg);
  border-radius: var(--borderRadius-button-bordered);
  border: var(--borderSize-button-bordered) solid var(--color-content-default3);
}

._1wB8DM_7UAac_4zMkF_DDd {
  border: var(--borderSize-button-bordered) solid var(--color-content-default3);
}

.TbL53i6D3J3mhzOsWnnLK:hover > fieldset > details > summary {
  color: var(--color-button-bordered-font-hover);
  background-color: var(--color-button-bordered-bg-hover);
  border: var(--borderSize-button-bordered) solid
    var(--color-button-bordered-border-hover);
}

.TbL53i6D3J3mhzOsWnnLK:hover > fieldset > details > summary svg path {
  fill: currentColor;
}
.TbL53i6D3J3mhzOsWnnLK:hover > fieldset > details > summary:hover svg g{
  fill:#FFF
}

.TbL53i6D3J3mhzOsWnnLK._3lOTxtHBbrxdegi2-wVHb9 {
  justify-content: center;
  align-items: center;
}

.TbL53i6D3J3mhzOsWnnLK._3lOTxtHBbrxdegi2-wVHb9 > fieldset > details > summary {
  background-color: transparent;
  border-radius: unset;
  border: unset;
}

.TbL53i6D3J3mhzOsWnnLK._3lOTxtHBbrxdegi2-wVHb9:hover > fieldset > details > summary {
  color: unset;
  background-color: unset;
  border: unset;
}

.TbL53i6D3J3mhzOsWnnLK._3lOTxtHBbrxdegi2-wVHb9:hover
  > fieldset
  > details
  > summary
  svg
  path {
  fill: unset;
}

@media (max-width: 767px) {
  .TbL53i6D3J3mhzOsWnnLK {
    display: block;
    text-align: center;
    height: 110px;
    width: 70px;
  }

  ._1wB8DM_7UAac_4zMkF_DDd {
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    padding: 0;
    margin: 0 auto;
  }

  ._2zFDJdGLhuO875QdNhUA1Q,
  ._2ZDHITkaj90zrogKpFVWBJ {
    height: 24px;
    width: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ._2ZDHITkaj90zrogKpFVWBJ {
    fill: var(--color-content-default1);
  }

  ._1aJ7wSNigLx32xopO65TzQ {
    fill: var(--color-content-highlight-hover);
  }

  ._1mOBKYoILp6-rSQSiB75oo,
  ._1Sf37_ZARmbROo-A8aMz_F {
    padding-top: 10px;
    font-size: var(--fontSize-s);
  }
}
