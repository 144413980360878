:root {
  --PageAssetEPG-linkBackPosition: 40px;
  --PageAssetEPG-contentContainerPadding: 20px;
  --PageAsset-linkBackPositionTop: 20px;
  --PageAssetEPG-title-fontSize: var(--fontSize-xxl);
}

._1zAQLpSAAfvrz9x8OMb0IZ {
  background: var(--color-background-asset);
  height: 100%;
  width: 100%;
  overflow: auto;
}

._2DynR4_acHXfqZg1cLawyt {
  position: absolute;
  top: 20px;
  left: var(--PageAssetEPG-linkBackPosition);
  z-index: 20;
}

._2DynR4_acHXfqZg1cLawyt._1Ejvz-8MtQ8Ua7itFckN0i {
  left: unset;
  right: var(--PageAssetEPG-linkBackPosition);
}

._12pl-AL_hmBasOf_fgH59c {
  max-width: 1024px;
  min-height: 100vh;
  margin: 0 auto 50px;
  position: relative;
  padding: 0 var(--PageAssetEPG-contentContainerPadding);
  z-index: 10;
}

._1NIapC4QIa0V_rmt5kZmhg,
._1pcv9qIcFM56mUcM8N7h5P {
  padding-bottom: 0;
}

.pUGr3ntKgLZ5LmYafd5SM {
  padding-bottom: 20px;
}

.V8gk7Tc8lIcIOol4OHIyi {
  font-size: var(--fontSize-s);
  font-weight: bold;
  color: var(--color-content-default1);
  margin: 40px 0 20px;
}

._3-su7qZkgWrSanqxK0fb78,
._4i9TRsIeEsDKWUX0RBBuG {
  font-size: var(--fontSize-m);
  color: var(--color-content-default3);
}

._4i9TRsIeEsDKWUX0RBBuG {
  margin-top: 20px;
}

._34ll_ZeQLD8LKlcXZH02Yx {
  border-top: 1px solid var(--color-content-default3);
  margin: 40px 0;
  width: 100%;
}

._38atMSSa8SWiByHdsaC1zZ {
  width: 20px;
  transform: rotate(90deg);
  float: right;
}

._38atMSSa8SWiByHdsaC1zZ path {
  stroke: var(--color-content-default3);
}

._1gQ8fRQWnJLC4LWMB9dV3y {
  min-width: 490px;
  color: var(--color-content-default1);
}

._1MH4dGe3lA4qDXz04OicKB {
  font-weight: normal;
  font-size: var(--fontSize-s);
  cursor: pointer;
  vertical-align: middle;
  margin-left: 20px;
}

._3evNmHFsoUGhsCKdDbafYX {
  width: 24px;
  margin-right: 5px;
}

._3evNmHFsoUGhsCKdDbafYX path {
  fill: var(--color-background-asset) !important;
}
._3EFKzebrGXNJb_aDWzG3B_ {
  height: 24px;
  width: 24px;
}
._3nSZTy7QXJZzQC1Bi4Y8Mn {
  font-size: var(--fontSize-s);
  font-weight: normal;
  text-decoration: none !important;
  padding-top: 10px;
}
.Ud-sjSUKxycR1en76a-_0 {
  font-weight: normal;
  font-size: var(--fontSize-s);
  cursor: pointer;
  vertical-align: middle;
  border-color: var(--color-content-default3);
  min-width: 200px;
}
.Jgv5YYM71lGEsyZrZ7VJH {
  text-decoration: none;
  color: var(--color-content-default1);
  display: flex;
}
.qh5hI6iGsx_CmLSlqkICa {
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  :root {
    --PageAssetEPG-linkBackPosition: 20px;
    --PageAssetEPG-title-fontSize: var(--fontSize-xl);
  }
  ._3-su7qZkgWrSanqxK0fb78,
  ._4i9TRsIeEsDKWUX0RBBuG {
    font-size: var(--fontSize-s);
  }
  ._2DynR4_acHXfqZg1cLawyt {
    top: var(--PageAssetEPG-linkBackPosition);
    left: 14px;
  }

  ._1gQ8fRQWnJLC4LWMB9dV3y {
    width: 300px;
  }

  ._2-iKr7uXpBTl4hf3mznk46 {
    display: block;
    text-align: center;
    height: 110px;
    width: 60px;
  }

  .Ni6d4gzJBWPKJYDFxb0YZ {
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    padding: 0;
  }
  ._3EFKzebrGXNJb_aDWzG3B_ {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ._3evNmHFsoUGhsCKdDbafYX {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
