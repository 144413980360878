:root {
  --iconSize-s: 22px;
  --iconSize-m: 24px;
  --iconSize-l: 26px;
}

.vo_ffK34GCq8el1TCkmPo {
  margin-top: 11px;
  margin-right: 11px;
  float: left;
}

.vo_ffK34GCq8el1TCkmPo,
.vo_ffK34GCq8el1TCkmPo > svg {
  width: var(--iconSize);
  height: var(--iconSize);
  align-self: flex-start;
  fill: var(--color-scrollTopHeader-primary1);
}
._16wORDpAPZ0_YfM_10Qk1b {
  align-self: flex-end;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-scrollTopHeader-primary1);
}
.rjtNBWkdm1A5sLT93AZ0x:hover ._16wORDpAPZ0_YfM_10Qk1b,
.rjtNBWkdm1A5sLT93AZ0x:hover .vo_ffK34GCq8el1TCkmPo > svg {
  fill: var(--color-scrollTopHeader-highlight-hover1);
  color: var(--color-scrollTopHeader-highlight-hover1);
}

.rjtNBWkdm1A5sLT93AZ0x {
  color: var(--color-scrollTopHeader-primary1);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
