:root {
  --LinkBack-height: 24px;
}

._134Z1xjiyv2QNJ27z7i0Zl,
._134Z1xjiyv2QNJ27z7i0Zl svg {
  cursor: pointer;
  color: var(--color-primary1);
  stroke: var(--color-primary1);
  z-index: 1; /* To appear above image gradient */
  display: flex;
  align-items: center;
  height: 40px;
}
.Ti4g3KDj9f5OdtsOBWcaa {
  justify-content: flex-start;
}

._134Z1xjiyv2QNJ27z7i0Zl:hover,
._134Z1xjiyv2QNJ27z7i0Zl:hover svg {
  color: var(--color-highlight-hover1);
  stroke: var(--color-highlight-hover1);
}

._27IvDm7wAncU4PVRrmOc1m {
  vertical-align: middle;
  display: inline-block;
  margin-right: 20px;
}

._27IvDm7wAncU4PVRrmOc1m svg {
  transform: rotate(180deg);
  width: var(--LinkBack-height);
  height: var(--LinkBack-height);
  stroke-width: 2px;
  stroke-linecap: round;
}

._221eRCgVHe2WrvTjJ-2q2O svg {
  transform: rotate(360deg);
  margin: 0 0 0 10px;
}

._1z2337zg3eBkQBqjidY1gW {
  height: var(--LinkBack-height);
  line-height: var(--LinkBack-height);
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  ._1z2337zg3eBkQBqjidY1gW {
    font-size: var(--fontSize-s);
  }

  ._27IvDm7wAncU4PVRrmOc1m {
    margin-right: 16px;
  }
}
