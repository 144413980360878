:root {
  color: var(--color-content-default1);
}

._1l4hKsmvtWLwPQj6tNTKgw {
  margin-bottom: 38px;
}

.M8fLeJYUI-13MOZBT0TT_ {
  font-size: var(--fontSize-s);
  font-weight: 600;
  text-transform: inherit;
  display: block;
  margin: auto;
  margin-bottom: 10px;
  text-align: center;
}

._1lClcalJO9TTLhPEJzT7mt {
  font-size: var(--fontSize-m);
  font-weight: normal;
  text-align: left;
  margin: 10px 0 30px 0;
}

._2MTgybCspwfEb-ty-vhhhL {
  font-size: var(--fontSize-s);
  color: var(--color-content-highlight-hover);
  font-weight: bold;
  cursor: pointer;
}

.prjrzEdcXHoyRfiCQKayh {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
  padding-left: 5px;
  text-align: left;
}

._3WY04URnHVc14gKWviAPFM {
  margin-top: 20px;
}

._3WY04URnHVc14gKWviAPFM > svg {
  margin-top: 20px;
  width: 80px;
  height: 80px;
}

._3WY04URnHVc14gKWviAPFM circle {
  fill: var(--color-content-highlight-hover);
}

._3WY04URnHVc14gKWviAPFM path {
  fill: var(--color-secondary1);
}

._1_3SrH-AW9-jIh-PjIcxUW {
  font-size: var(--fontSize-m);
  font-weight: bold;
  text-align: center;
  margin: 20px 0 20px 0;
}

._2eBfnqGE1d_Kefmi7odbUe {
  font-size: var(--fontSize-m);
  font-weight: normal;
  text-align: center;
  margin: 10px 0 30px 0;
}

.wk8TpBSj6QFhE1N0dMx4G {
  font-size: var(--fontSize-s);
  font-weight: bold;
  font-style: normal;
  color: var(--color-content-highlight-hover);
  margin-top: 20px;
}
.AqXrQ4pod86TGoU9yCATB {
  font-size: var(--fontSize-s);
  font-weight: bold;
  font-style: normal;
  color: var(--color-content-default3);
  margin-top: 20px;
}

.AqXrQ4pod86TGoU9yCATB:hover {
  color: var(--color-content-default3);
  cursor: not-allowed;
}

._1-VgM0agxA35mdK2anrbOI {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  text-align: center;
  font-weight: normal;
  font-style: normal;
}

@media (max-width: 767px) {
  ._1lClcalJO9TTLhPEJzT7mt,
  ._1_3SrH-AW9-jIh-PjIcxUW {
    font-size: var(--fontSize-s);
  }
}
