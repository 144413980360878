.Miaufl6iutpSmomm8a-bX {
  max-width: 1024px;
  margin: 0 auto 100px;
}

._3BEomy_8UXIHpCKTzn7Aw3 {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  max-height: 160px;
  border-bottom: 1px solid var(--color-account-separator);
}

._30Fmnnmq3SnWLxwKMX1RHP {
  margin-left: 30px;
  display: inline-block;
}

.PSpFRwR6bX0zegNjil3ex {
  width: 120px;
  height: 120px;
  vertical-align: middle;
}

._3h02kVgFxMbdHEMHwZKDCE {
  background: transparent url(/assets/profile_default.png)
    no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

._3BEomy_8UXIHpCKTzn7Aw3 .PSpFRwR6bX0zegNjil3ex {
  position: relative;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.beUdDekJtx9pHBsnVME9T {
  height: 22px;
  border-radius: 16px;
  margin-top: 10px;
  background-color: var(--color-secondary3);
  text-align: center;
}
._1ViznGgVmAaCXB9DilfpGM {
  margin: 5px;
  width: 68px;
  height: 22px;
  display: flex;
  align-items: center;
}
.t4Z-u-NVoSNc55oUsVAKi {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  /* background-color: var(--color-content-error); */
}
._2Wtt-A82GJjo9o-vujZBbW {
  background-color: var(--color-relax);
}
._30DE1Z4YjSn6ch6tU_n6ds {
  background-color: var(--color-error);
}
._2-mmtCaAsl8iooWTclWpPt {
  width: 53px;
  object-fit: contain;
  font-size: 9px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary1);
  text-align: left;
}
._9MjmgLW5EkZIU4YQhVWDF {
  font-size: var(--fontSize-m);
  font-weight: normal;
  color: var(--color-content-default3);
}

._3qNidUn69OoaFt9ect6rgG {
  font-size: var(--fontSize-xxl);
  font-weight: bold;
  color: var(--color-content-default1);
}

._3euoGOfddV5ckixqNMiH-4 {
  font-size: var(--fontSize-xxl);
  font-weight: bold;
  color: var(--color-content-default1);
}

._1faVyjtE0rf653Wn5wiGuK {
  position: relative;
}

._3RONJtZGUV1wB5rwQXK0m6 {
  padding-left: 5px;
  margin-top: 30px;
  font-size: var(--fontSize-xl);
  font-weight: bold;
  color: var(--color-content-default1);
}

._1WOwuXTGhL2YhSffFqwu8k {
  margin-top: 40px;
  margin-bottom: 40px;
}

._1j7twS4zcsacBCX6v1BZER {
  padding-left: 10px;
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  line-height: 1.43;
  margin-bottom: 5px;
  margin-top: 30px;
}

.ERoblOMaeR61nv5iqh60A {
  padding-left: 10px;
  font-size: var(--fontSize-m);
  color: var(--color-content-default1);
  max-width: 100%;
}

._2aqAaCTetf18Z78jHblu2R {
  color: var(--color-content-highlight-hover);
  font-size: var(--fontSize-s);
}

._3nemgwFv77GQnncnjxFNpZ {
  position: absolute;
  top: 0;
  right: 0;
}

._3nemgwFv77GQnncnjxFNpZ._16nxwhg4HvaSzIyBefO7Ll {
  right: unset;
  left: 0;
}

._3baZozLy1Zgq9BaydvX4Df {
  font-size: var(--fontSize-s);
  display: block;
  text-transform: inherit;
  margin-bottom: 30px;
  min-width: 150px;
  width: 240px;
  line-height: 27px;
}

._3baZozLy1Zgq9BaydvX4Df:hover {
  background-color: var(--color-content-highlight-hover);
  color: var(--color-background-asset);
}

._16w3ZhiC2YpPuw0FkLnQSA {
  width: 400px;
  height: auto;
}

._3k1p_x78FfVVMtyFmgzXY1 {
  padding-bottom: 20px;
}

._9CPMonG03lYIY9Sj9K8BJ {
  padding-bottom: 80px;
}

._2UmVNGYGDFoByXpM77NfQr {
  color: var(--color-content-default1);
}

._14OXl8vcbpc0muwIHCF5gA {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-save-bar-bg);
  padding: 20px 50px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

._1rLj217oRgBTIvNIG-Lqzr {
  bottom: 80px;
}

._1dd50GasNOopGPnHwpVfKI {
  margin-right: 40px;
}

._21la6yoE3RSt_EyuvMmrjU {
  margin-right: 40px;
  min-width: 200px;
  font-size: var(--fontSize-s);
  font-weight: 600;
}

._2_MS_kIfn9accHjqa3NsOo {
  font-size: var(--fontSize-s);
  min-width: 200px;
  font-weight: 600;
  line-height: 27px;
}

._2_MS_kIfn9accHjqa3NsOo:hover {
  background-color: var(--color-content-hover-for-highlighted);
}

.JZz7QVa8q3A1_DdJ4kjy3 {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
  padding-left: 10px;
}

._5kOPouxEstdAg4a0B5Ttq,
._2-sL5KpWjVKhyow6Hi_9II,
._2cV0Fb3zek2EU0k61MZoDQ {
  display: inline-block;
}

._5kOPouxEstdAg4a0B5Ttq {
  width: 138px;
  margin-right: 40px;
  vertical-align: top;
}

._1WOwuXTGhL2YhSffFqwu8k hr {
  border-color: var(--color-primary3);
  opacity: 0.4;
}

._3lmDrDakR-c0XC-GFM3u_t {
  display: flex;
  flex-flow: column;
  margin: 0;
}

._31olRIPUXIcvreZSbWyqCh {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid var(--color-primary3);
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 15px;
}

._31olRIPUXIcvreZSbWyqCh ._3RONJtZGUV1wB5rwQXK0m6 {
  margin-top: 0px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary1);
}

.Collapsible__trigger ._3ZI62idMeb8UzZFVltmXQF {
  width: 20px;
  height: 20px;
  transform: rotate(-270deg);
}

.Collapsible__trigger ._3ZI62idMeb8UzZFVltmXQF path {
  stroke: var(--color-content-default1);
}

.Collapsible__trigger.is-open ._3ZI62idMeb8UzZFVltmXQF {
  transform: rotate(270deg);
}

._2jHlbwxbJ6ZGl_UVn77RL9 {
  margin: 79px 172px 20px 0;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary1);
}

._3Vcdq4lMaw1DStAOMMKS38 {
  margin-top: 30px;
  margin-bottom: 10px;
}

._20fkF_NYt9N-ovZrBFMvim._3GogMUcSLcSDJnvuZziEkQ {
  border-top: 1px solid var(--color-account-separator);
}

._20fkF_NYt9N-ovZrBFMvim._1ETrbkIK-8zkoB-c06MxNQ {
  border-bottom: 1px solid var(--color-account-separator);
  margin-top: unset !important;
  margin-bottom: 30px !important;
}

._2wO4pqGoPB28IrI_WBzDsH {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-content-highlight-hover);
}

._2wO4pqGoPB28IrI_WBzDsH:hover {
  text-decoration-line: underline;
  cursor: pointer;
}

._3Vcdq4lMaw1DStAOMMKS38 .GCe0IDhvN9GH32swU2Mb1 {
  margin: 32px 46px 5px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary1);
}

._3Vcdq4lMaw1DStAOMMKS38 ._1GxR6MBlrwMTmgOWjjGMz8 {
  margin: 5px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  opacity: 0.4;
  color: var(--color-primary-3);
  margin-bottom: 20px;
}

._2aEQj2Vf6qF33OEaDeOTNj {
  width: 270px;
  height: 44px;
  margin: 53px 0 28px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary-1);
}
._2SpcUy5ex8L7eZzvefDujZ {
  width: 136px;
  height: 19px;
  margin: 0 0 0px 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary-3);
  opacity: 0.4;
}

.JeD16MKjknP8zuPJcu4mg {
  width: 270px;
  height: 40px;
  margin: -8px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary-1);
}

._3VSqT3mmNEuvSMgswX6OQ2 {
  width: auto;
  max-width: 90%;
  margin: 33px 148px 147px 77px;
  padding: 30px 30px 50px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

.vuIiFNdr_V-L8xd9r20px {
  width: 230px;
  height: 40px;
  margin: 50px 20.1px 40px 19.9px;
}

.qO4WsBdHW4wxnU_Jn1WxI {
  width: 270px;
  height: 44px;
  margin: 30px 1px 10px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-primary-1);
}

._3ELulZHDMZ-28oFPhzu6ZK {
  width: 270px;
  height: 57px;
  margin: 10px 1px 40px 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-primary-1);
}

._1am2LtV33UUS59W-DjlPl6 {
  width: 230px;
  height: 40px;
  margin: 0px 20.1px 20px 19.9px;
}

.LeqvOvc_RLMXfhwLd3S_7 {
  width: 502px;
  margin: 33px 148px 147px 77px;
  padding: 30px 30px 50px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

._3kgReJvAK0SzFXIkG5csoJ {
  width: auto;
  max-width: 403px;
  height: 49px;
  margin: 0px 0px 20px 0px;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-primary-1);
}

._1kJk193El2StoCBdndrc4H {
  width: auto;
  max-width: 392px;
  height: 44px;
  margin: 15px 0px 40px 0px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-primary-2);
  opacity: 0.4;
}

.NlHlmTUJyrnMuicYv538e {
  width: 120px;
  height: 40px;
  /* margin: 40px 121px 0 165px; */
  border-radius: 20px;
  display: block;
  margin: 0 auto;
}

._1f-JyaVQtiU5yzC3McdTQT {
  width: auto;
  margin: 33px 148px 147px 77px;
  padding: 30px 30px 50px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

._3-3vLi_kq80pBhieOHyczn {
  width: 330px;
  margin: 33px 148px 147px 77px;
  padding: 30px 30px 50px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

._3Dk9_oNoiFhZJpyH0CSeD5 {
  width: 270px;
  height: 44px;
  margin: 0px 1px 20px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-primary-1);
}

._2vgMggnPOWFB7GiBwtUJ-8 {
  width: 270px;
  height: 38px;
  margin: 20px 1px 30px 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-primary-1);
  opacity: 0.4;
}

._2CMijfOuplDxkUNxMK4jeZ {
  width: 270px;
  height: 40px;
  margin: 30px 0 0 1px;
  border-radius: 20px;
}

._2cR2aygq5rYIrZZY3xic8h {
  width: 80px;
  height: 70px;
  margin: -25px 72px 0px 95px;
  border-radius: 3px;
  background-color: var(--color-brand-1);
}

._1kVMl74Kmn64xN2HG-6oau {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1023px) {
  ._3BEomy_8UXIHpCKTzn7Aw3 {
    margin-top: 10px;
    max-height: none;
  }

  ._16w3ZhiC2YpPuw0FkLnQSA {
    width: 330px;
  }

  ._1faVyjtE0rf653Wn5wiGuK {
    position: static;
  }

  ._3nemgwFv77GQnncnjxFNpZ {
    margin-top: 0;
    padding-right: 0;
    position: static;
    text-align: center;
  }
  ._3baZozLy1Zgq9BaydvX4Df {
    display: inline-block;
    min-width: 230px;
    width: 180px;
    margin-right: 20px;
  }

  ._2cV0Fb3zek2EU0k61MZoDQ:not(:last-child) {
    margin-bottom: 40px;
  }

  ._14OXl8vcbpc0muwIHCF5gA {
    padding: 20px;
  }

  ._21la6yoE3RSt_EyuvMmrjU,
  ._1dd50GasNOopGPnHwpVfKI {
    margin-right: 20px;
  }

  ._21la6yoE3RSt_EyuvMmrjU,
  ._2_MS_kIfn9accHjqa3NsOo {
    min-width: 150px;
  }
  ._3VSqT3mmNEuvSMgswX6OQ2 {
    margin: unset;
  }
}

@media (max-width: 767px) {
  .PSpFRwR6bX0zegNjil3ex {
    width: 80px;
    height: 80px;
  }

  ._1WOwuXTGhL2YhSffFqwu8k {
    margin: 0;
  }

  ._9MjmgLW5EkZIU4YQhVWDF {
    font-size: var(--fontSize-m);
  }

  ._3euoGOfddV5ckixqNMiH-4 {
    font-size: var(--fontSize-xl);
  }

  ._3RONJtZGUV1wB5rwQXK0m6 {
    font-size: var(--fontSize-l);
  }

  .ERoblOMaeR61nv5iqh60A {
    font-size: var(--fontSize-m);
  }

  ._3nemgwFv77GQnncnjxFNpZ {
    margin-top: 30px;
  }

  ._1WOwuXTGhL2YhSffFqwu8k:last-child {
    margin-top: 30px;
  }

  ._3baZozLy1Zgq9BaydvX4Df {
    width: 150px;
    min-width: 230px;
    margin-right: 10px;
  }

  ._14OXl8vcbpc0muwIHCF5gA {
    display: inline-block;
    text-align: left;
  }

  ._21la6yoE3RSt_EyuvMmrjU {
    margin-top: 20px;
    line-height: 27px;
    margin-bottom: 20px;
  }

  ._5kOPouxEstdAg4a0B5Ttq {
    margin-bottom: 40px;
  }

  ._1kVMl74Kmn64xN2HG-6oau {
    align-items: center;
  }

  ._20fkF_NYt9N-ovZrBFMvim {
    text-align: center;
  }

  ._20fkF_NYt9N-ovZrBFMvim .GCe0IDhvN9GH32swU2Mb1 {
    margin-right: 0px;
  }
}

@media (max-width: 359px) {
  ._3euoGOfddV5ckixqNMiH-4 {
    font-size: var(--fontSize-l);
  }
}

.ACgVuEw8_5i_fd9Xo9i1G {
  background: var(--color-button-normal-bg);
  color: var(--color-button-normal-font);
  /*min-width: 250px;*/
  display: inline-block;
  padding: 5px 20px;
  text-align: center;
  margin: 0 10px 10px 0;
  height: var(--height);
  line-height: 27px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: var(--borderRadius-button-normal);
  border: var(--borderSize-button-normal) solid
    var(--color-button-normal-border);
  font-weight: bold;
  text-decoration: none;
}
