:root {
  --PageVODDetail-grid-width: 161px;
  --PageVODDetail-grid-cover_mode_width: 300px;
  --PageVODDetail-grid-rowGap: 30px;
  --PageVODDetail-linkBackPosition: 40px;
}

.NrF1hDsw2cDfdm5_YtZDn {
  min-height: 100vh;
}

._2h5l2Zlh6I8Orl8qQkufVg {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageVODDetail-grid-width), 1fr)
  );
  grid-row-gap: var(--PageVODDetail-grid-rowGap);
}
._2x45afk_5yuB3nbvqf1nUa {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageVODDetail-grid-cover_mode_width), 1fr)
  );
  grid-row-gap: var(--PageVODDetail-grid-rowGap);
}

._3J14T26kuvhGlnn0wvF6bu {
  position: absolute;
  top: var(--PageVODDetail-linkBackPosition);
  left: 40px;
}
.aWdo_RexK_t1__qBXyGIU {
  position: absolute;
  top: var(--PageVODDetail-linkBackPosition);
  right: var(--PageAsset-linkBackPosition);
}

._1Ophfw4_-_rHJm1qU4FEWK {
  margin: 20px 0 40px -7px;
}

.wtdmnK2bsquwlj-cB6wqD {
  font-size: var(--fontSize-m);
  color: var(--color-content-default1);
}

.wtdmnK2bsquwlj-cB6wqD:hover {
  color: var(--color-content-highlight-hover);
}

.wtdmnK2bsquwlj-cB6wqD:hover ._3ogpOYOMrq8idp5UNYMfGv {
  stroke: var(--color-content-highlight-hover);
}

._3ogpOYOMrq8idp5UNYMfGv {
  width: 24px;
  height: 24px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
  position: relative;
  top: 10px;
  stroke: var(--color-content-default1);
  stroke-width: 2px;
}

@media (max-width: 767px) {
  :root {
    --PageVODDetail-grid-width: 127px;
    --PageVODDetail-grid-rowGap: 20px;
    --PageVODDetail-linkBackPosition: 20px;
  }

  ._3J14T26kuvhGlnn0wvF6bu {
    top: var(--PageVODDetail-linkBackPosition);
    left: var(--PageVODDetail-linkBackPosition);
  }

  ._1Ophfw4_-_rHJm1qU4FEWK {
    margin: 20px 0 20px -7px;
  }
}
