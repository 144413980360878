:root {
  --Search_inputWidth: 456px;
  --buttonVisibility: initial;
  --buttonPadding: 150px;
  --buttonPaddingMenuOpen: 360px;
}
@media (max-width: 767px) {
  :root {
    --Search_inputWidth: 100%;
  }
}
@media (max-width: 975px) {
  :root {
    --buttonVisibility: hidden;
  }
}
@media (max-width: 990px) {
  :root {
    --buttonPadding: 130px;
    --buttonPaddingMenuOpen: 340px;
  }
}
._2NmPMj3J6aNiYECI6_Sgop._2794UcgIidUeJ6pC1ySJT_ {
  visibility: var(--buttonVisibility);
  padding-left: var(--buttonPadding);
  transition: all 300ms ease-in-out;
}
._1PIIvc4GFksjI6kK0a_yHb._2794UcgIidUeJ6pC1ySJT_ {
  visibility: var(--buttonVisibility);
  padding-left: var(--buttonPaddingMenuOpen);
  transition: all 300ms ease-in-out;
}

._2NmPMj3J6aNiYECI6_Sgop.sXzpM2FLKY1JoYxTswuLx {
  visibility: var(--buttonVisibility);
  padding-right: var(--buttonPadding);
  transition: all 300ms ease-in-out;
}
._1PIIvc4GFksjI6kK0a_yHb.sXzpM2FLKY1JoYxTswuLx {
  visibility: var(--buttonVisibility);
  padding-right: var(--buttonPaddingMenuOpen);
  transition: all 300ms ease-in-out;
}

._3d9mmrlgy3gLo_B-netxog {
  visibility: hidden !important;
  transition: all 0ms ease-in-out;
}
._3YB3Qp5CNEc0XDBw7eAB09 {
  width: var(--Search_inputWidth);
  display: inline-flex;
  align-items: center;
  padding-right: 40px;
}

._3YB3Qp5CNEc0XDBw7eAB09.sXzpM2FLKY1JoYxTswuLx {
  padding-left: 40px;
}

div._37YvoKvKbb_BAH_q6k7icq {
  background: var(--color-scrollTopHeader-bg);
  width: 100%;
  transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out,
    background 0.5s ease-in-out;
  display: flex;
  justify-content: space-between;
}

._2Os7yNq9-e6yKSFKGsNtKa {
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: var(--color-search-primary1);
}

._2vDQ-rlDmKYkXN6NWHxDsr {
  align-items: center;
  display: inline-flex;
  position: absolute;
  padding-left: 20px;
}
._2vDQ-rlDmKYkXN6NWHxDsr.sXzpM2FLKY1JoYxTswuLx {
  padding-left: 0;
  padding-right: 20px;
}
._2EEH4WgMdeDVIcFcQriJ9f {
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: 40px;
  padding-right: 20px;
}

._1aNDyrdT8zZ6u6R7Ei3Mpo {
  left: 50px !important;
  right: unset !important;
}

._1IshAAEtj1tWsclteTVkpY {
  width: var(--Search_inputWidth);
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: var(--color-search-input-bg);
  font-size: var(--fontSize-m);
  color: var(--color-search-primary1);
  box-sizing: border-box;
  outline: none;
  padding-left: 60px;
}

._1IshAAEtj1tWsclteTVkpY.sXzpM2FLKY1JoYxTswuLx {
  padding-left: 0;
  padding-right: 60px;
}

._1SQfdUH9VujM7F4WQiz2Nk {
  max-width: var(--Search_inputWidth);
  margin: 0 auto;
}

._2Ch6A3EeoY4rXX1I9qdcgw {
  background: var(--color-content-overlay1);
  width: var(--Search_inputWidth);
  min-height: 180px;
  max-height: 450px;
  border-radius: 3px;
  padding: 20px 20px 1px;
  box-sizing: border-box;
  text-align: left;
  position: absolute;
  top: 66px;
  right: 40px;
  overflow: scroll;
  overscroll-behavior: contain;
}

._2Ch6A3EeoY4rXX1I9qdcgw.sXzpM2FLKY1JoYxTswuLx {
  right: unset;
  left: 40px;
}

._2Ch6A3EeoY4rXX1I9qdcgw._1SmTanpjvXKzCTYd2uqXuI {
  right: calc(var(--buttonPadding) * 3.33);
}

._1sr-xVD9SVBxWI2Hu1Kk1M {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  box-sizing: border-box;
}

.yw-1iZZFREG8xULLmu1Bw {
  text-align: center;
  padding: 20px;
}

._3N1zbOBwDObtNdRNRqdV0L,
._3N1zbOBwDObtNdRNRqdV0L > svg {
  width: 80px;
  transform: rotate(90deg);
}

._3N1zbOBwDObtNdRNRqdV0L path {
  fill: var(--color-content-overlay3);
}

._3N1zbOBwDObtNdRNRqdV0L circle {
  fill: var(--color-content-default3);
}

.UJnZLMikeIMsNzCnHDll3 {
  color: var(--color-content-default1);
  text-decoration: none;
  display: block;
}

._2ylOPVKpKCR7awcFyKk66f {
  stroke: var(--color-content-default1);
  margin-left: 10px;
  float: right;
}

div._3RmUESDudwdLHVoeZuHqsn {
  visibility: hidden;
  opacity: 0;
  background: transparent;
  transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out,
    background 0.5s ease-in-out;
}

._3wzZWM_aUOJFvs4lsoUetl {
  width: 100%;
  margin: 20px 0;
  opacity: 0.4;
}

@media (max-width: 1023px) {
  ._3YB3Qp5CNEc0XDBw7eAB09.sXzpM2FLKY1JoYxTswuLx {
    padding-left: 0px;
  }

  ._1o85Y-glwotJTSDb46q3YM {
    display: inline-flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: var(--color-nav-background);
  }

  ._3sj_xV5bQ76LtFTJF9ZF1n {
    left: 0;
    width: 100% !important;
  }

  ._3YB3Qp5CNEc0XDBw7eAB09 {
    width: 100%;
    padding-right: 20px;
  }

  ._2vnXsGTRbU3-4Nunt2vs2R {
    background: var(--color-nav-background);
  }

  div._37YvoKvKbb_BAH_q6k7icq {
    background: transparent;
    width: 60px;
  }

  ._2vDQ-rlDmKYkXN6NWHxDsr {
    left: initial;
    right: 20px;
  }

  ._2vDQ-rlDmKYkXN6NWHxDsr.sXzpM2FLKY1JoYxTswuLx {
    left: 20px;
  }

  ._2EEH4WgMdeDVIcFcQriJ9f {
    left: initial;
    right: 20px;
    padding-right: 0;
  }

  ._2vDQ-rlDmKYkXN6NWHxDsr._1PJ5ZbNHjr0-YgNYGO8Dxz {
    right: initial;
    left: initial;
  }

  ._1IshAAEtj1tWsclteTVkpY {
    width: 0;
    padding-left: 0;
    background-color: var(--color-nav-background);
    border-radius: 0;
  }

  ._1IshAAEtj1tWsclteTVkpY._22-UGh1Di6C-CTwCJzRj1A {
    padding-left: 60px;
    width: 100%;
  }

  ._2Ch6A3EeoY4rXX1I9qdcgw {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    padding: 20px 20px 1px;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 60px);
    border-radius: 0;
  }

  ._1SQfdUH9VujM7F4WQiz2Nk {
    max-width: 100%;
  }
}
