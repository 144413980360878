:root {
  --title-fontSize: var(--fontSize-l);
  --subtitle-fontSize: var(--fontSize-s);
  --subtitle-margin: 0 0 20px;
  --synopsis-fontSize: var(--fontSize-s);
  --charcoal:#1d1e1c;
}

.SZOO6mN-yO7RzvEjTXUGi {
  position: relative;
}
._2VdhT6jJ0j_Owp72mZXcc {
  position: relative;
  border-radius: 5px;
  background-color: var(--charcoal);
  padding: 10px;
}
._3lxGNM5OLb8yJJrRpbNwiF{
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: flex-start;
  min-height: 90px;
}
._2J-puOOt2Cw-PfmPJDOIMf{
  float: left;
  margin-right: 20px;
}
.vfGS7vuGg1wQJTe5TrvrD{
  float: left;
  margin-right: 20px;
}

.rpOY6RdLCpM2HM0nDcnmr {
  position: absolute;
  bottom: 0;
  z-index: 50;
}

.W-HZe4TUMusT9Whn_bcO {
  text-decoration: none;
  margin: 0;
  text-overflow: ellipsis;
  font-size: var(--title-fontSize);
  font-weight: bold;
  color: var(--color-thumbnail-primary1);
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

._7y_7EbULTlfYm2OD1IbkA {
  font-size: var(--subtitle-fontSize);
  font-weight: normal;
  color: var(--color-thumbnail-primary3);
  margin: var(--subtitle-margin);
}

._1fX6pW7B6WfpJfeIhROGTD {
  font-size: var(--synopsis-fontSize);
  color: var(--color-thumbnail-primary1);
  margin: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.4;     /* fallback */
  max-height: 66px;      /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

._1q7utkGH7Z523VbJgX4ycX {
  position: absolute;
  bottom: 0;
  right: 0;
  text-transform: uppercase;
  line-height: 27px;
  font-size: var(--fontSize-s);
}
._3satwoDjY1cufn9ilnfA88 {
  /* float:right ; */
  bottom: 8px;
  right: 8px;
  position: absolute;
  text-transform: uppercase;
  font-size: var(--fontSize-s);
  /* align-self: flex-end; */
}

._1q7utkGH7Z523VbJgX4ycX:hover {
  background-color: var(--color-button-bordered-border);
  color: var(--color-background-asset);
}


@media (max-width: 768px) {
  :root {
    --title-fontSize: var(--fontSize-l);
    --subtitle-fontSize: var(--fontSize-s);
    --subtitle-margin: 0 0 10px;
    --synopsis-fontSize: var(--fontSize-s);
  }
  ._2VdhT6jJ0j_Owp72mZXcc {
    position: relative;
    display: flex;
    flex-direction: column;
    padding:10px;
  }
  .vfGS7vuGg1wQJTe5TrvrD{
    float: left;
    margin-right:0;
  }
  ._3lxGNM5OLb8yJJrRpbNwiF{
    min-height: 120px;
  }
}

