._1mL-l-tDm1Rl7eBA3A28Mg {
  display: block;
  margin-top: 10px;
  color: var(--color-error);
  font-size: var(--fontSize-s);
}

.rrNSmO80DPP7ixYG3Sud0 {
  display: block;
  max-width: 300px;
}

.T-Knfb7g4PbXvyogwDwgd {
  background-color: var(--color-select-background);
  width: 250px;
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__control {
  height: 40px;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid var(--color-content-default3);
  border-radius: 0;
  box-shadow: none;
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__control--menu-is-open,
.rrNSmO80DPP7ixYG3Sud0 .react-select__control--is-focused {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid var(--color-select-highlight-active) !important;
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__single-value {
  width: 250px;
  color: var(--color-select-primary1);
  background-color: transparent;
  font-size: var(--fontSize-s);
  height: 40px;
  line-height: 40px;
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__single-value:hover {
  border-color: var(--color-content-default3);
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__menu-list {
  background-color: var(--color-select-background);
  border-radius: var(--borderRadius-select-normal);
  box-shadow: var(--boxShadow-select-normal);
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__menu {
  background-color: var(--color-select-background);
  color: var(--color-select-primary1);
  border-radius: var(--borderRadius-select-normal);
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__indicator-separator {
  display: none;
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__input {
  display: inline-block;
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__dropdown-indicator {
  color: var(--color-content-default3);
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__option {
  font-size: var(--fontSize-s);
  border-radius: var(--borderRadius-select-normal);
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__option:hover {
  background-color: var(--color-select-background-hover);
  color: var(--color-select-background);
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__option--is-focused {
  color: var(--color-select-background);
  background-color: var(--color-select-background-hover) !important;
  border: 0;
  box-shadow: none;
  font-weight: bold;
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__option--is-selected {
  background-color: var(--color-select-background-hover);
  color: var(--color-select-background);
  font-weight: bold;
}

.rrNSmO80DPP7ixYG3Sud0 .react-select__control--menu-is-open {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid var(--color-content-default3);
  box-shadow: none;
}
