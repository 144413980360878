:root {
  --PageMyLibrary-grid-width: 360px;
  --PageLiveDetail-grid-width: 340px;
  --PageMyLibrary-grid-cover-width: 500px;
  --grid-column-gap: 60px;
  --grid-row-gap: 20px;
}
._1v6GxTRlKiNJk-WJXs7fWI {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageLiveDetail-grid-width), 1fr)
  );
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

._2Vz26hn4RqobyuEZx7jDC5{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
._2zGEgL7djcf1MlDNggL2nK {
  float: left;
  border-right: 1px solid var(--color-thumbnail-primary1);
  padding: 0 36px;
  box-sizing: border-box;
  text-align: center;
}
._2zGEgL7djcf1MlDNggL2nK:first-child{
  display: flex;
  flex-direction: row;
  align-items: center;
}
._2zGEgL7djcf1MlDNggL2nK:first-child > p{
  margin: 10px;
}
._2zGEgL7djcf1MlDNggL2nK:last-child {
  border-right: none;
}
._2zGEgL7djcf1MlDNggL2nK p{padding: 0;margin: 0;}
._2zGEgL7djcf1MlDNggL2nK ._34VJ1rOs4WxgLinx0eQDmr{
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: var(--color-thumbnail-primary1)
}
._2zGEgL7djcf1MlDNggL2nK .aZWId4VzuZhmkR1tL2_Cr{
  margin: 6px 0 0;
  font-size: 13px;
  color:  var(--color-content-highlight-hover);
}

.rz26VKp2nV3uMCUH1jY22,
.rz26VKp2nV3uMCUH1jY22 svg {
  height: 19px;
  width: 19px;
  fill: var(--color-thumbnail-primary1);
}

@media (max-width: 1023px) {
  :root {
    --PageMyLibrary-grid-width: 290px;
    --PageMyLibrary-grid-cover-width: 500px;
    --grid-column-gap: 60px;
    --grid-row-gap: 20px;
  }
  ._2zGEgL7djcf1MlDNggL2nK{
    padding: 0 15px ;
  }
}

@media (max-width: 768px) {
  :root {
    --PageMyLibrary-grid-width: 410px;
    --PageMyLibrary-grid-cover-width: 300px;
    --grid-column-gap: 30px;
    --grid-row-gap: 10px;
  }

  /* .gridCover{
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
}
@media (max-width: 767px) {
  :root {
    --PageMyLibrary-grid-width: 280px;
    --PageMyLibrary-grid-cover-width: 300px;
    --grid-column-gap: 30px;
    --grid-row-gap: 10px;
  }
  ._2Vz26hn4RqobyuEZx7jDC5{
    display: block;
  }
  ._2Vz26hn4RqobyuEZx7jDC5 > div {
    margin-bottom: 15px;
  }
  ._2zGEgL7djcf1MlDNggL2nK{
    padding: 0 10px ;
  }
  ._2zGEgL7djcf1MlDNggL2nK:first-child{
    margin-bottom: 10px;
    width: 100%;
  }
  ._2zGEgL7djcf1MlDNggL2nK:first-child{
    border-right: 0;
    padding-right: 0;
  }
  ._3th9OUz-RJR1N-XtJoiJot {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
._1v6GxTRlKiNJk-WJXs7fWI {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageMyLibrary-grid-width), 1fr)
  );
  grid-column-gap: 20px;
  grid-row-gap: 40px;
}
._3th9OUz-RJR1N-XtJoiJot {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageMyLibrary-grid-cover-width), 1fr)
  );
  grid-column-gap: var(--grid-column-gap);
  grid-row-gap: var(--grid-row-gap);
}
._3qLF6u5OJDCRr0GBrlJhLd .react-tabs__tab {
  padding: 0 10px 20px 10px;
  border: none;
  background-color: transparent;
  color: var(--color-content-default3);
  font-weight: bold;
}

._3qLF6u5OJDCRr0GBrlJhLd .react-tabs__tab--selected {
  border-bottom: 4px solid var(--color-content-highlight-hover);
  background-color: transparent;
  color: var(--color-content-default1);
}

._3qLF6u5OJDCRr0GBrlJhLd .react-tabs__tab-list {
  margin: 0 0 20px 0;
}
._34VJ1rOs4WxgLinx0eQDmr {
  text-decoration: none;
  margin: 0;
  text-overflow: ellipsis;
  font-size: var(--title-fontSize);
  font-weight: bold;
  color: var(--color-thumbnail-primary1);
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 40px 0 40px 0;
}
