._3jR0iket1UXiLCFmAMQfg4 {
  position: relative;
}
._3jR0iket1UXiLCFmAMQfg4:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  content: "";
  background: var(--color-modal-overlay)
    url(/assets/background_login.jpg) no-repeat top center;
  background-size: cover;
}
