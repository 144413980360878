.qZJEDy54LEoPZLKJ3m2-l {
  color: var(--color-content-highlight-hover);
  font-size: var(--fontSize-m);
  font-weight: bold;
}

._3B1zikJPyFI9FYzpz_yqFM {
  color: var(--color-content-default3);
  font-weight: normal;
  margin-left: 22px;
  margin-right: 22px;
}

.ooGroOd7sJTY3KYlGyydp {
  display: inline;
}

.dsADijDqVT2b9tkGN3gS-,
.dsADijDqVT2b9tkGN3gS- > svg {
  fill: var(--color-content-highlight-hover);
  width: 33px;
  height: 33px;
  vertical-align: middle;
  padding: 0 5px;
}

._1oEJIS63nNDn6iY0e1y0WJ,
._1oEJIS63nNDn6iY0e1y0WJ > svg {
  fill: var(--color-content-default3);
  stroke: var(--color-content-highlight-hover);
  stroke-width: 1px;
  width: 33px;
  height: 33px;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 5px;
}

@media (max-width: 767px) {
  .qZJEDy54LEoPZLKJ3m2-l {
    font-size: var(--fontSize-s);
  }
}
