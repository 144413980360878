:root {
  --bannerFullWidth: 100%;
  --bannerFullHeight: 256px;
}

@media (max-width: 1024px) {
  :root {
    /* --bannerFullWidth: 914px; */
    --bannerFullWidth: 100%;
    --bannerFullHeight: 228px;
  }
}

@media (max-width: 768px) {
  :root {
    /* --bannerFullWidth: 768px; */
    --bannerFullWidth: 100%;
    --bannerFullHeight: 192px;
  }
}

@media (max-width: 420px) {
  :root {
    /* --bannerFullWidth: 360px; */
    --bannerFullWidth: 100%;
    --bannerFullHeight: 93px;
  }
}

._3mWwUVlVTpKWNBz0zZV3Up {
  position: relative;
}

.e7mROewwUqkxt4YhEefv3 {
  background-color: var(--color-slideshow-primary2);
  width: 100%;
  height: auto;
  position: relative;
  margin: 0 auto;
}
