.-ZBiS_jr9XOfLz-5CrqJo {
  background: var(--color-background-asset);
  width: 100%;
  height: 100%;
}

._2vKEnhyScR5flrJEn4pJbV {
  color: var(--color-content-default1);
  text-align: center;
  font-size: var(--fontSize-xl);
}

._3bpobRrXstpJNU8zSl7Ed_ {
  width: 400px;
  height: auto;
}
