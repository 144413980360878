._3ED9NwWPPTox9nzFQ6q1dx {
  color: var(--color-content-default1);
  font-weight: bold;
  font-size: var(--fontSize-m);
}

._2W_H0pP_jhwk19bGxrDrQy {
  color: var(--color-content-default1);
  float: right;
  font-size: var(--fontSize-m);
  font-weight: normal;
  text-decoration: none;
}

._21o5iN992pIDMF8kapGOP6 {
  stroke: var(--color-content-default1);
  margin-left: 10px;
  float: right;
}

.aI4bMHCyIECxk_eIUirM3 {
  position: relative;
}

._1251cO5mJBP_zHvjNkTVc1 {
  overflow: hidden;
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
  outline: none;
}

._34gHbORU1lwL7P7rJ5-g7h {
  width: 30px;
  height: 30px;
  position: absolute;
  left: -15px;
  top: calc(50% - 15px);
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.21, 0.43, 0.37, 0.99);
  cursor: pointer;
}

._1vJUkKAUDPS8I2tkAMxnxC {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: calc(50% - 15px);
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

.x0jWGzwA1epuTE9AlMcVb {
  stroke: var(--color-content-default1);
  width: 20px;
  height: 20px;
  margin: 0 auto;
}

._34gHbORU1lwL7P7rJ5-g7h:hover,
._1vJUkKAUDPS8I2tkAMxnxC:hover {
  background-color: var(--color-content-highlight-hover);
}

._34gHbORU1lwL7P7rJ5-g7h:hover .x0jWGzwA1epuTE9AlMcVb,
._1vJUkKAUDPS8I2tkAMxnxC:hover .x0jWGzwA1epuTE9AlMcVb {
  stroke: #000;
}

._34gHbORU1lwL7P7rJ5-g7h .x0jWGzwA1epuTE9AlMcVb {
  transform: rotate(180deg);
}

.G_xflpAf7nLmtQESHRSmd {
  display: inline-block;
  width: 89px;
  height: 135px;
  margin: 0 7.5px;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

.G_xflpAf7nLmtQESHRSmd:hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.G_xflpAf7nLmtQESHRSmd:first-child {
  margin-left: 0;
}

.G_xflpAf7nLmtQESHRSmd:last-child {
  margin-right: 0;
}

._1voPxJUoNu_986l5mj73Xj span {
  display: inline !important;
}
._1voPxJUoNu_986l5mj73Xj {
  display: inline-block;
  width: 125px;
  height: 70px;
  margin: 0 8px;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

._1voPxJUoNu_986l5mj73Xj:hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

._1_8AyLmW3jT-EOJbc7OVgy {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  z-index: 5;
  right: 0;
}

._1_8AyLmW3jT-EOJbc7OVgy::before,
._1_8AyLmW3jT-EOJbc7OVgy::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

._1_8AyLmW3jT-EOJbc7OVgy::before {
  border-width: 20px;
}

._1_8AyLmW3jT-EOJbc7OVgy::after {
  border-width: 20px;
  border-right-color: var(--color-border-right-padlock-container);
  border-top-color: var(--color-border-top-padlock-container);
}

._1yyu_ZPx0Ks8HX1hdkUSO7 {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 110px;
  right: 0;
}

._1yyu_ZPx0Ks8HX1hdkUSO7::after {
  border-radius: 50%;
  background-color: var(--color-border-top-padlock-container);
  position: absolute;
  bottom: 0;
  right: 0;
}

._3kp3LR8FtDJ0cW7rqjfOFB {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}

._1cww4HTnDZRUCiYrWYAsTC {
  position: absolute;
  top: 12px;
  left: -6px;
  z-index: 1;
}

._2dpe1aQpjKFyi4tjYgi2kg,
._2dpe1aQpjKFyi4tjYgi2kg svg {
  width: 16px;
  fill: var(--color-icon-playlock-fill);
}

._2dpe1aQpjKFyi4tjYgi2kg svg path {
  fill: var(--color-icon-playlock-fill);
}

._3Q34sxXNN6Quj9GRuYmgYx {
  position: relative;
  top: -5px;
}
._1voPxJUoNu_986l5mj73Xj:first-child {
  margin-left: 0;
}

._1voPxJUoNu_986l5mj73Xj:last-child {
  margin-right: 0;
}

.k_YyQrCb6QA_E8pbHdUsH {
  width: 100%;
  margin: 20px 0;
  opacity: 0.4;
}

._3Q34sxXNN6Quj9GRuYmgYx {
  position: relative;
  top: -5px;
}

._1v22G6M4OLZFhnyHaZ-GKd div {
  background-size: 35px;
}

._1hlQlPi4qpw8X0NuhOsJnj div {
  background-size: 35px;
}

@media (max-width: 456px) {
  ._1voPxJUoNu_986l5mj73Xj {
    width: calc(33% - 6px);
    height: auto;
    min-height: 52px;
    margin: 0 5px;
  }

  .G_xflpAf7nLmtQESHRSmd {
    width: calc(25% - 8px);
    height: auto;
    min-height: 52px;
    margin: 0 5px;
  }
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

._12C7XLP1xYElmXP7oUxjWS {
  position: relative;
}

._12C7XLP1xYElmXP7oUxjWS:focus {
  outline: none;
}

/*.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}*/

._3_OQqxBNNd1HzlMrQdUqAB {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

._12C7XLP1xYElmXP7oUxjWS._1JZ7wp5set7AeXjKPLq98H {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

._12C7XLP1xYElmXP7oUxjWS._1JZ7wp5set7AeXjKPLq98H ._3N4xFZT_Ds5nD51-KlRV9I {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

._12C7XLP1xYElmXP7oUxjWS._1JZ7wp5set7AeXjKPLq98H ._3N4xFZT_Ds5nD51-KlRV9I._3e4cHyzWuwWsJhKzwqxeOs {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

._2AZ6PUA8rYhAXnFYePDAU6 {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

._2AZ6PUA8rYhAXnFYePDAU6:hover {
  background: white;
  cursor: pointer;
}

._2AZ6PUA8rYhAXnFYePDAU6:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

._2AZ6PUA8rYhAXnFYePDAU6:active {
  opacity: 0.6;
}

._2AZ6PUA8rYhAXnFYePDAU6:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

._2F2JbY8hlkATzqWlWwhcin {
  fill: #333;
}

/* ---- previous/next buttons ---- */

._2KgG-_jxBsK-rLPwQUBQjl {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

._2KgG-_jxBsK-rLPwQUBQjl._3aCZ4pbqhp2ljzClU_UNuD {
  left: 10px;
}
._2KgG-_jxBsK-rLPwQUBQjl.q7qbDtq8xyGrwi3aQbPXZ {
  right: 10px;
}
/* right to left */
._3jX2QS_WM2A-hxhaQQgrlW ._2KgG-_jxBsK-rLPwQUBQjl._3aCZ4pbqhp2ljzClU_UNuD {
  left: auto;
  right: 10px;
}
._3jX2QS_WM2A-hxhaQQgrlW ._2KgG-_jxBsK-rLPwQUBQjl.q7qbDtq8xyGrwi3aQbPXZ {
  right: auto;
  left: 10px;
}

._2KgG-_jxBsK-rLPwQUBQjl ._2F2JbY8hlkATzqWlWwhcin {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.YkiXhJI7KEfikgvE51Qh7 {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

._3jX2QS_WM2A-hxhaQQgrlW .YkiXhJI7KEfikgvE51Qh7 {
  direction: rtl;
}

.YkiXhJI7KEfikgvE51Qh7 ._1qN0biTiDTlCMMOwi1bZ8J {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.YkiXhJI7KEfikgvE51Qh7 ._1qN0biTiDTlCMMOwi1bZ8J._2_iNdXnuztlnFzi7eIkSo1 {
  opacity: 1;
}
