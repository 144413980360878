:root {
  --PageHistory-grid-width: 320px;
}

@media (max-width: 1023px) {
  :root {
    --PageHistory-grid-width: 290px;
  }
}

@media (max-width: 767px) {
  :root {
    --PageHistory-grid-width: 280px;
  }
}

._1p1pmCU_xxeSuXPFNRsEb3 {
  min-height: calc(100vh - 130px); /*margin-top + margin-bottom */
}

._2Q_EQYPyznpTaoPCoCFxkP {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageHistory-grid-width), 1fr)
  );
  grid-column-gap: 20px;
  grid-row-gap: 40px;
}

@media only screen and (min-width: 1920px) {
  ._2Q_EQYPyznpTaoPCoCFxkP {
    grid-column-gap: 35px;
  }
}

._2Vjg216mAx32uFIHU82gLz h1 {
  margin-top: 50px;
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
}
