:root {
  --MenuSeparator-margin: 20px;
}

._1vk9yi8dPySREJQHlk_BhQ {
  height: 1px;
  width: calc(100% - 2 * var(--MenuSeparator-margin));
  background: var(--color-nav-separator);
  margin: var(--MenuSeparator-margin)
}
