._3TNTE12GERP8gGomcz0Fna {
  position: relative;
  margin: 0 var(--marginBetweenThumbnails)
}

.BP0quiwYLwtzDsVH53zWw {
  cursor: pointer;
  text-decoration: none;
  margin: 0 var(--marginBetweenThumbnails) 0 0;
  position: relative;
  transition: all 250ms cubic-bezier(.21, .43, .37, .99);
  overflow: hidden;
  border-radius: 50%;
}

._1F2MyNGHSPy58ZJ8m1gcUn {
  transform: scale3d(1.1, 1.1, 1.1);
}

._3TNTE12GERP8gGomcz0Fna:hover .lXHkWem2Qq_ozhHY0-581 {
  display: none;
}

._197GYrjCi5qQAD55UysX3i {
  position: absolute;
  left: 50%;
  transform: translate(-17px, -50%);
  z-index: 50;
  width: 35px;
  height: 35px;
  top: 25px;
  text-align: center;
}

._3MVEsJ0blhxF599rdbUai4 {
  position: relative;
  margin: auto;
  vertical-align: middle;
  max-width: 35px;
  max-height: 35px;
  z-index: 1;
}

._2ISFeikAfPOJVns7xwkLF8 {
  text-decoration: none;
}

._1iApWljLRxPt4IxXPCkS {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.VBa6S75YB-V9ll49FAuI8 {
  color: var(--color-thumbnail-primary1);
  font-size: var(--fontSize-s);
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}

.lXHkWem2Qq_ozhHY0-581 {
  position: absolute;
  top: 120px;
  right: 50px;
}

.n8Bx-W-Wvw2llxbCy60pD {
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 50%;
  background-color: var(--color-thumbnail-highlight-hover1);
}

._3fNeN7I78KiDcvppmRUb-y {
  /*  padding-left: 9px;
  padding-top: 9px; */
  width: 24px;
  height: 24px;
  fill: var(--color-thumbnail-primary1);
}

.BP0quiwYLwtzDsVH53zWw:hover .lXHkWem2Qq_ozhHY0-581 {
  display: none;
}

@media (max-width: 767px) {
  .lXHkWem2Qq_ozhHY0-581 {
    top: 108px;
    right: 53px;
  }
}

._20qUl_hcZDlOvB4atjwJB7 {
  position: absolute;
  bottom: 40px;
  right: 5px;
  width: 40px;
  height: 60px;
  z-index: 1;
}

.gjbt4tyjmJ0ELSZb6d69E {
  fill: var(--color-thumbnail-highlight-hover1);
}