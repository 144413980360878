._3chhH5Vlal-SPqUCl2Jd6d {
  min-height: calc(100vh - 130px); /* margin-top + margin-bottom */
}

._1o_VcfKrVKIY4aeO137NkK {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--PageVODDetail-grid-width), 1fr));
  grid-column-gap: 25px;
  grid-row-gap: 20px;
}

@media(max-width: 1023px) {
  ._1o_VcfKrVKIY4aeO137NkK {
    grid-template-columns: repeat(auto-fill, 152px);
    grid-column-gap: 12px;
    grid-row-gap: 20px;
  }
}
