:root {
  --editorialBannerWidth: 100%;
}
._3QRY86ZLeYlPKFNSgXrM1a {
  position: relative;
}

._2KQl7Unhx6uFqodv65ULjp,.wCEolcnqAHdTTbq0cCcFA {
   height: auto; 
  position: relative;
  margin :50px 0;
  display: flex;
  flex-direction: row;
}
._3judpwOutRTSwDdpyvN7fK{
  flex:2;
  height: auto;
}
._1lci075qBt36kdp7m-wxA2{
  background-color: var(--color-promo-banner-txt-bg);
  color: var(--color-primary1);
  position: relative;
  flex:1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
._1mxDk4WddSFlbf3-eB0Pbm{
  margin: auto;
  margin: 0 20px;
}
._1jp0uOw-jXsI8CnR9SjDfP{
  margin-left: auto;
  margin-right: auto;
  -webkit-line-clamp: 10;
padding: 0 10px 0 10px;
   overflow: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
._2zQ9-R1I1GpsQhg0ajv8YQ{
  flex:2;
  height: auto;
}
@media (max-width: 1023px){
      ._2KQl7Unhx6uFqodv65ULjp {
        flex-direction: column;
      }
      .wCEolcnqAHdTTbq0cCcFA {
        flex-direction: column-reverse;
      }
  ._1mxDk4WddSFlbf3-eB0Pbm {
    margin: 0;
  }
    ._1jp0uOw-jXsI8CnR9SjDfP{
      -webkit-line-clamp: 5;font-size: var(--fontSize-s);
    }
    }