:root {
  --progressBarHeight: 3px;
}

._1TblEHl7EIJwYO9qIV0mr3 {
  /* width: 100%; */
  height: var(--progressBarHeight);
  /* background: var(--color-content-progressbar-buffer-empty1); */
  position: absolute;
}

._1VqvlLTFAE4Zqvewk8-xo9 {
  cursor: pointer;
}

._1cdedEVbgWwX_qnUKTuym2 {
  max-width: 100%;
  height: 100%;
  background: var(--color-content-progressbar-main);
  border-radius: 8px;
}

._3UBucX3BUpP4OfBkpRuGlw {
  max-width: 100%;
  height: 100%;
  background: var(--color-primary1);
  border-radius: 8px;
}
._1IIYIrE-Fx4TPOMejHYHrB {
  width: 100%;
  height: 100%;
  background: var(--color-content-progressbar-buffer-empty1);
  position: relative;
}

._1ro0_v0BoaIiZFkDUMvMxt {
  font-size: var(--fontSize-s);
  font-weight: bold;
  position: absolute;
  top: -8px;
  height: 23px;
  line-height: 23px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background: var(--color-content-progressbar-main);
  box-sizing: border-box;
  text-align: center;
  color: var(--color-secondary3);
  overflow: hidden;
  padding: 0 5px;
}
._KhozEpVSGGUoExDrfm4q {
  font-size: var(--fontSize-s);
  font-weight: bold;
  position: absolute;
  top: -8px;
  height: 23px;
  line-height: 23px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background: var(--color-primary1);
  box-sizing: border-box;
  text-align: center;
  color: var(--color-secondary3);
  overflow: hidden;
  padding: 0 5px;
}

._38vv9HUZaMYOn-CZ_4eUBr {
  position: absolute;
  top: -7px;
  width: 20px;
  height: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 3px var(--color-content-default1);
  border-radius: 24px;
  background: var(--color-content-progressbar-main);
  box-sizing: border-box;
}

@media (max-width: 767px) {
  ._1ro0_v0BoaIiZFkDUMvMxt {
    background: var(--color-content-progressbar-main);
    font-size: var(--fontSize-xs);
  }
  ._KhozEpVSGGUoExDrfm4q {
    background: var(--color-primary1);
    font-size: var(--fontSize-xs);
  }
}
