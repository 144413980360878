:root {
  --color: var(--color-nav-primary1);
  --iconSize: 20px;
}

._1XMbpeXI6N3dcsbpX01F15 {
  display: block;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  position: relative;
  color: var(--color);
  fill: var(--color);
  text-decoration: none;
  font-size: var(--fontSize-s);
}

._3Psi3ozyAYf9lCUd864UdR {
  display: flex;
  justify-content: flex-start;
}
.R4TLTytFvPpYjheEqR30q {
  display: block;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  position: relative;
  color: var(--color-nav-active);
  text-decoration: none;
  font-size: var(--fontSize-s);
}
._1aNGGbllLtJCnTDmyHlGhn {
  width: 200px;
  display: flex;
  flex-direction: column;
  background: var(--color-nav-background);
  border-radius: 5px;
}
._2Jo1CZp9QKlkxq51SgfRBm {
  cursor: pointer;
  padding: 5px;
  height: 28px;
  color: var(--color-nav-primary1);
  text-align: left;
  font-size: var(--fontSize-s);
}

._2Jo1CZp9QKlkxq51SgfRBm:hover {
  color: var(--color-nav-background);
  font-weight: bold;
  background-color: var(--color-nav-hover);
  font-size: var(--fontSize-s);
}
._1zuFnhoaoHq27f4hoXjHQI {
  cursor: pointer;
  padding: 5px;
  height: 28px;
  color: var(--color-nav-active);
  text-align: left;
  font-size: var(--fontSize-s);
}

._1zuFnhoaoHq27f4hoXjHQI:hover {
  color: var(--color-nav-background);
  background-color: var(--color-nav-hover);
  font-size: var(--fontSize-s);
}
._2Jo1CZp9QKlkxq51SgfRBm:hover:first-child,
._1zuFnhoaoHq27f4hoXjHQI:hover:first-child {
  color: var(--color-nav-background);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--color-nav-hover);
}
._2Jo1CZp9QKlkxq51SgfRBm:hover:last-child,
._1zuFnhoaoHq27f4hoXjHQI:hover:last-child {
  color: var(--color-nav-background);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--color-nav-hover);
}
._1XMbpeXI6N3dcsbpX01F15:hover,
._1XMbpeXI6N3dcsbpX01F15:hover path {
  fill: var(--color-nav-hover);
  color: var(--color-nav-hover);
}

.KErCkI6wjPDSZtZnt8bt7 {
  color: var(--color-nav-active);
}

.KErCkI6wjPDSZtZnt8bt7 ._1dKxZF7W3s2dDPcSLRsdYy,
.KErCkI6wjPDSZtZnt8bt7 ._1dKxZF7W3s2dDPcSLRsdYy path {
  fill: var(--color-nav-active);
}
.R4TLTytFvPpYjheEqR30q ._1dKxZF7W3s2dDPcSLRsdYy,
.R4TLTytFvPpYjheEqR30q ._1dKxZF7W3s2dDPcSLRsdYy path {
  fill: var(--color-nav-active);
}
._1dKxZF7W3s2dDPcSLRsdYy {
  height: var(--iconSize);
  margin: 10px 38px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  float: left;
}

._2gSBg1NhskHAEqDqrJSWP8 {
  float: right;
  direction: ltr;
}

._31_P-nuR5JxcroTZEriuQ7 {
  margin: 20px;
}

._3zfHBjucqQhrZ2IM9EmH7b {
  width: var(--iconSize-s);
}

._2pCOnry2XC7TUBm7zzSqPI {
  width: var(--iconSize-m);
}

._2kbpLfXiojJSS6em1A0b6p {
  width: var(--iconSize-l);
}

.OliGE1ebBxBvuC0j8szB8 {
  top: 10px;
  left: 60px !important;
}

._2Km02PrVmMDqBiY7cwyoyj {
  position: absolute;
  left: 90px;
  /* display: flex; */
  align-items: center;
  height: 40px;
}

.Um6dmos6sYapKsfMscpHc {
  right: 90px;
  left: 0;
}

._2wLqIbKVpxnxeziq0O4576 {
  visibility: hidden;
  opacity: 0;
}

._1Ets8cfMiHuRRCsUtLvi5e {
  opacity: 0;
  visibility: visible;
}

._2HxD_ApSl9Ss61csYD9G-r {
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.1s;
  opacity: 1;
  visibility: visible;
}

._2uueYOMMVJosjz4WwW5Svf {
  opacity: 1;
  visibility: visible;
}

._2CK4nO1Yl9UF5HxC6MaJk4 {
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms;
}

.XAApH6dJStZ-L9EGtooY2 {
  opacity: 0;
  transition: opacity 200ms;
}

._2NytUVgQdqnTOqhljZtD2G {
  visibility: hidden;
}

._2KPQr2WMJcifDd4CoeNsOT {
  /* To override the font-weight of the menu items change/add the menuFontWeight value in the variables file in customer skin folder of the client */
  font-weight: var(--menuFontWeight, regular);
}
._2KPQr2WMJcifDd4CoeNsOT span {
  color: var(--color-primary1);
}
