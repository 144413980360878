:root {
  --slideBannerWidth: 1024px;
  --slideBannerHeight: 256px;
}

@media (max-width: 1024px) {
  :root {
    --slideBannerWidth: 768px;
    --slideBannerHeight: 228px;
  }
}

@media (max-width: 768px) {
  :root {
    --slideBannerWidth: 768px;
    --slideBannerHeight: 192px;
  }
}

@media (max-width: 420px) {
  :root {
    --slideBannerWidth: 360px;
    --slideBannerHeight: 93px;
  }
}

._1Cn7JuZ8R0Pf5IKaZUJHeu {
  position: relative;
}

._2CE9lSLHKOG6RWW0O7rHkt {
  background-color: var(--color-slideshow-primary2);
  width: var(--slideBannerWidth);
  /* height; auto; */
  max-height: var(--slideBannerHeight);
  position: relative;
  margin: 0 auto;
}
