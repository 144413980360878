:root {
  --Profile-grid-width: 390px;
}

@media (max-width: 767px) {
  :root {
    --Profile-grid-width: 280px;
  }
}

._3mtgC9g5OM9Y-k5T_GUXTS {
  width: 300px;
  margin: 10% auto;
  text-align: center;
  color: var(--color-content-default1);
}

._3mtgC9g5OM9Y-k5T_GUXTS img {
  width: 100%;
}

._10ZiU0npknuP6etDR1pgn {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--Profile-grid-width), 1fr)
  );
  grid-column-gap: 20px;
  grid-row-gap: 40px;
}

._3C8WS83HGhsQdAaW7MCYpw {
  background-color: var(--color-content-overlay1);
  display: flex;
  align-items: center;
  padding: 60px 20px;
  height: 80px;
  cursor: pointer;
  width: 100%;
}

.PzPeWeoxKdU7l1SX7vFGr {
  justify-content: center;
}

.mdNHkQzk0zg95wpuUnAhu {
  width: 40px;
  height: 40px;
}

.mdNHkQzk0zg95wpuUnAhu path {
  fill: var(--color-content-default3);
}

._1WKk3sivXf6s8X_cbNGvd2 {
  border: solid 1px var(--color-content-highlight-hover);
}

._1sGEwyl2fXCxNCFPqIH3Dn {
  background: url(/assets/profile_default.png) no-repeat;
  width: 80px;
  height: 80px;
  background-size: contain;
}

._1x8KCUlpWxDABAXC7CpsNu {
  padding-left: 20px;
  vertical-align: middle;
}

._3vij88SO1cBQqVkGIblFZY {
  padding-right: 20px;
}

.-lk32zN8WB6FhNT8WEdj4 {
  font-weight: bold;
  font-size: var(--fontSize-xl);
  color: var(--color-content-default1);
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._3wzj9gjPZoobEgVN450Ut7 {
  font-size: var(--fontSize-m);
  color: var(--color-content-default3);
}

._2tzLyy_Iw3KeV2l7LLvhPg {
  height: auto;
  width: 358px;
}

._3mtgC9g5OM9Y-k5T_GUXTS h1 {
  margin-top: 50px;
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
}

._2MhI_Kzf24Ghw95Y2Y2jUj {
  background-color: var(--color-content-highlight-hover);
  text-align: center;
  color: var(--main-profile-label-font, var(--color-secondary3));
  position: absolute;
  border-radius: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  padding: 3px;
  font-size: var(--fontSize-xs);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
}

._2iLmJJfRmyrvEqFo9gD5ot {
  position: relative;
}

@media (max-width: 767px) {
  ._1sGEwyl2fXCxNCFPqIH3Dn {
    background-size: 60px;
    width: 60px;
    height: 60px;
  }

  ._3C8WS83HGhsQdAaW7MCYpw {
    height: 60px;
  }

  .PzPeWeoxKdU7l1SX7vFGr {
    height: 60px;
  }
}
