._3JHvTK1PyWj2qtk6k7-yFJ {
  margin: 0 auto;
  width: 50px;
  height: 26px;
  text-align: center;
}

._1yRwe09fkIZXyfYhcrv3GQ {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

._3TTzhOJ-UhIssZAWYNdwY2, .SfUskGLvxPKBZeQWpux0Y, ._37PKGYRv1TvoZcD1ZqZbMN, ._3nrbpLUNlbZALlYGQIpe1m, .JYDunV4CGst2QPHbgE_LN {
  background-color: var(--color-content-default1);
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 0 1px;
  animation: _2ayWeO2IMowvtN61nFgFGs 1.2s infinite ease-in-out;
}

._3TTzhOJ-UhIssZAWYNdwY2 {
  animation-delay: -1.2s;
}

.SfUskGLvxPKBZeQWpux0Y {
  animation-delay: -1.1s;
}

._37PKGYRv1TvoZcD1ZqZbMN {
  animation-delay: -1s;
}

._3nrbpLUNlbZALlYGQIpe1m {
  animation-delay: -0.9s;
}

.JYDunV4CGst2QPHbgE_LN {
  animation-delay: -0.8s;
}

@-webkit-keyframes _2ayWeO2IMowvtN61nFgFGs {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
  transform: scaleY(1); }
}

@keyframes _2ayWeO2IMowvtN61nFgFGs {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
