._1hUojF1rRFI8DGOXCIPluf {
  background: var(--color-background-asset);
  width: 100%;
  height: 100%;
}

._2oNT5OHpph7QVYh6DcUbF {
  color: var(--color-content-default1);
  text-align: center;
  font-size: var(--fontSize-xl);
}

._5P5rqk_11jygX6FpvEGPK {
  width: 400px;
  height: auto;
}

@media (max-width: 1023px) {
  ._1hUojF1rRFI8DGOXCIPluf {
    max-height: calc(100vh - 59px);
  }
}
