._37SLI30q-ygTdlh6u5TnJF {
  width: 100%;
  height: 100vh;
  background: var(--color-background-asset);
  overflow: hidden;
}

._247zkU8oyEdgb7f5DN4QVr {
  width: 100%;
  background: var(--color-background-asset);
  overflow: hidden;
}

._3qx-e9AWKasty1VYdXY8cR {
  width: 100%;
  height: 100%;
}

._2GdC_fNbzv34U8a0UvJBrT {
  width: 100%;
  height: 100%;
}

._1RLDEJmekW4dqEC8D8N72- {
  position: absolute;
  left: 44px;
  top: 30px;
  right: 50px;
  color: var(--color-player-default1);
  display: flex;
  align-items: center;
  height: 60px;
}

._2OvO3V4FkztSHbXizUkLIG {
  width: 24px;
  height: 32px;
  transform: rotate(180deg);
  stroke: var(--color-player-default1);
  stroke-width: 2px;
  cursor: pointer;
  transition: stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

._2OvO3V4FkztSHbXizUkLIG:hover {
  stroke: var(--color-player-highlight-hover1);
}

._2pcm0wLY59v0gCkSQABmGj {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-left: 15px;
}

._10ujZbQBqCC5eIuZG6LIt8{
  display: inline-block;
  margin-left: 20px;
  font-size: var(--fontSize-l);
}

.m2n4_EosrhGmXRQ-ukEkK {
  font-weight: bold;
  margin: 0;
}

._3rfq9bM6lhCwsVcdllHvt {
  margin: 0
}

.m2n4_EosrhGmXRQ-ukEkK + ._3rfq9bM6lhCwsVcdllHvt {
  margin-top: 10px;
}


@media (max-width: 767px) {
  ._1RLDEJmekW4dqEC8D8N72- {
    left: 15px;
    top: 0;
    display: inline-flex;
  }
  ._2pcm0wLY59v0gCkSQABmGj {
    margin-left: 10px;
  }

  ._2OvO3V4FkztSHbXizUkLIG {
    height: 20px;
    width: 13px;
  }

  ._10ujZbQBqCC5eIuZG6LIt8 {
    margin-left: 10px;
  }

  .m2n4_EosrhGmXRQ-ukEkK {
    font-size: var(--fontSize-s);
  }

  ._3rfq9bM6lhCwsVcdllHvt, .IzYRBlcB8Yn2E_zrRcXBR {
    font-size: var(--fontSize-xs);
  }

  ._3rfq9bM6lhCwsVcdllHvt {
    margin: 0;
  }
}
