._2Nn9KIksdQ-2Eyr3FtFwQG {
    padding: 10px;
}

.CchJTzlKR_EODbzFiJt-l {
    width: 100%;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 24px 0 24px 0;
}

.CchJTzlKR_EODbzFiJt-l:first-child {
    border-top: none;
}

._3LRNF2soebxozJ8ZjixToQ {
    display: flex;
}

._2tzFw7fPfnf7Wu7zYFA9xn {
    flex: 0.5;
    margin-right: 19px;
}

._1asPmaL1kBq32qkWLOIUTu {
    display: flex;
    align-items: center;
}

._1X5eY3t4Zqh--EAilwEv2Z {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

._3heTZoxIQtNkHNZAd4Qpxo {
    background: none;
    font-family: inherit;
    border: 0;
    cursor: pointer;
    line-height: normal;
    overflow: visible;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffca30;
}

._3iT-Mn5mViP8tP22nTpDdV {
    margin-bottom: 5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-top: 0;
    line-height: normal;
    letter-spacing: normal;
    font-size: 16px;
    color: #ffffff;
}

.NwlOvVEIropIoe2s8BRwR {
    margin: 0;
    color: #8c8c8c;
}


._3J8Rd-quJlwf8ul4-foghh {
    float: left;
}

._3Bvy2SnKUzJHkY96bZ93ZT {
    float: right;
}

._1ZvZtTo0EDvraEdm_VZuKo {
    color: var(--color-content-default1);
    width: 330px;
    border-radius: 3px;
    min-height: 260px;
}

._1RwT2cuwpIXF50bKbCo3oX {
    width: auto;
    height: 44px;
    margin: 33px 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-primary-1);
}