.O7JKMEYEnvU7MSny-zQfG {
  position: relative;
}

.RkvCANY6ooLmO8w7cP-db {
  display: none;
}

._142yuxADi_XscFiNMN_Ygf {
  display: block;
  margin-top: 10px;
}

._1vxw8Co5JxJ5ldsnNclBgP {
  font-size: var(--fontSize-l);
  font-weight: bold;
  cursor: pointer;
}

._3QSslgvK1WtFWTl6qZM_NH {
  cursor: pointer;
  width: 20px;
  position: absolute;
  top: -3px;
  right: 5px;
}

._3QSslgvK1WtFWTl6qZM_NH path {
  stroke: var(--color-content-default3);
}

.CY6OKSu_0BBqt35hWDdqn {
  transform: rotate(-270deg);
}

.gY1jO2zY4q9nKnfvx1q-C {
  transform: rotate(270deg);
}

._2WaVYxVPgSGt7LqBTvBgMM {
  right: unset;
  left: 5px;
}