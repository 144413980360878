._2iZALdQPeu7_WSmRRmk96Z {
  color: var(--color-login-error);
  /* position: absolute; */
  left: 0;
  right: 0;
  text-align: left;
  margin-left: 20px;
  font-weight: bold;
}

._369_T_OFFp-NackeyhqyDp p {
  font-weight: bold;
}

._2Qk9YpCaCQOQz2gzttpKUy {
  margin-top: 45px;
}

._2oOWeKQMIQObzfiTxFeZnh {
  width: calc(100% - 10px);
  height: 50px;
  padding-left: 10px;
}

._1YynTmLaPQhV-mYwmg9AGj {
  margin: 25px 0;
}

._1-HrzbA7-HHq1-idbFglec {
  margin: 40px 0;
}

._3dt5wXt-EHV2bVvJ_I2MyW {
  color: var(--color-button-login-link-font);
}

._3dt5wXt-EHV2bVvJ_I2MyW:hover {
  color: var(--color-button-link-font-hover);
}

._1Fvyz72Bh0HqsC3G_baxcW {
  font-size: var(--fontSize-s);
  width: 240px;
  line-height: 27px;
}

._2KUeaFcxqb8BRnoUwpPLyd {
  text-align: right;
  /* margin-bottom: 50px; */
  padding-right: 10px;
  /* margin-right: -53px; */
}

.SzBfxspxJRgmJBjyjFiuQ {
  color: var(--color-login-primary2);
  padding-right: 10px;
}

._1EGStiIC4aB-2UqfSIYBkk ._2KUeaFcxqb8BRnoUwpPLyd {
  padding-right: 0px;
}

._1KooZRFiFtGkDC_vgVvEfO ._2KUeaFcxqb8BRnoUwpPLyd {
  padding-right: 0px;
}

._1-6-X36WPwTm50_g5OecJ5 {
  font-size: var(--fontSize-s);
  width: 240px;
  margin: 0;
  height: auto;
}

@media (max-width: 767px) {
  ._1-6-X36WPwTm50_g5OecJ5 {
    width: 180px;
  }
}

._2FLkxBEv3IdiB_0-LCVtKm {
  margin-top: 35px;
}

._15bXB73SRAbrdVMTmmvghe {
  width: 100px;
  margin: 20px;
}
._3DU1WA7rL6I4kXwJvFVNyI{
  width: 280px;
  height: 140px;
  margin: 5px;
}
