._1v2zQzPL8BbK_WPRadyoVQ {
  display: flex;
	height: 24px;
	cursor: pointer;
}

.Cxv5LoAAVJisvr6x-FM3S {
  fill: var(--color-player-default1);
  transition: fill .3s ease-in-out;
}

.Cxv5LoAAVJisvr6x-FM3S:hover {
  fill: var(--color-player-highlight-hover1);
}

._3VC-QIoP_kaMdEsyDlYAQ7 {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 5px;
  padding: 0 2px;
}

.oE__2VflB2ZeyFd1F54ZL {
	border-radius: 4px;
	width: 3px;
  background: grey;
  margin: 0 auto;
  transition: background .3s ease-in-out, height 500ms ease-in-out;
}

._3VC-QIoP_kaMdEsyDlYAQ7:hover .oE__2VflB2ZeyFd1F54ZL {
	background: var(--color-player-highlight-hover1);
}

._2jbjsw_ZM1nMhdeMxpXh2E {
	background: var(--color-player-default1);
}

._1Ndz84uEbKtaJscs-xJsv- {
	height: 30%;
  margin-top: 20%;
}

._3VC-QIoP_kaMdEsyDlYAQ7:hover ._1Ndz84uEbKtaJscs-xJsv- {
  height: 45%;
}


._22KIb_pzYRcvCVrQS5Gbtp {
	height: 50%;
}

._3VC-QIoP_kaMdEsyDlYAQ7:hover ._22KIb_pzYRcvCVrQS5Gbtp {
	height: 65%;
}

.Vk3FvIGfs6nBoO6wnV9Vv {
	height: 66.67%;
}

._3VC-QIoP_kaMdEsyDlYAQ7:hover .Vk3FvIGfs6nBoO6wnV9Vv {
	height: 80%;
}

.UEHbiXVPN1T1YVFkhXb6P {
	height: 83.33%;
}

._3VC-QIoP_kaMdEsyDlYAQ7:hover .UEHbiXVPN1T1YVFkhXb6P {
	height: 98%;
}

.C_mEeBCGHXQfE7sMGj0RZ {
  height: 100%;
}

._3VC-QIoP_kaMdEsyDlYAQ7:hover .C_mEeBCGHXQfE7sMGj0RZ {
	height: 115%;
}

