._37dccblCqD99334UJfsQN1 {
  display: inline-block;
  margin-right: 10px;
  border-radius: var(--borderRadius-select-normal);
}

.RV6WuXY1FuE1ldpeSoQY1 {
  background-color: var(--color-select-background);
  width: 140px;
}

._37dccblCqD99334UJfsQN1 .react-select__control {
  width: 140px;
  height: 40px;
  background-color: var(--color-select-background-hover);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px;
  border-color: var(--color-select-background-hover);
  box-shadow: none;
  border-radius: var(--borderRadius-select-normal);
}

._37dccblCqD99334UJfsQN1 .react-select__single-value {
  width: 140px;
  color: var(--color-select-background);
  background-color: var(--color-select-background-hover);
  font-size: var(--fontSize-s);
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
}

._37dccblCqD99334UJfsQN1 .react-select__single-value:hover {
  background-color: var(--color-select-background-hover);
  color: var(--color-select-background);
}

._37dccblCqD99334UJfsQN1 .react-select__menu-list {
  background-color: var(--color-select-background);
  border-radius: var(--borderRadius-select-normal);
  box-shadow: var(--boxShadow-select-normal);
}

._37dccblCqD99334UJfsQN1 .react-select__menu {
  background-color: var(--color-select-background);
  color: var(--color-select-primary1);
  width: 140px;
}

._37dccblCqD99334UJfsQN1 .react-select__placeholder {
  display: none;
}

._37dccblCqD99334UJfsQN1 .react-select__indicator-separator {
   display: none;
}

._37dccblCqD99334UJfsQN1 .react-select__input {
  display: inline-block;
}

._37dccblCqD99334UJfsQN1 .react-select__dropdown-indicator {
  color: var(--color-select-background);
}

._37dccblCqD99334UJfsQN1 .react-select__option {
  font-size: var(--fontSize-s);
}

._37dccblCqD99334UJfsQN1 .react-select__option:hover {
  background-color: var(--color-select-background-hover);
  color: var(--color-select-background);
}

._37dccblCqD99334UJfsQN1 .react-select__option--is-focused {
  background-color: var(--color-select-background-hover);
  color: var(--color-select-background);
  font-weight: bold;
}

._37dccblCqD99334UJfsQN1 .react-select__option--is-selected {
  background-color: var(--color-select-background);
  color: var(--color-select-highlight-active);
  font-weight: bold;
}
