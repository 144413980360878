._3zAeJ-3PlKMuNgrJG-XAh6 {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

._1O3CPtwn96G_e5CykE3ltZ {
  fill: var(--color-player-default1);
  transition: fill 0.3s ease-in-out;
}

._1O3CPtwn96G_e5CykE3ltZ:hover,
._1FG3VCjONg8Wuckgg40uTR,
._1O3CPtwn96G_e5CykE3ltZ:hover svg path,
._1FG3VCjONg8Wuckgg40uTR svg path {
  fill: var(--color-player-highlight-hover1);
}

._1oI4uNsIPG-1nGh5I9JDci {
  background: var(--color-content-overlay2);
  color: var(--color-player-default1);
  width: fit-content;
  height: auto;
  position: fixed;
  bottom: 94px;
  right: 40px;
  border-radius: 5px;
  display: flex;
  padding: 30px;
  z-index: 2001;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  z-index: 999999;
}

.QLv1TY43G1OjX-1l6PAlj {
  width: 331px;
  height: 200px;
  bottom: 95px;
  right: 138px;
}
@media (max-width: 767px) {
  .QLv1TY43G1OjX-1l6PAlj {
    width: fit-content;
    height: auto;
    bottom: 95px;
    right: 40px;
  }
}
._2BDko6Y3PcGGh3rVyzmDcE {
  float: left;
}
._2bbfEmwfDV2-r6paFFqmk7 {
  float: right;
}
._3GQvOdd_cv5QOmYZKfXBwJ {
  right: 330px;
}
._2bbfEmwfDV2-r6paFFqmk7 > *,
._2BDko6Y3PcGGh3rVyzmDcE > * {
  width: -moz-fit-content;
  width: fit-content;
}

._1Bu6TuGdWRCAZMwfD20-gX {
  font-weight: bold;
  font-size: 0.9793rem;
  text-transform: capitalize;
}

.VWGbv9y-UUQVBoHZohNNl {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 360px;
  overflow-x: hidden;
  animation: _39ZnClotUKeLtGGnD9KxBd;
  border-radius: var(--borderRadius-scrollbar-track);
  scrollbar-color: var(--color-scrollbar-corner);
  scrollbar-width: thin;
}

._2btse_k3u5c_eDXPuRUuKr {
  cursor: pointer;
  display: flex;
  margin: 15px 0;
  line-height: 20px;
}

.h8Nb4V9JgfIOBC-WtN0fE {
  color: var(--color-player-highlight-hover1);
}

.Y2ne2onREndN-XEsp0DDN {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid var(--color-player-default1);
  display: inline-block;
  margin: 0 5px 0;
  position: relative;
  box-sizing: border-box;
}

.lBuC2wAil16zz1AniL7zT {
  border-color: var(--color-player-highlight-hover1);
}

.lBuC2wAil16zz1AniL7zT:before {
  position: absolute;
  content: "";
  background: var(--color-player-highlight-hover1);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  top: 3px;
  left: 3px;
}
