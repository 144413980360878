._2bD-ZzGwEU_V9nBfq_kpWp ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

._2bD-ZzGwEU_V9nBfq_kpWp ul._1M-rCtPzM8ezivyDG5UAD3 {
  display: flex;
  flex-flow: column;
}

._2bD-ZzGwEU_V9nBfq_kpWp ul._3AIzOhIJDZW3iMtovCmuu0 {
  display: flex;
  width: calc(100% + 20px);
  margin: 0 -10px;
  height: 100%;
}
._1aszB0h9E3n5NemsBkHdur{
  display: flex;
  align-items: center;
  justify-content: center;
}
._2bD-ZzGwEU_V9nBfq_kpWp ul li {
  display: block;
  position: relative;
  width: 100%;
  min-height: 140px;
  margin: 0 0 20px 0;
}

._2bD-ZzGwEU_V9nBfq_kpWp ul._3AIzOhIJDZW3iMtovCmuu0 li {
  margin: 0 10px 10px 10px;
}

._2bD-ZzGwEU_V9nBfq_kpWp ul li input[type=radio] {
  position: absolute;
  visibility: hidden;
}

._2bD-ZzGwEU_V9nBfq_kpWp ul li label {
  display: block;
  position: relative;
  height: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--color-content-default1);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  -webkit-transition: all 0.25s linear;
}


._2bD-ZzGwEU_V9nBfq_kpWp ul li ._3ig5VUp6iFV3yvQVMEZEhh {
  display: block;
  position: absolute;
  border: 2px solid var(--color-content-default2);
  border-radius: 100%;
  height: 18px;
  width: 18px;
  top: 10px;
  right: 10px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
  cursor: pointer;
}

._2bD-ZzGwEU_V9nBfq_kpWp ul li ._3ig5VUp6iFV3yvQVMEZEhh::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 10px;
  width: 10px;
  top: 2px;
  left: 2px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked ~ ._3ig5VUp6iFV3yvQVMEZEhh {
  border: 2px solid var(--color-content-highlight-hover);
}

._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked ~ ._3ig5VUp6iFV3yvQVMEZEhh::before {
  background: var(--color-content-highlight-hover);
}

._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked ~ label {
  border: 1px solid var(--color-content-highlight-hover);
  color: var(--color-content-highlight-hover);
  background-color: var(--color-content-overlay3);
}


._2bD-ZzGwEU_V9nBfq_kpWp ul li label._3PMsHdCIwVSzeYs-g5LFcH {
  background-image: url(/assets/free-default.png);
  background-size: 180px;
}

._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked ~ label._3PMsHdCIwVSzeYs-g5LFcH {
  background-image: url(/assets/free-active.png);
  background-size: 180px;
}
._3FSqSO1dwVDCaWg0A-LCUL{
  position: relative;
  width: 50%;
  height: 50%; 
}
._2bD-ZzGwEU_V9nBfq_kpWp ul li label {
  display: flex;
  justify-content: center;
  align-items: center;

}

._2bD-ZzGwEU_V9nBfq_kpWp ul li label._3cU5AMg6txTg293mt3QCgR {
  display: block;
  position: relative;
  height: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  -webkit-transition: all 0.25s linear;
  background: white;
  width: 422px;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked ~ label._3cU5AMg6txTg293mt3QCgR {
  border: 1px solid var(--color-content-highlight-hover);
  background-color: var(--color-content-overlay3);
}
._2Yw8DWToQ8P4JnIlOv_jge{
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 600;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked  ~ label   p._2Yw8DWToQ8P4JnIlOv_jge {
  color:var(--color-primary3) !important;
  font-size: 11px;
  font-weight: 600;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked  ~ label   p {
  color:var(--color-primary1) !important;
  font-size: 14px;
  font-weight: bold;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked  ~ label  div._1UKn7KLA6-ddl89pn0rrkl {
  color:var(--color-primary1) !important;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]  ~ label  p._2Yw8DWToQ8P4JnIlOv_jge {
  color:var(--color-primary3) !important;
  font-size: 11px;
  font-weight: 600;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]  ~ label   p {
  color:var(--color-secondary4) !important;
  font-size: 14px;
  font-weight: bold;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]  ~ label  div._1UKn7KLA6-ddl89pn0rrkl {
  color:var(--color-secondary1) !important;
}
._1UKn7KLA6-ddl89pn0rrkl{
  padding-left: 10px;
    height: 110px;
    font-size: 11px;
    font-weight: 600;
}
._1QpqmCfZjAxY7yOv2UtSiW{
  margin-bottom: 5px;
  margin-top: 30px;
}
._2Ieqv0ESM-HDMc3Hd5Vane{
  display: flex;
  flex-direction: row;
  width: 200px;
  height: 70px;
  position: absolute;
  top: 54px;
  left: 214px;
  justify-content: flex-end;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio] ~ ._3cU5AMg6txTg293mt3QCgR ._2Ieqv0ESM-HDMc3Hd5Vane > div {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center -10px;
  width: 110px;
}
.KZQPquGabSQolV1sSN0o7{
  width: 38px;
  height: 24px;
}
._1LwRBfjHHryMHg4xKzRuNS{
  cursor: pointer;
    width: 100%;
    height: 100%;
    min-height: inherit;
    position: relative;
    display: flex;
    overflow: hidden;
    font-family: OpenSans;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: var(--color-secondary2);
    display: flex;
    align-items: center;
    justify-content: center;
}
._2DY5KDga-7qPsqZh6-wfNl{
  cursor: pointer;
    width: 100%;
    height: 100%;
    min-height: inherit;
    position: relative;
    display: flex;
    overflow: hidden;
    font-family: OpenSans;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: var(--color-primary1);
    display: flex;
    align-items: center;
    justify-content: center;
}
._2ghXeOYZj2pp9lNUGW1vkU{
  font-size: 18px;
  text-align: right;
  padding: 10px;
  margin-top: 25px;
  color: var(--color-secondary2);
  font-family: "OpenSans";
  font-weight: bold;
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked ~ ._3cU5AMg6txTg293mt3QCgR ._2Ieqv0ESM-HDMc3Hd5Vane  ._2ghXeOYZj2pp9lNUGW1vkU{
  color: var(--color-primary1);
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked ~  label .OAo28qpS9aVdItWJZTd4w ._1LwRBfjHHryMHg4xKzRuNS {
  color: var(--color-primary1);
}
._2bD-ZzGwEU_V9nBfq_kpWp input[type=radio]:checked ~  label .OAo28qpS9aVdItWJZTd4w ._2DY5KDga-7qPsqZh6-wfNl {
  
  color: var(--color-secondary2);
}
._3RC4q2Jyg6ijFCQabr4q73{
  font-size: 16px;
  font-weight: bold;
}
._2bD-ZzGwEU_V9nBfq_kpWp ul li label.AYjzBJ0ScIckD4o7GhqR5 {
  display: block;
  position: relative;
  height: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  -webkit-transition: all 0.25s linear;
  background: white;
  display: flex;
  flex-direction: row;  
    justify-content: space-between;
  background-color: var(--color-content-overlay3);
}
._2iWD7BRuCBGym2gwU_wakv{
  height: 110px;
  font-size: 11px;
  font-weight: 600;
  /* position: absolute; */
  margin-left: 20px;
  margin-top: 15px;
}
._392AnWU80X5UGPE4FqDiI8 {
  display: block;
  width: fit-content;
  margin: 40px auto;
  text-transform: none;
  font-weight: normal;
  /* position: absolute; */
  margin-right: 25px;
}
._21gT4ZFg-AfNcyXbnvzlCL{
  /* position: absolute; */
  /* top: 30px; */
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cAOgkpVrZCYxEk1wA8AGf {
  justify-content: center;
  min-width: 160px;
}
@media (max-width: 767px) {
  ._2bD-ZzGwEU_V9nBfq_kpWp ul._3AIzOhIJDZW3iMtovCmuu0 {
    flex-wrap: wrap;
  }
  .cAOgkpVrZCYxEk1wA8AGf {
    display: flex;
    align-items: center;
  }
  ._2bD-ZzGwEU_V9nBfq_kpWp ul li label.AYjzBJ0ScIckD4o7GhqR5 {
    display: flex;
    flex-direction: column;  
      justify-content: center;
    background-color: var(--color-content-overlay3);
  }
  ._392AnWU80X5UGPE4FqDiI8 {
    margin:25px 10px;
  }
  ._21gT4ZFg-AfNcyXbnvzlCL{
    margin-left: 0;
  }
}
@media (max-width: 359px) {
  ._2bD-ZzGwEU_V9nBfq_kpWp ul._3AIzOhIJDZW3iMtovCmuu0 {
    flex-wrap: wrap;
  }
  .cAOgkpVrZCYxEk1wA8AGf {
    display: flex;
    align-items: center;
  }
  ._2bD-ZzGwEU_V9nBfq_kpWp ul li label.AYjzBJ0ScIckD4o7GhqR5 {
    display: flex;
    flex-direction: column;  
      justify-content: center;
    background-color: var(--color-content-overlay3);
  }
  ._392AnWU80X5UGPE4FqDiI8 {
    margin:25px 10px;
  }
  ._21gT4ZFg-AfNcyXbnvzlCL{
    margin-left: 0;
  }
  ._1aszB0h9E3n5NemsBkHdur{
    display: block;
  }
}
