:root {
  --PageAssetTVShow-linkBackPosition: 40px;
  --PageAssetTVShow-contentContainerPadding: 20px;
  --PageAsset-linkBackPositionTop: 63px;
  --PageAssetTVShow-title-fontSize: var(--fontSize-xxl);
}

@media (max-width: 767px) {
  :root {
    --PageAssetTVShow-title-fontSize: var(--fontSize-xl);
  }
}

.PmMTtaKF0IWn45qoYJyEH,
._1EVSTBJL5ZcgVmN3xWrV1F {
  background: var(--color-background-asset);
  height: 100%;
  width: 100%;
  overflow: auto;
}

._19JfFoDz-m00FTYjuQXREc {
  padding-bottom: 50px;
}

._3LPGjAQWYE0c7MAY68TfqX {
  position: absolute;
  top: 20px;
  left: var(--PageAssetTVShow-linkBackPosition);
  z-index: 100;
}

._3BNWAKdnvn08zNiminfKeV {
  position: absolute;
  top: 20px;
  right: var(--PageAssetTVShow-linkBackPosition);
  z-index: 100;
}

._3G66fJkQAT5v7GynlDqaED {
  max-width: 1024px;
  margin: 0 auto 50px;
  position: relative;
  padding: 0 var(--PageAssetTVShow-contentContainerPadding);
  z-index: 20;
}

._3t_q0bSssjLI4RP9wV_rQI {
  font-weight: normal;
  font-size: var(--fontSize-s);
  cursor: pointer;
  vertical-align: middle;
  border-color: var(--color-content-default3);
  min-width: 200px;
}

.M0djr8lJoCdumcVUmmmu- {
  vertical-align: middle;
  min-width: 200px;
  border-color: var(--color-content-default3);
}

.M0djr8lJoCdumcVUmmmu-:hover {
  background: transparent;
}

._348SpbAWw8p9Orbj8-a4p1 {
  height: 24px;
  width: 24px;
}

._2dMP22b47EOuYmonU51A5t {
  font-size: var(--fontSize-s);
  font-weight: bold;
  color: var(--color-content-default1);
  margin: 40px 0 20px;
}

._3OhmEdu36cAd0ipVBJ03-A,
._12nZdue4ogwBLIrCbpFXjB {
  font-size: var(--fontSize-m);
  color: var(--color-content-default2);
}

._12nZdue4ogwBLIrCbpFXjB {
  margin-top: 20px;
}

._1xJDAhiqk0MUtww0-HFkvY {
  border-top: 1px solid var(--color-content-default3);
  margin: 40px 0;
  width: 100%;
}

._34Cx1zXKKe8z92S0sXZZ8r {
  width: 100%;
  min-height: 500px;
  background-color: var(--color-background-asset);
  margin-top: 50px;
}

.PqvF55MdqzWhrA6UFmmVp {
  display: inline-block;
  margin-right: 20px;
}

._33IuR4d48qRS9oSQk4N-Ik svg {
  margin-right: unset;
}

._2qoz7nSRhCBM0-dTefHdVH {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;
}

._3B6zIUnWPjqUfExgiViqpW {
  text-transform: uppercase;
  position: absolute;
  opacity: 0.8;
  border-radius: 4px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 32px;
  line-height: 32px;
  background-color: var(--color-background-asset);
  color: var(--color-content-default1);
  font-size: var(--fontSize-s);
  font-weight: normal;
  text-align: center;
}

.gSsKDSp_UQp38qDdzs_-H {
  position: relative;
  bottom: 0;
  z-index: 50;
}

.ZBVEkN_PFDFXFi4lnKTBj {
  font-weight: normal;
  font-size: var(--fontSize-s);
  cursor: pointer;
  vertical-align: middle;
  margin-left: 20px;
}

._3HcuM-ILI9ZZF9gSMBAuIc {
  width: 24px;
  margin-right: 5px;
}

._3HcuM-ILI9ZZF9gSMBAuIc path {
  fill: var(--color-alternative-background) !important;
}

._2KAExfg-WUvhJi6OmmBPb2 {
  max-width: 490px;
  color: var(--color-primary);
}

._1S1sHfZhf9TuLKAdhdmDFU {
  font-size: var(--fontSize-s);
  font-weight: normal !important;
  text-decoration: none !important;
  padding-top: 10px;
}

._3p8DDoHphfpPls33OUvmu {
  line-height: 23px;
}

._3Krsg0jgK9uAJtkL4puDrA h1 {
  margin-top: 50px;
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-primary);
}

@media (max-width: 1024px) {
  ._2GgX0b71J4ObusZPoa13oM {
    height: 230px;
  }
}

@media (min-width: 768px) {
  .PqvF55MdqzWhrA6UFmmVp {
    display: inline-block;
    margin-right: 40px;
  }
}
@media (max-width: 768px) {
  .ZBVEkN_PFDFXFi4lnKTBj {
    margin-left: 5;
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  ._2ZquZsij6yV-yRN0zoCFn0 {
    display: block;
    text-align: center;
    height: 110px;
    width: 70px;
  }

  ._2ZquZsij6yV-yRN0zoCFn0 > a {
    margin: 0 auto;
  }

  ._2ZquZsij6yV-yRN0zoCFn0 > span {
    display: block;
  }

  ._3p8DDoHphfpPls33OUvmu {
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    padding: 0;
  }

  ._1IdfOLU3GyctYZQCot1giR {
    position: relative;
    height: 60px;
    line-height: 23px;
    width: 60px;
    border-radius: 30px;
    padding: 0;
  }

  ._348SpbAWw8p9Orbj8-a4p1,
  ._3HcuM-ILI9ZZF9gSMBAuIc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ._3OhmEdu36cAd0ipVBJ03-A,
  ._12nZdue4ogwBLIrCbpFXjB {
    font-size: var(--fontSize-s);
  }

  ._3LPGjAQWYE0c7MAY68TfqX {
    top: 20px;
    left: 14px;
  }
}
._2o5E4BrFobMStwkOd1Bpos {
  margin-right: 20px;
}

._1IdfOLU3GyctYZQCot1giR {
  margin-right: 40px;
  line-height: 23px;
}

._2_ZPdHbgm3yq0sgl_eux-v fieldset {
  margin: 0;
  padding: 0;
}

._1wqWDI-boaW06QoWWLPXLZ {
  margin-bottom: 35px;
}
