:root {
  --slideHeight-c1: 720px;
  --slideHeight-c2: 420px;
}

._3cdRtcxTC2mTJykDoiRKOy {
  width: 100%;
  height: 100vh;
  /* background: var(--color-background-asset); */
  background: var(--color-player-background);
  overflow: hidden;
  direction: ltr;
}
._3JrAGPdKmVEJqOHezza5fU {
  width: 100%;
  height: 100%;
  /* background: var(--color-background-asset); */
  background: var(--color-player-background);
  overflow: hidden;
}

._2rRKGZ4a2FR6RI3851GXrS {
  width: 100%;
  background: var(--color-player-background);
  overflow: hidden;
}

.-AE1_xR180OIDBzl9kxyv {
  height: var(--slideHeight-c2);
}

.aF-pRKJcDkWdO5DG5l_Pq {
  height: var(--slideHeight-c1);
}

.uG9QsjANiahJsrHt6WGoS {
  width: 100%;
  height: 100%;
  position: relative;
}

._1az5deO5nFGXS6aflBFtGa {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
}

._3_PLTzBCehWP7KciGUoovt {
  position: absolute;
  bottom: 35%;
  right: calc(10% - 15px);
  z-index: 9999;
  cursor: pointer;
  fill: var(--color-player-default1);
  transition: fill 0.3s ease-in-out;
}

.dPS9K5GM8zFGMrOHYwvtc {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
  fill: var(--color-player-default1);
  transition: fill 0.3s ease-in-out;
}

._3_PLTzBCehWP7KciGUoovt:hover {
  fill: var(--color-player-highlight-hover1);
}

/* Hide default player controls */
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}

@media (min-width: 2000px) {
  :root {
    --slideHeight-c1: 1200px;
    --slideHeight-c2: 50vw;
  }
}

@media (max-width: 2000px) {
  :root {
    --slideHeight-c1: 800px;
    --slideHeight-c2: 50vw;
  }
}

@media (max-width: 1023px) {
  :root {
    --slideHeight-c1: 560px;
    --slideHeight-c2: 56vw;
  }
  ._3cdRtcxTC2mTJykDoiRKOy {
    height: calc(100vh - 65px);
  }
}

@media (max-width: 767px) {
  :root {
    --slideHeight-c1: 510px;
    --slideHeight-c2: 110vw;
  }
}
