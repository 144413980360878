._33FX3UyS9mzaiI7RhGMnB4 {
  height: 24px;
  width: 24px;
  cursor: pointer;
  vertical-align: middle;
}

._3vIJKbKH_jLBUQiMo-aQfc {
  fill: var(--color-content-default1);
}

.t38kHPCDjFtWalFm5gmn0 {
  fill: var(--color-content-highlight-hover);
}

