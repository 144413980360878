:root {
  --PageAsset-headerHeight: 600px;
}

@media (max-width: 1440px) {
  :root {
    --PageAsset-headerHeight: 520px;
  }
}

@media (max-width: 1023px) {
  :root {
    --PageAsset-headerHeight: 462px;
  }
}

@media (max-width: 767px) {
  :root {
    --PageAsset-headerHeight: 420px;
  }
}

@media (max-width: 400px) {
  :root {
    --PageAsset-headerHeight: 225px;
  }
}
@media (min-width: 2000px) {
  :root {
    --PageAsset-headerHeight: 800px;
  }
}
@media (min-width: 3000px) {
  :root {
    --PageAsset-headerHeight: 1300px;
  }
}
@media (min-width: 4000px) {
  :root {
    --PageAsset-headerHeight: 1800px;
  }
}
._2Dz0e5rJv-rBboAvzrr3Vs {
  /* max-height: var(--PageAsset-headerHeight); */
  overflow: hidden;
  position: relative;
}

._23BvFnTbkKSYCqaMjRkf1u {
  position: relative;
  width: 100%;
  padding-bottom: 56%;
}

._23BvFnTbkKSYCqaMjRkf1u::before {
  content: "";
  display: block;
  height: 323px;
  object-fit: contain;
  position: absolute;
  background-image: var(--color-slideshow-overlay-before);
  width: 100%;
  z-index: 1;
}

._2Ba2BKrjJCzc6uae28jsF6 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: auto;
}

._3IAZ3Br45OU3clGigluWTD {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(var(--color-background-asset)),
    color-stop(70%, transparent)
  );
  background-image: -webkit-linear-gradient(
    bottom,
    var(--color-background-asset),
    transparent 70%
  );
  background-image: linear-gradient(
    0deg,
    var(--color-background-asset),
    transparent 70%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
