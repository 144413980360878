._1kbT8tR7ITAit9_hyUTcZJ {
  display: block;
  margin: 20px 20px 50px 0;
  position: relative;
}

._1AcvzG1Ik3dPRdJZ8YOwiu {
  width: 100%;
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 20px;
  place-items: start;
}

._1AcvzG1Ik3dPRdJZ8YOwiu > * {
  width: 100%;
}

@media (max-width: 1440px){
  ._1AcvzG1Ik3dPRdJZ8YOwiu > * {
    width: 96%;
  }
}