:root{
  --StepProgressBar-height: 5px;
  --StepProgressBar-height-icon: 40px;
}

._1LVmzLQ7zHQ40CfTnS9-Fm {
  width: 90%;
  margin: 0 auto 100px;
}

.yE8GLB2u_dHR7OJjPR8T5 {
  background: var(--color-stepEmptybar);
  position: relative;
}

.yE8GLB2u_dHR7OJjPR8T5 ._376teQsh1KrbFVf_5RDc4U {
  position: absolute;
  height: var(--StepProgressBar-height);
  background: var(--color-stepFullbar);
  transition: width 3s ease;
}

.yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA {
  display: flex;
  height: var(--StepProgressBar-height);
  justify-content: space-between;
  align-items: center;
}

.yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq {
  z-index: 2;
  position: relative;
}

.yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq ._19sd8opT7JhasH0Vcq5p_p {
  position: absolute;
  top: calc(100% + 25px);
  left: 50%;
  transform: translateX(-50%) perspective(1000px);
  white-space: nowrap;
  font-size: var(--fontSize-s);
  color: var(--color-stepLabel);
}

@media (max-width: 767px) {
  .yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq ._19sd8opT7JhasH0Vcq5p_p {
    display: none;
  }

  ._1LVmzLQ7zHQ40CfTnS9-Fm {
    width: 90%;
    margin: 50px auto 50px;
  }
}

.yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq span {
  display: block;
  color: var(--color-stepFont);
  font-size: var(--fontSize-s);
}

/*.checkoutProgress .progressBar .step .stepCheck {
  color: var(--color-content-default1);
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 3s ease;
  transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
}*/

.yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) perspective(1000px);
          transform: translate(-50%, -50%) perspective(1000px);
  width: var(--StepProgressBar-height-icon);
  height: var(--StepProgressBar-height-icon);
  background-color: var(--color-stepBG);
  border-radius: 50%;
  transition: all 5s ease;
}

.yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq._2wPrBQo1IuP_6xHtb48qQI span {
  color: var(--color-stepActiveFont);
}

.yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq._2wPrBQo1IuP_6xHtb48qQI:after {
  background-color: var(--color-stepActiveBG);
}

.yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq._2wPrBQo1IuP_6xHtb48qQI ._19sd8opT7JhasH0Vcq5p_p {
  color: var(--color-stepLabel);
}

@media (max-width: 767px) {
  .yE8GLB2u_dHR7OJjPR8T5 .JQ4tt8FIqNeAtWHCoJVCA ._1mc4F9IqCLNlaTVW8OBhsq:after {
    width: 40px;
    height: 40px;
  }
}
