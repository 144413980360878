._2zmNneJQAeo3-6CA7FGDCw {
  background: var(--color-background-asset);
  width: 100%;
  height: 100%;
}

._4GTmycXSsD_o_y-bha94n {
  color: var(--color-content-default1);
  text-align: center;
  font-size: var(--fontSize-xl);
}

.N5MCWlSCFUkxo6wsMo0_x {
  height: auto;
  color: var(--color-content-default1);
}

._3yqr9odr8vcRtMgeuwP1QK {
  color: red;
}

._3----N19tENJ-v7S3n82mR {
  z-index: 1000;
  position: absolute;
  left: 44px;
  top: 30px;
  right: 50px;
  color: var(--color-player-default1);
  display: flex;
  align-items: center;
  height: 60px;
}

._3x0SIEjPtsTvGUvXRFP4mL {
  width: 24px;
  height: 32px;
  transform: rotate(180deg);
  stroke: var(--color-player-default1);
  stroke-width: 2px;
  cursor: pointer;
  transition: stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

._3x0SIEjPtsTvGUvXRFP4mL:hover {
  stroke: var(--color-player-highlight-hover1);
}

._2etXeyv3tr3d0vZ7GYBHeX {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-left: 15px;
}

.FRhkCUgoP8qTjXh6xLQYC {
  display: inline-block;
  margin-left: 20px;
  font-size: var(--fontsize-l);
}

._2eubw111b-SZJRKICwNyGe {
  font-weight: bold;
  margin: 0;
}

._3NHj4rxAK_jKJrt5u6exMv {
  margin: 0;
}

._2eubw111b-SZJRKICwNyGe + ._3NHj4rxAK_jKJrt5u6exMv {
  margin-top: 10px;
}

@media (max-width: 1023px) {
  ._2zmNneJQAeo3-6CA7FGDCw {
    max-height: calc(100vh - 59px);
  }
}
