._2z8z0KjHbuyFY0spTOpnxs {
  position: relative;
}

._2sP6MXiSCVC9qjiLHAlCY1 {
  min-height: 155px;
  color: var(--color-slideshow-primary1);
  overflow: hidden !important;
}

._2ZIo-s8KgOWvqNyrq-xgmC {
  text-align: center;
  position: relative;
  top: -40px;
  height: 40px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

._1OCT4anGMwKsecRlJTmy1q {
  height: 18px;
  width: 18px;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
  outline: none;
}

._2tOir-GDUv1RVIEAugIzRD {
  background: rgba(255, 255, 255, 0.2);
  height: 7px;
  width: 7px;
  border-radius: 7px;
  margin: 3px 5px;
}

._3H8e1c5sKmnPgtD5n6I4D_ {
  background: var(--color-slideshow-primary1);
}
