._2kUCVS5tak9PztZm8gzlG9 {
  top: 0;
  position: fixed;
  text-align: center;
  z-index: 999999 !important;
  padding: 0;
  padding-bottom: unset !important;
}

@media (max-width: 767px) {
  ._2kUCVS5tak9PztZm8gzlG9 {
    top: 60px;
  }
}
