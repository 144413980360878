._1N2ISrwmrOlgIAs-omaS4K {
  padding: 10px;
  border-bottom: 1px solid var(--color-button-bordered-bg);
}

._1U14sNd7SjgseZfrbdep6t {
  margin: 0;
  padding: 0 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--fontSize-s);
  font-weight: 600;
}

.sRnFB4CeTEN6pseNwcyTA {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  width: 19px;
  float: right;
}

.ea0RG1tZ5lEeSQ5HtR2qD {
  text-decoration: none;
  color: var(--color-secondary4);
}

._3uiMwxryLl3gQPeb6ayVsY {
  color: var(--color-secondary4);
  background-color: var(--color-content-highlight-hover);
}

._3uiMwxryLl3gQPeb6ayVsY .sRnFB4CeTEN6pseNwcyTA {
  fill: var(--color-secondary4);
}

.yaUQrdpa81vHFNLCN5lF- {
  color: var(--color-content-default1);
  background-color: var(--color-content-error);
}

.yaUQrdpa81vHFNLCN5lF- .sRnFB4CeTEN6pseNwcyTA {
  fill: var(--color-content-default1);
}


@media (max-width: 767px) {

  ._1N2ISrwmrOlgIAs-omaS4K {
    padding: 5px;
  }

  ._1U14sNd7SjgseZfrbdep6t {
    padding: 0px 20px;
  }

  .sRnFB4CeTEN6pseNwcyTA {
    right: 10px;
    width: 20px;
  }

}
