._3wY-kS28QS0BRaElV2-gIR {
  position: absolute;
  top: 0;
  bottom: 70px;
  right: 0;
  /* overflow-y: scroll; */
}

._3liEvALPBZ1mQVHUkghpf- {
  overflow: scroll;
  height: 100%;
}

._1AmRAtsfmBBwu7EeARz50a {
  background: var(--color-content-overlay3);
}

._2J0EKMH6csBm6xcjeh6URF {
  text-decoration: none;
}
.WWLfnoe9eliSRyqsCVxz_ {
  width: 100%;
  height: 60px;
  background-color: var(--color-secondary3);
  position: relative;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}
.W8o0HmKoUthTUT2wFLYXX {
  /* width: 40px;
  height: 40px; */
  margin: 10px;
  /* border-radius: 5px; */
}
._2bgTwZVA0toS-E-M3NVsNQ {
  overflow-y: scroll;
  height: calc(100vh - 398px);
  min-width: 404px;
}
._36bstHiW4VE1ack-Hs2bZK {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background-color: var(--color-secondary3);
  width: calc(var(--EPGHeaderItem-width) - 20px);
  border-radius: 5px;
  padding: 10px;
  overflow-y: scroll;
  height: 356px;
}

.ZUAPjSeKB1vGNoOoeQ92k {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 40px;
  text-decoration: none;
  color: var(--color-content-default1);
  cursor: pointer;
  margin: 0;
}
._1hkKvai0l8SXf5xuZhAtlX {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 40px;
  text-decoration: none;
  color: var(--color-content-default1);
  background-color: var(--color-highlight-hover1);
  cursor: pointer;
  margin: 0;
  padding: 0 3px;
}
._3yY0oZgtqnie16sQDxlcBl {
  cursor: pointer;
  margin-right: 5px;
}
._1pufTdAr663_W1IHAfrAmw {
  display: inline-block;
  min-width: 10px;
  border-radius: 2px;
  background-color: #373737;
  padding: 1px 5px;
}
._2sOruNyw7e4flF2vy7_FAl {
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-highlight-hover1);
}
._2BWdpMm4IGqIQvaWDdvi2 {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  left: 20px;
}
._2uhobB9xMzyHwJcI34ydTv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* align-content: center; */
  margin-left: -20px;
  padding: 0 0 0 30px;
  height: 52px;
  /* width: 90%; */
  text-decoration: none;
  color: var(--color-content-default1);
  cursor: pointer;
  /* width: max-content; */
  font-size: 14px;
}
._2ru8tnPNLn7ZaJYvlV7mCv {
  height: 1px;
  margin: 8px 0 10px -10px;
  opacity: 0.4;
  background-color: var(--color-primary3);
}
.nXU57fquYhupQCasRdLgj {
  font-size: 14px;
  color: var(--color-primary3);
}
._1uaIRp6ozUPtidDovRJVmw {
  cursor: pointer;
  margin-left: 5px;
}
._1gg_oaRTLiauQum4HiRsd6 {
  cursor: pointer;
  width: 20px;
}

._1gg_oaRTLiauQum4HiRsd6 path {
  stroke: var(--color-content-default3);
}

._3LQh9KTwhlputTmkntjars {
  transform: rotate(-270deg);
}

._3jBBpAAQtWF184p6nU7Q65 {
  transform: rotate(270deg);
}
._2LWmnMUn8TKaAvVepXgOBj {
  transform: rotate(180deg);
}
._1e2232BESpOLtim7fA5Y67 {
  width: 300px;
  display: inline-flex;
  align-items: center;
  /* margin: 20px 0;
  position: absolute;
  right: 30px;
  top: -4px; */
}
._2QxQpon6sXsVZ5x2yml4Cp {
  background: var(--color-nav-background);
}
._2n5IA4thyvDvpdVSnQSgHK {
  align-items: center;
  display: inline-flex;
  position: absolute;
  padding-left: 20px;
}
._1lyz1T-LNfcV1wbYVoEmqq {
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: var(--color-search-primary1);
}
._2n5IA4thyvDvpdVSnQSgHK._1jyorxfwVniLaDzNpVFSpn {
  right: initial;
  left: initial;
}
._372-wGyXXQ8JUb82KBXnUU {
  width: var(--Search_inputWidth);
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: var(--color-search-input-bg);
  font-size: var(--fontSize-m);
  color: var(--color-search-primary1);
  box-sizing: border-box;
  outline: none;
  padding-left: 60px;
}
.pvhz-7hLlg3r4PQJ15RB- {
  text-align: center;
  padding: 20px;
  background-color: var(--color-secondary3);
}

._2lRCEoxeSmpWVZmamy2VOn {
  width: 80px;
  transform: rotate(90deg);
}

._2lRCEoxeSmpWVZmamy2VOn path {
  fill: var(--color-content-overlay3);
}

._2lRCEoxeSmpWVZmamy2VOn circle {
  fill: var(--color-content-default3);
}
@media (max-width: 767px) {
  ._2bgTwZVA0toS-E-M3NVsNQ {
    min-width: 325px;
  }
  ._1e2232BESpOLtim7fA5Y67 {
    width: 215px;
    right: 26px;
  }
}
@media (max-width: 411px) {
  ._2bgTwZVA0toS-E-M3NVsNQ {
    min-width: 262px;
  }
  ._1e2232BESpOLtim7fA5Y67 {
    width: 200px;
    right: 15px;
  }
}
@media (max-width: 361px) {
  ._2bgTwZVA0toS-E-M3NVsNQ {
    min-width: 262px;
  }
  ._1e2232BESpOLtim7fA5Y67 {
    width: 200px;
    right: 15px;
  }
}
