._2S2YWW4mSXiK_hhAY2KINq {
  position: absolute;
  top: 100px;
  bottom: 200px;
  right: 100px;
  width: 410px;
  min-height: 160px;
  z-index: 3000;
  overflow: hidden;
}

@media (max-width: 767px) {
  ._2S2YWW4mSXiK_hhAY2KINq {
    right: 0;
    top: 10px;
    right: 0;
    width: 300px;
  }
}
