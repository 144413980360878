:root {
  --PlayerBingeOverlay-animationDuration: 0.5s;
  --PlayerBingeOverlay-iconDuration: 0.3s;
}

._2OHM-qg6nghBb-Vc-MR0LI {
  /* z-index: 1002;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 450px; */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.03),
    var(--color-background-asset)
  );
}

._14nyvxERr5Mv-Js96ssgKD {
  position: absolute;
  right: 0;
  bottom: 15px;
  padding: 10px 0px 10px 0;
  z-index: 1004;
  height: 180px;
  width: 830px;
}

.IwKlMPuhqy8WW5QVxKL_T {
  position: absolute;
  display: inline-block;
  margin-right: 20px;
  width: 268px;
  height: 151px;
  /* position: relative; */
}

._2D0zwjKpq_SlcOSCkWrr9t {
  width: 268px;
  height: 151px;
}

._2RkgDUwlgxbkVcdeIVIdVF {
  width: 56px;
  height: 56px;
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 28px);
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
}

._2RkgDUwlgxbkVcdeIVIdVF circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

.IwKlMPuhqy8WW5QVxKL_T:hover ._2RkgDUwlgxbkVcdeIVIdVF {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.qd-Y5EWWy4ycg5Pm028XH {
  position: absolute;
  display: inline-block;
  left: 275px;
  top: 50%;
  transform: translateY(-50%);
}

.P7a-6y2R4iou4Z5Tjn_7C {
  font-size: var(--fontSize-s);
  color: var(--color-player-highlight-hover1);
  margin: 0;
}

._1dQuwFQkmJ4r3fb2j2v583 {
  font-size: var(--fontSize-m);
  color: var(--color-player-default1);
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 5px 0;
}

._35txfzjyl6TPpZr6jOIXsi {
  font-size: var(--fontSize-s);
  color: var(--color-player-default1);
  max-width: 480px;
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

._2d4JZGgqIDDomNa2XlV9eI {
  position: absolute;
  right: 40px;
  top: calc(50% - 12px);
  margin-left: 20px;
  width: 24px;
  height: 24px;
  fill: var(--color-player-default1);
  cursor: pointer;
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out;
}

._2d4JZGgqIDDomNa2XlV9eI:hover {
  fill: var(--color-player-highlight-hover1);
}

/* Overlay aninmation */
.moau5pULPXbQasocNkutg {
  opacity: 0;
}

.GXMvQSTonKZv_Gidth99h {
  opacity: 0;
}

.MpVsKND_SBdvTU9x88vVq {
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
  opacity: 1;
}

._2HYyBoC-J6gqbLiHCnDPnS {
  opacity: 1;
}

._31PNrdxMqhKzGJUcyFQQUC {
  opacity: 1;
}

._1sDxKCJw_df7bvqEqgwiXx {
  opacity: 0;
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
}

._3bl1ijdqEGXulgNAL0qLBK {
  opacity: 0;
}

.tYD0AcznKLCTIUmXEZCS0 {
  cursor: pointer;
}

@media (max-width: 1023px) {
  ._14nyvxERr5Mv-Js96ssgKD {
    width: 100%;
  }

  .qd-Y5EWWy4ycg5Pm028XH {
    padding-right: 60px;
    left: 280px;
  }
}

@media (max-width: 767px) {
  ._14nyvxERr5Mv-Js96ssgKD {
    height: 200px;
  }
  .IwKlMPuhqy8WW5QVxKL_T {
    width: 170px;
  }

  .IwKlMPuhqy8WW5QVxKL_T > div {
    width: 170px;
  }

  .qd-Y5EWWy4ycg5Pm028XH {
    transform: translateY(-60%);
    left: 175px;
  }

  ._2d4JZGgqIDDomNa2XlV9eI {
    transform: translateY(-70%);
  }

  ._35txfzjyl6TPpZr6jOIXsi {
    -webkit-line-clamp: 2;
  }
}
