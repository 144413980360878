:root {
  --contentWrapper-margin: 50px;
}

@media (max-width: 1023px) {
  :root {
    --contentWrapper-margin: 30px;
  }
}

._1ZECbBEVToLV6jWi7EjXJI {
  margin: 0 var(--contentWrapper-margin) 30px 50px;
}

._3kzFfUI4gsTp3H6YQuYhjF {
  margin: 0 var(--contentWrapper-margin) 0;
}

@media (max-width: 767px) {
  :root {
    --contentWrapper-margin: 20px;
  }

  ._1ZECbBEVToLV6jWi7EjXJI {
    margin: 0 20px 75px;
  }
}
