:root {
  --width: 100%;
  --contextHeight: 160px;
}

._173mcavcvEXiS6-idfTsyg {
  width: var(--width);
  position: relative;
  text-decoration: none;
}

._173mcavcvEXiS6-idfTsyg:after {
  content: "";
  clear: both;
}

.hHeqWcDg0nSf_G_nAW7x6 {
  text-decoration: none;
}

.yMsod7D0k5dzaXjIu8wGu {
  width: var(--width);
  position: relative;
}

._3Y3w4pD_sgzv9V7Aw5-23w {
  width: var(--width);
  height: var(--contextHeight);
  background: var(--color-secondary4);
  position: relative;
}

._3ync6lYUT-mTXIrIBtovLa,
._1bL_3K0E6tdJ6kg4DQNZTp {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
}

._1bL_3K0E6tdJ6kg4DQNZTp {
  visibility: hidden;
}

._1bL_3K0E6tdJ6kg4DQNZTp circle,
._3ync6lYUT-mTXIrIBtovLa circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

.yMsod7D0k5dzaXjIu8wGu:hover ._1bL_3K0E6tdJ6kg4DQNZTp {
  visibility: visible;
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.axx4Q-I2VEVE7obyggLdz {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}
._2i5NeHgsYOTduvYTr8S66O {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
/* .wraper{
  position: relative;
} */
._23AqeTkkPtwaGS-JH9cz2Q {
  fill: var(--color-thumbnail-highlight-hover1);
}
.axx4Q-I2VEVE7obyggLdz::before,
.axx4Q-I2VEVE7obyggLdz::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

.axx4Q-I2VEVE7obyggLdz::before {
  border-width: 20px;
}

.axx4Q-I2VEVE7obyggLdz::after {
  border-width: 20px;
  border-right-color: var(--color-border-right-padlock-container);
  border-top-color: var(--color-border-top-padlock-container);
}

._2in6kCKGeE2JDan4NE26Df {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}

._1o8WCuFt0su2FKOrq3Rijn > svg {
  width: 16px;
  fill: var(--color-icon-playlock-fill);
}

.yMsod7D0k5dzaXjIu8wGu:hover ._1pRvcGfs6sbz-wpR4ME-H1 {
  visibility: hidden;
}

.yMsod7D0k5dzaXjIu8wGu:hover ._3aFCldEL13A09IG8c9z_Xo {
  display: initial;
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.yMsod7D0k5dzaXjIu8wGu ._1pRvcGfs6sbz-wpR4ME-H1 {
  visibility: visible;
}

.yMsod7D0k5dzaXjIu8wGu ._3aFCldEL13A09IG8c9z_Xo {
  display: none;
}
._3Uqlqy13008IAuBWBGWLvE {
  width: 25px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  object-fit: contain;
  z-index: 5;
}
._3CEzGq4a3BnbOFCjB6yH28 {
  position: absolute;
  margin-left: 24px;
  margin-top: 14px;
  width: 12px;
  height: 11px;
  display: flex;
  align-items: center;
  object-fit: contain;
  fill: var(--color-primary1);
  z-index: 5;
}

._1Q7tmu6Q2cJCphsL2P2tSk > svg {
  transform: rotate(90deg);
  fill: var(--color-primary1);
}
._3Uqlqy13008IAuBWBGWLvE:hover ._1Q7tmu6Q2cJCphsL2P2tSk {
  transform: rotate(90deg);
  fill: var(--color-highlight-hover1);
}

._3Uqlqy13008IAuBWBGWLvE:hover ._1Q7tmu6Q2cJCphsL2P2tSk circle {
  fill: var(--color-highlight-hover1);
}
._1Q7tmu6Q2cJCphsL2P2tSk circle {
  fill: var(--color-primary1);
}
._1vnqgpptB2wdIHgwVdLzg9 {
  margin-bottom: 0;
  margin-left: 0;
}

._3Uqlqy13008IAuBWBGWLvE > fieldset,
._3Uqlqy13008IAuBWBGWLvE > fieldset > details > summary > div:nth-child(1) {
  padding-left: 0;
}

._3Uqlqy13008IAuBWBGWLvE > fieldset > details > div {
  margin-top: 0;
}
