:root {
  --PageCatchUpDetail-grid-rowGap: 30px;
  --PageCatchUpDetail-linkBackPosition: 0px;
  --PageCatchupDetail-headerTitle: 80px;
}

/* .linkBack {
  position: relative;
  top: var(--PageCatchUpDetail-linkBackPosition);
  left: -9px;
} */
.wTC_XNYAiwgL2Bhm_WYmV {
  position: absolute;
  z-index: 20;
  left: 40px;
  top: var(--PageCatchUpDetail-linkBackPosition);
}
.I1LEe7s1fs6LnUi1F_mWA {
  position: absolute;
  top: var(--PageCatchUpDetail-linkBackPosition);
  right: var(--PageAssetTVShow-linkBackPosition);
  z-index: 20;
}

._3ikgH9gsAYP10m-y6inl-c {
  padding-top: var(--PageCatchupDetail-headerTitle);
}

.Kh7O9Elcl3ZolNMgHfxXU {
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-row-gap: var(--PageCatchUpDetail-grid-rowGap);
}
