.BY40JW1BaCtrpqwDvNP9c {
  cursor: pointer;
  text-decoration: none;
  /* margin: 0 var(--marginBetweenThumbnails) 0 0; */
  position: relative;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
  overflow: hidden;
}
._3BDJEu-4ld9zy7p12JS_Kr {
  margin: 0;
}
.KkDxhqITIdEJMmt8JeN73 {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
  width: 40px;
  height: 40px;
  text-align: left;
}
._4DLKc9vxMKidTEBxa4RZp {
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 40px;
  max-height: 40px;
}
._3hjJgosFcm4O3fEVOmagkf {
  transform: scale3d(1.1, 1.1, 1.1);
  z-index: 10;
}

._3POY6OMKtX0SovyZURTvZH {
  position: relative;
  min-width: 200px;
}

._3POY6OMKtX0SovyZURTvZH {
  object-fit: contain;
}

._38nVvoeQtha2Eh23wrXh5d {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
  width: 40px;
  height: 40px;
}

._1YL-8Sgl8eRhVryy2tUG63._3hjJgosFcm4O3fEVOmagkf {
  transform: scale3d(1.05, 1.05, 1.05);
  z-index: 10;
}

.USxwgRgucUMpLgEuK_jAu,
._1OjS2RFj8i2WdztoMzHGVp {
  position: absolute;
  bottom: 0;
  z-index: 50;
}

._8RwBhLVnE1zbW9VpB8StN {
  background: var(--color-content-overlay3);
}

._20JkLRYe8exwpRjp2XWKEv {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
._2u0WztNyyYlj_sRBHmtYAP {
  fill: var(--color-thumbnail-highlight-hover1);
}
