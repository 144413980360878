._16qG791lqN71ssMnN51wpl {
  display: inline-block;
  font-weight: normal;
  font-size: var(--fontSize-s);
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid var(--color-content-highlight-hover);
  border-radius: 30px;
  color: var(--color-content-highlight-hover);
  background-color: transparent;
  margin: 0 10px 10px 0;
  padding: 0 10px;
  white-space: nowrap;
}

._16qG791lqN71ssMnN51wpl:hover {
  color: var(--color-button-ghost-font-hover);
  background-color: var(--color-button-ghost-bg-hover);
}
