._2DVe_5K88qwTlYtXNH0hic {
  width: 100%;
  margin-top: 5px;
  position: relative;
}

._2cVvH6sKuTePv_KZZ5wImb {
  position: absolute;
  z-index: 4;
  bottom: 0;
  width: 100%;
}

._2zU-zwm4t6l_X0l5-v2Xe0, ._1sLplmnLhJ1rTeSw5EYBZg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
  visibility: hidden;
  cursor: pointer;
}

._1sLplmnLhJ1rTeSw5EYBZg circle, ._2zU-zwm4t6l_X0l5-v2Xe0 circle, ._2nFxpr1u80_Wzd6_6Mzr8h circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._2nFxpr1u80_Wzd6_6Mzr8h {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.jVnsBiCrcS9WzN24XVbs ._1sLplmnLhJ1rTeSw5EYBZg,
._10prb5nq3M0SU48V_Vb4jB:hover ._1sLplmnLhJ1rTeSw5EYBZg {
  visibility: visible;
}
._10prb5nq3M0SU48V_Vb4jB{
  height: 100% ;
}

.jVnsBiCrcS9WzN24XVbs ._1sLplmnLhJ1rTeSw5EYBZg:hover,
._10prb5nq3M0SU48V_Vb4jB ._1sLplmnLhJ1rTeSw5EYBZg:hover {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.jVnsBiCrcS9WzN24XVbs ._3v-PS3bXpRIhCHh0f1_yg9,
._10prb5nq3M0SU48V_Vb4jB:hover ._3v-PS3bXpRIhCHh0f1_yg9 {
  visibility: visible;
}

.jVnsBiCrcS9WzN24XVbs ._5Gm3p3XWm4g-TEnJGlyC7:hover ._3v-PS3bXpRIhCHh0f1_yg9,
._10prb5nq3M0SU48V_Vb4jB:hover ._5Gm3p3XWm4g-TEnJGlyC7:hover ._3v-PS3bXpRIhCHh0f1_yg9 {
  visibility: hidden;
}

.jVnsBiCrcS9WzN24XVbs ._5Gm3p3XWm4g-TEnJGlyC7:hover ._2nFxpr1u80_Wzd6_6Mzr8h,
._10prb5nq3M0SU48V_Vb4jB:hover ._5Gm3p3XWm4g-TEnJGlyC7:hover ._2nFxpr1u80_Wzd6_6Mzr8h {
  visibility: visible;
}

.NF6v8AAWYCgfPN-4I5y-U {
  transform: scale(1.2);
  transition: all .5s;
}
