._1Mm0AtWnIMZY7-qnOu6UlK {
  width: 100%;
  height: 100%;
}

._3cIooJ4tu3z2n70LBmNmxc {
  margin-top: 35px;
}

._1-Fnuaf_mBEEUx8lM-DsYR {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  margin-bottom: 40px;
}

._33CHKhkwkpTeVnX8PRclD {
  font-weight: normal;
  text-transform: inherit;
  width: 270px;
  display: block;
  margin: auto;
}

._2_1k7vDSbGsFbpAgsgCafM {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  text-align: center;
}

._106qdXThgfA2BqiAwUaInj {
  font-size: var(--fontSize-s);
  color: var(--color-content-highlight-hover);
  font-weight: bold;
  cursor: pointer;
}

._36KKKSFqopP6BRsWIOD35x {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
  padding-left: 10px;
}

.oehILnyOugvujpew2pmtA {
  padding-left: 20px;
  margin-block-start: 0;
  margin-block-end: 0;
}

._2Ov7q2gV9QiL5VadRe2-88{
  color:var(--color-input-password-valid-policy);
}
