:root {
  --Search_inputWidth: 456px;
  --PageLiveDetail-grid-width: 340px;
  --search-grid-width: 100px;
}

@media (max-width: 1023px) {
  :root {
    --PageLiveDetail-grid-width: 290px;
    --search-grid-width: 100px;
  }
}

@media (max-width: 767px) {
  :root {
    --Search_inputWidth: 100%;
  }
}

._3WhhJOhDRObp6r0kcjo1GE {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageLiveDetail-grid-width), 1fr)
  );
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.OcaRQpfLimkl1XKAE8FxF {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background-color: var(--color-secondary3);
  width: calc(var(--EPGHeaderItem-width) - 20px);
  border-radius: 5px;
  padding: 10px;
}

._1AETtkUVyNhmsXhasxqY6m {
  display: inline-flex;
  align-items: center;
  align-content: center;
  height: 40px;
  width: 100%;
  text-decoration: none;
  color: var(--color-content-default1);
}

._13jS4fP2hIObhnTIg2eiWC,
.qppiYCt1R5LRGhZiyzLyp,
.ealBMf-nVtE8vBDSnrUKF {
  height: 24px;
  width: 24px;
  margin-left: 10px;
  margin-right: 20px;
}

.ealBMf-nVtE8vBDSnrUKF,
._13jS4fP2hIObhnTIg2eiWC {
  fill: var(--color-content-default1);
}

.qppiYCt1R5LRGhZiyzLyp {
  fill: var(--color-content-highlight-hover);
}

.VAXo_SpcnBWbQeLXPnVCu {
  cursor: pointer;
}

.xEm5YgkrGKCN2DvyTI-Cm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 35px 10px 20px 0;
}

._3IHzN-sFyJIdBzsQxPuLIT {
  background-color: var(--color-select-background);
  padding: 7px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  stroke: var(--color-select-primary1);
}

._3IHzN-sFyJIdBzsQxPuLIT:hover {
  background-color: var(--color-select-background-hover);
}

._3IHzN-sFyJIdBzsQxPuLIT:hover {
  stroke: var(--color-select-background);
}

._1gaGONr7WmgYEbpfw2yraR {
  min-width: 160px;
  height: 40px;
  padding: 6px 10px 8px 20px;
  border-radius: 5px;
  background-color: var(--color-select-background);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
  stroke: var(--color-select-primary1);
  margin-left: 10px;
  margin-right: 10px;
}

._1gaGONr7WmgYEbpfw2yraR:hover {
  background-color: var(--color-select-background-hover);
}

.k6YrCBP5NvHjHsalyIax3 {
  font-size: var(--fontSize-s);
  font-weight: bold;
  cursor: pointer;
  margin-right: 5px;
}

._1gaGONr7WmgYEbpfw2yraR:hover .k6YrCBP5NvHjHsalyIax3,
._1gaGONr7WmgYEbpfw2yraR:hover path {
  color: var(--color-button-normal-font-hover);
  stroke: var(--color-button-normal-font-hover);
}
._2seNr5MHbQ3XeQj7Qmxm5w path {
  fill:var(--color-content-default3);
}

.tCaltcK_CJ4aU9ZpEA4z7 {
  cursor: pointer;
  width: 20px;
}

.tCaltcK_CJ4aU9ZpEA4z7 path {
  stroke: var(--color-content-default3);
}

._3szfLjlvL5d480pegUGa5u {
  transform: rotate(-270deg);
}

._3le-qGx6938fCRJcu_0YWE {
  transform: rotate(270deg);
}

._1QANiZrsODjNKiPNlFuTng {
  transform: rotate(180deg);
}

.OcaRQpfLimkl1XKAE8FxF {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background-color: var(--color-secondary3);
  width: calc(var(--EPGHeaderItem-width) - 20px);
  border-radius: 5px;
  padding: 10px;
  overflow-y: scroll;
  height: 356px;
}

._2am-nCT9glLli-_oprsHDX {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-left: -20px;
  padding: 0 10px 0 30px;
  height: 40px;
  width: 90%;
  text-decoration: none;
  color: var(--color-content-default1);
  cursor: pointer;
}

._2FMrrTsEka-29y4_rYaLqM {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-left: -20px;
  padding: 0 10px 0 30px;
  height: 40px;
  width: 90%;
  text-decoration: none;
  color: var(--color-content-default1);
  background-color: var(--color-highlight-hover1);
  cursor: pointer;
}

.VAXo_SpcnBWbQeLXPnVCu {
  cursor: pointer;
  margin-right: 5px;
}

._1s0w4nydLmWwKKfxqMwdb5 {
  display: inline-block;
  min-width: 10px;
  border-radius: 2px;
  background-color: #373737;
  padding: 1px 5px;
}

._2m1X5oElVGK93T8-hWyZW2 {
  padding: 7px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  stroke: var(--color-select-primary1);
  background-color: var(--color-highlight-hover1);
}

.uxJK1Qjkc2cEQcwdnKG7- {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  left: 20px;
}

._1rOyAv9KoCyY6vCos0ZxD8 {
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: -20px;
  padding: 0 0 0 30px;
  height: 52px;
  width: 90%;
  text-decoration: none;
  color: var(--color-content-default1);
  cursor: pointer;
  width: max-content;
  font-size: 14px;
}

.d7zeZPVbeKdPIr6bhwamY {
  height: 1px;
  margin: 8px 0 10px -10px;
  opacity: 0.4;
  background-color: var(--color-primary3);
}

._3FDKGAWNvDHM--jeTNEq6H {
  font-size: 14px;
  color: var(--color-primary3);
}

._3IcHmI6TqXQ5cYdjXUqgyp {
  cursor: pointer;
  margin-left: 5px;
}

.utUU1lRfaiqCUSMGsytbm {
  min-width: 120px;
  min-height: 120px;
}

._2yJB87UZGXz7tUFn7tC0xh {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--search-grid-width), 1fr)
  );
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

._32YmfxBbVamR6_BbWBuQ3z {
  width: var(--Search_inputWidth);
  display: inline-flex;
  align-items: center;
  margin: 20px 0;
}

._9pWDMYdvI8dXQ5yFuu3P_ {
  background: var(--color-nav-background);
}

._1jKD-YmgsSgb067zx_dRug {
  align-items: center;
  display: inline-flex;
  position: absolute;
  padding-left: 20px;
}

._1jKD-YmgsSgb067zx_dRug._1SLaiMgnLYvqGFP-kCw1Yw {
  padding-left: 0;
  padding-right: 20px;
}

._1jKD-YmgsSgb067zx_dRug._1wZh5juBAtdK0oDbx3FoBV {
  right: initial;
  left: initial;
}

._2KYLcRMYAGAnqKU1L0qhdS {
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: var(--color-search-primary1);
}
._1jrK2M9RhVJ-kpCzOHLlee {
  width: var(--Search_inputWidth);
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: var(--color-search-input-bg);
  font-size: var(--fontSize-m);
  color: var(--color-search-primary1);
  box-sizing: border-box;
  outline: none;
  padding-left: 60px;
}

._1jrK2M9RhVJ-kpCzOHLlee._1SLaiMgnLYvqGFP-kCw1Yw {
  padding-right: 60px;
  padding-left: unset;
}

._1jrK2M9RhVJ-kpCzOHLlee.sH8hx0-OGZUWrbx1pblXv._1SLaiMgnLYvqGFP-kCw1Yw {
  padding-right: 60px;
  width: 100%;
  padding-left: unset;
}

._1jrK2M9RhVJ-kpCzOHLlee.sH8hx0-OGZUWrbx1pblXv {
  padding-left: 60px;
  width: 100%;
}

._22tdKncTOYY9AOTMU4wObr {
  text-align: center;
  padding: 20px;
}

._2bkH_2VvDkDA2174UmgP0a,
._2bkH_2VvDkDA2174UmgP0a svg {
  width: 80px;
  transform: rotate(90deg);
}

._2bkH_2VvDkDA2174UmgP0a path {
  fill: var(--color-content-overlay3);
}

._2bkH_2VvDkDA2174UmgP0a circle {
  fill: var(--color-content-default3);
}
