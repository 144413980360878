._3uk0jRLph4s-c2BFJkgRnS {
  position: relative;
  top: 250px;
}

._1rMr6A2ZfAcTSHQDQiEfjw {
  padding-left: 10px;
  top: 80px;
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
}

.Oawu38BBYQHOrhq61XTho {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  margin-bottom: 50px;
}

._148us_d213ns4vX0RFMvyJ {
  margin-bottom: 50px;
  margin-top: 10px;
}

._1SrxdEcf2unBfPzvkDu4Ak {
  font-size: var(--fontSize-s);
  width: auto;
  display: block;
  margin: auto;
  max-width: 330px;
}

._1uM5w1mMVLE5idj7sWPkq- {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  text-align: center;
}

._2pfYoWFPRjaaq3UQ48MObz {
  font-size: var(--fontSize-s);
  color: var(--color-content-highlight-hover);
  font-weight: bold;
  cursor: pointer;
}

._3R8ayFqveq9EyqlqX-EVIB {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
}

._2JFmbkXIx1Y1dd6uJq8nh5 {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  margin-bottom: 10px;
}

.svAtlADheGPgBfq9FAQt {
  text-align: center;
}

.SjjbTkc8fRhwfQW5H28Cq {
  color: var(--color-content-highlight-hover);
  font-weight: bold;
}
