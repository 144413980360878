:root {
  --menuWidth: 300px;
}

._2YWntZzdKlYFUXsPBycm_c {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100000;
}

._3-aRtsjQH9jmChCqThMQe9 {
  height: 100%;
}

._3_meShQv8viLzuxTSBQwyu {
  height: 35rem;
  overflow: auto;
}

._3_meShQv8viLzuxTSBQwyu._3Z7TPJ63izWAiHfRHF4ADf {
  height: auto;
  max-height: 40rem;
}

._3JRbI13IGHCW-OwNxMv-4R {
  overflow: auto;
}

._30S7_e8vLu7hCdLSp4yyy {
  min-height: 48rem;
}

._112R_IVtijNchgXa-CFFM- {
  transition: margin-left 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  padding-bottom: 7px;
}

._1aIUZiq30yBtrXjIL8H2a_ {
  display: none;
}

._1DU2Aas-hVF6wMilpFkO32 {
  display: block;
}

._1GEIC8UPVmlClBWqO2eH1D {
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  z-index: 9999;
}

._2CRCi6opch_uhh8zEWPTUx {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  visibility: visible;
  opacity: 1;
  background: transparent;
  /* transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, background 0.5s ease-in-out; */
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
}

._2uQbJH-HlFu37ODF1LRV_W {
  right: 0;
}

.jAtrF6Crxl_mId0Vk0YNI {
  left: 0;
  right: unset;
}

._39iH49jEuE3Yz0bSPOISbt {
  width: var(--menuWidth);
}

._15fE2gZKsdeO05invIrS5e {
  margin-left: var(--menuWidth);
}

._3I_Irt6PgCHc1ueTl2_S1I {
  margin-right: var(--menuWidth);
}

._2Nu12DN0eAVbcEvZjT_voG {
  width: 100px;
}

._2PNc5aLb1K92Rp4-zd59X7 {
  margin-left: 100px;
}

._1qxmSfoN5olibazFtGsl9m {
  margin-right: 100px;
}

._3fju6uZTka9j1_CZ-3shXE {
  top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

._1js4I_jdw142sROYOIWM4I {
  transition: 0.3s ease-in-out;
}

.n1vxPmIHFSZVhkyrIP13N {
  width: calc(100% - var(--menuWidth));
}

.RQC7ZozwRKJW6fyowi1lW {
  width: calc(100% - 100px);
}

._2Sl1jUGnx6dizjt1GQ_G9d {
  width: 100%;
}

._2LKd3HYxLlreucdxWSvoVZ {
  background-color: var(--color-secondary1);
}

@media (max-width: 1023px) {
  :root {
    --menuWidth: 256px;
  }

  ._112R_IVtijNchgXa-CFFM- {
    width: 100%;
    overflow: hidden;
  }

  ._1dWVBY0Ap4_JmBteW22XJ2,
  ._2PNc5aLb1K92Rp4-zd59X7 {
    margin-left: 0;
    top: 58px;
    padding-bottom: 65px;
  }
  ._1dWVBY0Ap4_JmBteW22XJ2,
  ._1qxmSfoN5olibazFtGsl9m {
    margin-right: 0;
    top: 58px;
    padding-bottom: 65px;
  }

  ._15fE2gZKsdeO05invIrS5e,
  ._3I_Irt6PgCHc1ueTl2_S1I {
    top: 85px;
    padding-bottom: 92px;
  }

  ._2Nu12DN0eAVbcEvZjT_voG {
    left: calc(-1 * var(--menuWidth));
  }

  ._2dirHzfgZ4EMhm67Arj8zp {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    opacity: 1;
    z-index: 200;
  }

  ._2CRCi6opch_uhh8zEWPTUx {
    position: fixed;
    right: 0;
    top: 0;
    padding-left: 0;
    padding-right: 0;
    z-index: 9999;
    height: 60px;
    width: 60px;
  }

  .jAtrF6Crxl_mId0Vk0YNI {
    right: unset;
    left: 0;
    width: auto;
  }

  .n1vxPmIHFSZVhkyrIP13N,
  .RQC7ZozwRKJW6fyowi1lW {
    width: 100%;
  }
}

@media (max-width: 767px) {
  ._1dWVBY0Ap4_JmBteW22XJ2,
  ._2PNc5aLb1K92Rp4-zd59X7,
  ._1qxmSfoN5olibazFtGsl9m {
    z-index: 1;
  }
}
