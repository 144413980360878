:root {
  --Profile-linkBackPosition: 40px;
}

._9waZSnyZMpsVXcyGyWW9b {
  height: 100%;
  width: 100%;
}

._3M08UAH4QvdBy6wH2QB5BY {
  max-width: 1024px;
  margin: 0 auto 50px;
  position: relative;
  padding: 0;
  z-index: 20;
}

.VYVb-myOu20ST3AufAlEz {
  margin-top: var(--Profile-linkBackPosition);
  width: fit-content;
}

._3ut5xHYsSbJiYbZxQQUNB1 {
  min-width: 330px;
  min-height: 25%;
}

._2Ws8eqWjW1SHjsTJ1ZmtVS {
  float: left;
}

._1lUZfA88TPqHMd0GD0Puqe {
  float: right;
}

@media (max-width: 767px) {
  :root {
    --Profile-linkBackPosition: 20px;
  }

  .VYVb-myOu20ST3AufAlEz {
    margin-left: -4px;
  }
}