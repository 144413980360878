:root {
  --Checkbox-width: 15px;
  --Checkbox-height: 15px;
  --Checkbox-shadow-color: rgba(0, 0, 0, 0.5);
}

._1ufw-te92fswi3tmPD11Xd {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

._3J2T-JJ8chy_JZdQX6i25l {
  flex-shrink: 0;
  width: fit-content;
  padding: 0.5rem 1rem;
  color: var(--color-primary1);
  cursor: pointer;
}

._2naOBzc1HhpC4ns8LbeQoX {
  position: relative;
  flex-shrink: 0;
  width: var(--Checkbox-width);
  height: var(--Checkbox-height);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  border: 2px solid var(--color-content-default3);
}

._2naOBzc1HhpC4ns8LbeQoX::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  transition: all 0.1s;
  background: var(--color-content-highlight-hover);
}

._2naOBzc1HhpC4ns8LbeQoX:checked::before {
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
}

._2naOBzc1HhpC4ns8LbeQoX:disabled {
  border-color: var(--color-secondary3);
  cursor: default;
}

._2naOBzc1HhpC4ns8LbeQoX:disabled::before {
  background-color: var(--color-secondary3);
}

._2naOBzc1HhpC4ns8LbeQoX:disabled+._3J2T-JJ8chy_JZdQX6i25l {
  color: var(--color-secondary3);
  cursor: default;
}

.fxQj0b6eVTJcHOlFwmkIP {
  border-color: var(--color-error);
  background-color: var(--color-secondary3);
}

.fxQj0b6eVTJcHOlFwmkIP::before {
  background-color: var(--color-error);
}

._1SvxstR1FyhxFGhNaO4GTX {
  padding-left: 10px;
  padding-right: 0;
}

._1SvxstR1FyhxFGhNaO4GTX ._3J2T-JJ8chy_JZdQX6i25l {
  padding: 0;
}

._3hNb-dShxN-j6nvB4-6-um::after {
  content: "OFF";
  font-weight: bold;
  font-size: var(--fontSize-xs);
  margin-left: 1.8rem;
  vertical-align: -webkit-baseline-middle;
  position: absolute;
  bottom: 7px;
  left: 6px;
}

._22qy61NV5HPMhLiSw_sTYy::after {
  position: absolute;
  bottom: 7px;
  left: 6px;
}

._3hNb-dShxN-j6nvB4-6-um {
  margin: 0;
  width: 66px;
  height: 26px;
  border: none;
  border-radius: 13px;
  background: var(--color-button-switch-bg);
  color: var(--color-button-switch-label);
  box-shadow: 0 2px 4px 0 var(--Checkbox-shadow-color);
}

._3hNb-dShxN-j6nvB4-6-um::before {
  top: 2px;
  right: 42px;
  bottom: 2px;
  left: 2px;
  border-radius: 50%;
  background: var(--color-button-switch-circle);
  color: var(--color-button-switch-label);
  box-shadow: 0 2px 4px 0 var(--Checkbox-shadow-color);
}

._3hNb-dShxN-j6nvB4-6-um:checked {
  background: var(--color-button-switch-bg-active);
  color: var(--color-button-switch-label-active);
}

._3hNb-dShxN-j6nvB4-6-um:checked::after {
  content: "ON";
  margin-left: 1rem;
}

._22qy61NV5HPMhLiSw_sTYy:checked::after {
  position: absolute;
  bottom: 7px;
  left: 0;
}

._3hNb-dShxN-j6nvB4-6-um:checked::before {
  background: var(--color-button-switch-circle-active);
  right: 2px;
  left: 42px;
}

._3hNb-dShxN-j6nvB4-6-um:disabled {
  background-color: var(--color-button-switch-bg-disable);
  /* color: var(--color-button-switch-label-disable) */
}

._3hNb-dShxN-j6nvB4-6-um:disabled::before {
  background-color: var(--color-button-switch-circle-disable);
  color: var(--color-button-switch-label-disable);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  cursor: pointer;
  color: red;
}