._38TmGSh_1ybFE6D4YSbKq_ {
    max-width: 1024px;
    height: 100%;
    margin: 0 auto;
  }

  ._1M-JeEoTRi-98mkSP8zwV8 {
    font-weight: 600;
    margin-bottom: 70px;
  }

  .aNxHtbNU9X6qlilNk9gKx {
    margin: 0 20px;
  }

  ._16z5-dkgwq3hGx7uCitH0- {
    font-size: var(--fontSize-m);
    font-weight: normal;
    color: var(--color-content-default1);
    margin-bottom: 50px;
  }

  ._16z5-dkgwq3hGx7uCitH0-:nth-child(2n) {
    margin-bottom: 20px;
    color: var(--color-content-highlight-hover);
    font-weight: bold;
  }
  .Collapsible__trigger ._3WzoQqpcKG2892_CjVngxN {
    width: 20px;
    height: 20px;
    transform: rotate(-270deg);
  }

  .Collapsible__trigger ._3WzoQqpcKG2892_CjVngxN path {
    stroke: var(--color-content-default1);
  }

  .Collapsible__trigger.is-open ._3WzoQqpcKG2892_CjVngxN {
    transform: rotate(270deg);
  }
  ._1JCb6_nS17q2Bx518lkIYN{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid var(--color-primary3);
    margin-bottom: 20px;
    width: 100%;
  }
  ._5w0VnnJgwo0v7lqb9Hdl{
    width: 100%;
  }
  .qR9yH23CrqFGiec9N1-gB {
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-primary1);
  }
  ._2ULVb431kAws0lSoxwfUar {
    justify-content: space-between;
    padding: 0;
  }
  .EsHxVt3RGRuXwH4AJRCdL{
    font-size: 14px;
    color: var(--color-primary2);
  }
  ._1SxwahdGbj928l21pNQKUu{
    min-height: 80px;
    border-bottom: 1px solid var(--color-primary3);
    width: 100%;
    display: grid;
    color: var(--color-primary1);

  }
  .c1b7WwfUve4Y5x3E20KR4{
    width: 100%;
    display: flex;
    justify-content: space-between;

  }
  ._1kwFIuiPHZyyjFXkpSnDkA{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .YC2iCrMOLyMfY8Gc0t6T6{
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:var(--color-content-highlight-hover)
  }
  ._1dF-21Kn7skRnoWXsuGs0G {
    cursor: pointer;
  }
  ._1rb-Xq-JjZj5GOyimdlqMA {
    max-width: 490px;
    color: var(--color-content-default1);
  }
  @media(max-width: 767px) {
    ._1M-JeEoTRi-98mkSP8zwV8 {
      margin-bottom: 40px;
    }

    ._16z5-dkgwq3hGx7uCitH0- {
      font-size: var(--fontSize-s);
      margin-bottom: 30px;
    }

    ._16z5-dkgwq3hGx7uCitH0-:nth-child(2n) {
      margin-bottom: 10px;
    }
  }

._3-upJRUvam0jWWC94OBIlV {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary2);
}

.pPgY5YP39FkNVWRSJcZd- {
  width: 640px;
  margin: 40px 630px 123px 7px;
  border-radius: 3px;
}

@media (max-width: 767px) {
  .pPgY5YP39FkNVWRSJcZd- {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  ._1rb-Xq-JjZj5GOyimdlqMA {
    width: 90%;
    min-width: auto !important;
  }
}
