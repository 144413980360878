.s_mgoaU8UPHdRHa98Tpt2 {
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
  margin: 5px 0 35px 50px;
}
._25gbAr5gI-YkSNpEEzuWHz {
  text-align: center;
  padding: 20px;
}

._2mdUEyXM6VqbJe809lxG5w {
  width: 80px;
  transform: rotate(90deg);
}

._2mdUEyXM6VqbJe809lxG5w path {
  fill: var(--color-content-overlay3)
}

._2mdUEyXM6VqbJe809lxG5w circle {
  fill: var(--color-content-highlight-hover);
}
._2RzFoHy3ibYPVFN3_1rOzj {
  width: 80px;
}

._2RzFoHy3ibYPVFN3_1rOzj path {
  fill: var(--color-content-overlay3)
}

._2RzFoHy3ibYPVFN3_1rOzj circle {
  fill: var(--color-content-highlight-hover);
}
._8Ss8dxZIGC5YZag9f6n4X {
  width: 330px;
  min-height: 453px;
  color: var(--color-content-default1);
}
._3hY0DmPXB7K2CWy0Sei2yc {
  display: block;
  width: fit-content;
  margin: 10px auto;
  text-transform: none;
  font-weight: normal;
}
._2o2BfOu17oK1aBmLt8qJQv {
  margin-top: 0;
  margin-bottom: 20px;
}

._4WdiZkydfKUdlML_KQLZH {
  margin: 50px 0 0 0;
  text-align: center;
  font-size: larger;
}
._3lXG1fh3oSybuCUSju0_Ry {
  display: block;
  margin: 20px 0 30px 0;
  text-align: center;
}
@media (max-width: 1023px) {
  .s_mgoaU8UPHdRHa98Tpt2 {
    margin: 0 0 30px 30px;
  }
}

@media (max-width: 767px) {
  .s_mgoaU8UPHdRHa98Tpt2 {
    font-size: var(--fontSize-m);
    margin: 0 0 30px 20px;
  }
}

._17ckHVeOqIdeXpIH80K-wB {
  text-decoration: none;
}

._17ckHVeOqIdeXpIH80K-wB:hover .s_mgoaU8UPHdRHa98Tpt2 {
  color: var(--color-highlight-hover1);
}

._17ckHVeOqIdeXpIH80K-wB:hover ._2-wpzDyZIvAbnCiCYxhhXQ {
  fill: var(--color-highlight-hover1);
}

.s_mgoaU8UPHdRHa98Tpt2 {
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
  margin-bottom: 30px;
  position: relative;
}

._1j6wjjOPoF4I-9MBP9E5Y1{
  margin-right: var(--contentWrapper-margin);
  margin-left: 0;
}

.s_mgoaU8UPHdRHa98Tpt2::first-letter {
  text-transform: uppercase;
}

.s_mgoaU8UPHdRHa98Tpt2:hover ._284bNzufIOcfbA4QiqMeFN {
  transform: translateX(10px);
}

._284bNzufIOcfbA4QiqMeFN {
  position: absolute;
  top: 0;
  font-size: var(--fontSize-s);
  font-weight: normal;
  line-height: 26px;
  transition: all 250ms cubic-bezier(.21,.43,.37,.99);
}

._1Gt8BdMimxGKz_kYxCGhMm{
  right: 50px;
}

._3vtB91nat2uZ75An98t46z{
  left: 50px;
}

._2-wpzDyZIvAbnCiCYxhhXQ {
  float: right;
  margin-left: 10px;
  fill: var(--color-content-default1);
}

._2mvX9XLOk8uJj5RjSoxg1f {
  float: left;
  margin-right: 10px;
  transform: rotate(180deg);
}