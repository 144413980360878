._2NXORXG1pJ4dItNXGEDJGE {
    font-size: var(--fontSize-s);
    width: 100%;
    margin: 0;
    margin-top: 15px;
    cursor: pointer;
    height: auto;
    padding-left:15px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-button-login-bordered-font);
    background-color: var(--color-button-login-bordered-bg);
    border: var(--borderSize-button-login-bordered) solid var(--color-button-login-bordered-border);
    border-radius: var(--borderRadius-button-login-bordered);
}




._1cvOgIxHJEkQyFXtk2AFu {
    width: 100%;
    text-align: center;
    font-size: var(--fontSize-s);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    margin-left: -1rem;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-primary-1);
}

@media screen and (max-width: 768px) {
    ._1cvOgIxHJEkQyFXtk2AFu {
        margin-left: 0;
        text-align: center;
    }
}


._2NXORXG1pJ4dItNXGEDJGE:hover {
    color: var(--color-button-login-bordered-font-hover);
    background-color: var(--color-button-login-bordered-bg-hover);
    border: var(--borderSize-button-login-bordered) solid var(--color-button-login-bordered-border-hover);
}

._2Y8AuPL8UEprIRHqH553Aa{
    text-transform: 'capitalize'
}
