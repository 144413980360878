._2CGW7fHLetRLmr8ihrke- {
  position: absolute;
  top: 50px;
  bottom: 0px;
  right: 0px;
  overflow-y: scroll;
}

._1Y3Hlp4L3W9j4CivIkws9v {
  float: right;
}

.gn4F67Bi1MG3F0hkAnnEu {
  background: var(--color-content-overlay1);
  display: flex;
  margin-bottom: 10px;
  width: 390px;
  height: 110px;
  position: relative;
  margin-right: 10px;
  align-items: center;
}

.gn4F67Bi1MG3F0hkAnnEu:last-child {
  margin-bottom: 0;
}

.gn4F67Bi1MG3F0hkAnnEu:hover {
  background: var(--color-content-overlay3);
}

._1W5J6YN_oMFXsfJPGNyJeq {
  background: var(--color-content-overlay3);
}

._1vHU7EGiwoxGtjnjVtLA_y {
  position: relative;
  margin: 10px;
  width: 160px;
  height: 90px;
  display: inline-block;
}

._1vHU7EGiwoxGtjnjVtLA_y > div:first-child {
  width: inherit;
}

._3T5bQ5nnY_cD8RMADyPSTY {
  display: block;
}

.j9cg4TaoLb7td4rb6-Ldp {
  overflow: hidden;
}

.j9cg4TaoLb7td4rb6-Ldp p:last-child {
  position: relative;
  top: -5px;
  text-overflow: ellipsis;
  height: 60px;
  overflow: hidden;
  margin-bottom: unset;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
}

.j9cg4TaoLb7td4rb6-Ldp p:first-child {
  margin-top: 12px;
}

.VW9vaDidcviumF8JVE0bZ {
  color: var(--color-player-default2);
}

._1is9LxKRaYFDmOYXeUgGZO {
  color: var(--color-player-default1);
}

._2u27WcfpOp-b3W6zOLCTAO {
  position: absolute;
  top: 35px;
  left: 70px;
  z-index: 1;
}

@media (max-width: 767px) {
  ._1vHU7EGiwoxGtjnjVtLA_y {
    left: 5px;
    bottom: 12px;
    width: 130px;
    height: 75px;
    margin: 0;
  }
  .j9cg4TaoLb7td4rb6-Ldp {
    padding-left: 10px;
  }
  ._3OG0jJ6Sbof-JSl6ZKDLr {
    padding-right: 5px;
    padding-left: 6px;
  }
  ._3OG0jJ6Sbof-JSl6ZKDLr p {
    text-align: right;
  }
  .gn4F67Bi1MG3F0hkAnnEu {
    width: 100%;
  }
}

@media screen and (orientation: landscape) {
  ._3OG0jJ6Sbof-JSl6ZKDLr {
    right: 10px;
    top: 0;
  }
  ._3OG0jJ6Sbof-JSl6ZKDLr p {
    text-align: right;
  }
}

._3gfJV9p97do_5JQLXMxgDp {
  width: 42px;
  height: 42px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
}

._3gfJV9p97do_5JQLXMxgDp circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._3gfJV9p97do_5JQLXMxgDp {
  display: none;
}

.gn4F67Bi1MG3F0hkAnnEu:hover ._3gfJV9p97do_5JQLXMxgDp {
  display: initial;
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.gn4F67Bi1MG3F0hkAnnEu:hover ._7M9jTamgDtVHZanmgV6_X {
  display: none;
}

.gn4F67Bi1MG3F0hkAnnEu:hover ._4FIJiKSZzdVyYuiuMEIGj {
  display: initial;
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.gn4F67Bi1MG3F0hkAnnEu ._7M9jTamgDtVHZanmgV6_X {
  display: initial;
}

.gn4F67Bi1MG3F0hkAnnEu ._4FIJiKSZzdVyYuiuMEIGj {
  display: none;
}
