._3DzmDRvU46G2f4khr2zBlF {
  background-color: var(--color-secondary1);
  padding: 20px;
}

._3DzmDRvU46G2f4khr2zBlF>._1WNNpDnKzYF4yeylZ5sg67 {
  color: var(--color-content-default1);
  font-size: var(--fontSize-m);
  font-weight: normal;
}

._1Zte3Aq4H2HLb_QHecR-Gb {
  width: 100%;
  max-width: 100%;
  font-size: var(--fontSize-m);
}

.Ubj8unlTq0unNOOqrsI8y {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
}

._1sC8IinIg30Ok6fQd_iWpz {
  display: flex;
  margin: 10px 0 0 0;
}

._1sC8IinIg30Ok6fQd_iWpz Input {
  margin: 0 10px 20px 0;
}

._28R4TF3BfHjz5REO9Ls8vh {
  min-width: 172px;
  margin: 0 auto;
  text-transform: none;
  font-weight: normal;
  font-size: var(--fontSize-s);
}

._1Aapfx21Lt8ekpy5VHDlxY {
  display: block;
  color: var(--color-content-highlight-hover);
  font-size: var(--fontSize-s);
}

._2NRlOjj816gLq50RCElZOK {
  display: block;
  margin: 10px 0 0 0;
  font-size: var(--fontSize-s);
}

@media (max-width: 767px) {
  ._3DzmDRvU46G2f4khr2zBlF ._1WNNpDnKzYF4yeylZ5sg67, ._1Zte3Aq4H2HLb_QHecR-Gb {
    font-size: var(--fontSize-s);
  }
}