._1MzpdrKTwHJKb7XYh6kdjQ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 1s;
  -webkit-transition: all 1s;
  opacity: 1;
}

._2RLdEnoYTRyfTNpDJlGQZe {
  background: var(--color-thumbnail-overlay-background);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
  z-index: 0;
}

._35tT6AMosUlXZioTquZ6aA {
  opacity: 0.8;
  z-index: 0;
}

._38PJT2O2vMkZb8ycHx1X-E {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.AgRhpy3gxYQ2M9HxErIC8 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

._3TPketbhfQEZakZkeiBCdq {
  opacity: 1;
  transform: translateY(0);
}

._1WvIoDTBNoyxNhVw6B30tK {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
  opacity: 0;
  cursor: pointer;
  /* transition: all 250ms cubic-bezier(.21,.43,.37,.99); */
}

._2atAoH9LxUf8_aQhIqzZpZ {
  position: absolute;
  bottom: 10px;
  left: calc(10% - 20px);
  width: 40px;
  height: 40px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
  opacity: 0;
  /* transition: all 250ms cubic-bezier(.21,.43,.37,.99); */
}

._2wqWesHwQoJC_YWygoAOqr {
  bottom: 8%;
  height: 40px;
  width: 40px;
  left: calc(10% - 15px);
}

._1WvIoDTBNoyxNhVw6B30tK circle,
._2atAoH9LxUf8_aQhIqzZpZ circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._1WvIoDTBNoyxNhVw6B30tK:hover,
._2atAoH9LxUf8_aQhIqzZpZ:hover {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
  transform: scale(1.1);
}

._1SZeSsCJy-NcSxqCiOqWW- {
  opacity: 1;
}

._24aUzpIynjYjOtQB_9VemJ {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 25px 10px 10px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(100%);
  transition: all 250ms cubic-bezier(0.21, 0.43, 0.37, 0.99);
}

._2mRbJLcRs1PBXJlWn6Z6Mw {
  position: absolute;
  bottom: 0;
  left: calc(10% + 20px);
  width: 80%;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  opacity: 0;
}

._2NOfkxZzeH1vTO5zVuv6Ul {
  bottom: 5%;
  left: calc(10% + 30px);
}

.Z5fRxubzWh5XPb9SSWpkO {
  left: 80%;
}

.ZvFDH2j3-0YZWhLqVmioV {
  opacity: 1;
  transform: translateY(0);
}

._18xZ9ZaMWDIooAR8Eg83C3 {
  font-size: var(--fontSize-s);
  font-weight: bold;
  color: var(--color-thumbnail-primary1);
  opacity: 0.9;
}

.DQFgzptrk4B3PQ-ayu0-w > svg {
  fill: var(--color-slideshow-primary3);
  width: 11px;
  height: 8px;
  position: relative;
}

.c95Xiu8H-3TUIFcnbtpxB {
  font-size: var(--fontSize-xs);
  font-weight: bold;
  color: var(--color-thumbnail-primary1);
  width: auto;
  opacity: 0.9;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

._32qhkddSIaNpUuiv2S4PJW {
  font-size: var(--fontSize-s);
  font-weight: bold;
  color: var(--color-thumbnail-primary1);
  width: auto;
  opacity: 0.9;
  /* margin-bottom: 20%; */
  left: 15%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.nzpSCM85vIz0SNXDIHOA_ {
  font-size: var(--fontSize-xs);
  color: var(--color-thumbnail-primary1);
  opacity: 0.9;
}

._3JdRs63kYF3oilnlEDlBqf,
._3-Xv5wvnV1hvboMJCnKe3H {
  position: absolute;
  bottom: 20px;
  right: 10px;
  z-index: 1;
  width: 20px;
  height: 20px;
}

._3vAVYsT4TLWgSTv33WjG-3{
   position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: 1;
  width: 20px;
  height: 20px;
} 

._3-Xv5wvnV1hvboMJCnKe3H {
  opacity: 0;
}

.Ri421EmSUxZGF8MFCj48a {
  opacity: 1;
}

.VtwQWfZhfusX9MP1xeK9w {
  top: 150px;
  left: 50%;
  transform: translate(-10px, -50%);
}
._33t4ThzEJhF16_88UNDv64,
._2Ap0N3CARh14gw1vjhjuZ_ {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  width: 20px;
  height: 20px;
}
._33t4ThzEJhF16_88UNDv64{
  bottom: 18px;
}

@media(max-width: 767px) {
  ._33t4ThzEJhF16_88UNDv64{
    right:18px  
  }
}

._2DnGvB1Bvdc97psvwZwf19 {
  bottom: 12%;
  width: 25px;
  height: 25px;
  right: 10px;
}

._3elLYajFkf9NGuwBc824ca {
  padding-right: 30px;
}

._3ULtoABO_xMEXkZVuOaQU9 {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  color: var(--color-primary1);
  font-size: var(--fontSize-s);
}

._1dinHSR8YKu56Lmg36yotL {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  width: 40px;
  height: 40px;
}

._34tNJT9eqqGLpdnT3A8LiT {
  top: -2px;
  left: 10px;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
}
._35bBuOEo8jF5mn2SPYAgQ {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

._35bBuOEo8jF5mn2SPYAgQ::before,
._35bBuOEo8jF5mn2SPYAgQ::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

._35bBuOEo8jF5mn2SPYAgQ::before {
  border-width: 20px;
}

._35bBuOEo8jF5mn2SPYAgQ::after {
  border-width: 20px;
  border-right-color: var(--color-border-right-padlock-container);
  border-top-color: var(--color-border-top-padlock-container);
}

._2lEJbEkmi8JIhtpRg_PwJu {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 110px;
  right: 0;
}

._2lEJbEkmi8JIhtpRg_PwJu::after {
  border-radius: 50%;
  background-color: var(--color-border-top-padlock-container);
  position: absolute;
  bottom: 0;
  right: 0;
}

._2L3-arQVeCksHE-a2PNjhW {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}

._1YEq3DXDtrtk2AoCLts_da {
  position: absolute;
  top: 12px;
  left: -6px;
  z-index: 1;
}

._3gtrTiUmmUZY5C0ynXeGky,
._3gtrTiUmmUZY5C0ynXeGky > svg {
  cursor: pointer;
  width: 16px;
  fill: var(--color-icon-playlock-fill);
}

.ox4zavrXdGQauy-3Znqo0 {
  cursor: pointer;
  display: none;
}

._3t3oXytUgVpIgvPbCuT8sO {
  cursor: pointer;
}

.E7NIhQIufbYd0gStfKdWJ {
  width: 100%;
  height: 100%;
}

._3t3oXytUgVpIgvPbCuT8sO:hover .BbJ-ZcVv3J3QBnBjk_hSw {
  display: none;
  opacity: 0;
}

._3t3oXytUgVpIgvPbCuT8sO:hover .ox4zavrXdGQauy-3Znqo0 {
  display: block;
}

._2oMy9YRBeuD2ZoFHcd-IRN {
  display: none;
}

@media (max-width: 1024px) {
  .VtwQWfZhfusX9MP1xeK9w {
    top: 130px;
  }
}

._2tOhaRwA0a04d6lVEVsk5t {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: "center";
  width: 100%;
}

.ywQ1SPVAAmwvO1W8R2l5T {
  /* margin-top: 10px; */
  height: 180px;
}

._1NABwc_T3W_5RpeLGM0hvN {
  height: 185px;
}

._2_bpmaz6zfrLPLqT6b4ldB {
  opacity: 0.3;
  display: none;
  -webkit-transition: all 0.02ms ease-in-out;
  -moz-transition: all 0.02ms ease-in-out;
  -o-transition: all 0.02ms ease-in-out;
  transition: all 0.02ms ease-in-out;
}
