._11DrbZDVKc_0VKu2bQYMsa {
  color: var(--color-content-default1);
  max-width: 490px;
}

._3wQxwxFeGch05xtvpD6J8x {
  width: calc(100% - 10px);
}

._3wQxwxFeGch05xtvpD6J8x:hover {
  background-color: var(--color-secondary1);
}

._2ziql4UGb9Li4xjUoEXFSa {
  float: left;
  cursor: pointer;
  font-size: var(--fontSize-s);
  font-weight: normal;
}

.BO3tt5oKqaoU0Sk7ayNMe {
  float: right;
  cursor: pointer;
  font-size: var(--fontSize-s);
  font-weight: normal;
}

._2y9MRd7ROEsQF0neweqXdh {
  display: flex;
  flex-flow: column;
  margin: 0;
}

._2HzB1Fa_MrCp3WoaetYZia {
  margin: 10px 0 5px 0;
  font-size: var(--fontSize-l);
  font-weight: bold;
}

._2JFHB-lmRjtXdEi7V9SfY6 {
  color: var(--color-content-highlight-hover);
  font-weight: bold;
}

._3fygq5nKrIwUprM2KkwZHm {
  min-height: 30%;
  height: auto;
}

.Collapsible__trigger .nBObRwznyPt3k9mH-hYsu {
  width: 20px;
  height: 20px;
  transform: rotate(-270deg);
}

.Collapsible__trigger .nBObRwznyPt3k9mH-hYsu path {
  stroke: var(--color-content-default1);
}

.Collapsible__trigger.is-open .nBObRwznyPt3k9mH-hYsu {
  transform: rotate(270deg);
}

.uzCgwexWlHZCD-1L164Rd h1 {
  margin-top: 50px;
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
}

._2yYmMhxctOMU4hEG1Toeod {
  font-size: var(--fontSize-s);
  font-weight: bold;
}

._3BjUo2i9YeWVM_r9QwPKB5 {
  margin: 50px 0 0 0;
  text-align: center;
}

._1bpgrn2T6v6h2r-IZFo-MM {
  display: block;
  margin: 20px 0 30px 0;
  text-align: center;
}

.lfJ0VI3SKA3NxZu2f9ssi {
  display: block;
  text-align: center;
  width: fit-content;
  margin: 10px auto;
  text-transform: none;
  font-weight: normal;
}

._1jsNyciE7wF0RL5Kvd4wHs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid var(--color-primary3);
  margin-bottom: 20px;
}

.cxe32AfRnsOgYZNX4GaEk {
  width: 400px;
}

._1m3hxQM84dwbc3iN2mHMwM {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: none;
  margin-top: 23px;
}

._22fbfrPTU8s4s2V0AgBkXU {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 12px;
  margin-top: 10px;
  color: var(--color-highlight-hover1);
  cursor: pointer;
}

.MRBQE51dhAUG7AM-KYqkq {
  width: 390px;
  margin-left: 45px;
  font-size: 14px;
  color: var(--color-primary3);
}

._1L1UZPEnAiesjex6qc4Peo {
  height: 30% !important;
}

._2giKVmRIbH2s3i7FYceKt7 {
  color: var(--color-content-highlight-hover);
  margin-bottom: 15px;
  font-size: var(--fontSize-xs);
}
