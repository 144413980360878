._2-qjiUobl9jJ9AR0nVoic8 {
  max-width: 1024px;
  margin: 0 auto;
}
.oq5ydynHewaEn4gKZ_MrK {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 23px;
}
._1jkB_2b1UXp02nJ-3RoeKC {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
  margin-top: 10px;
  color: var(--color-highlight-hover1);
  cursor: pointer;
}
.hcco0Z5xDUxdsvb0EmOXn {
  width: 544px;
  min-height: 38px;
  margin-left: 45px;
  font-size: 14px;
  color: var(--color-primary3);
}

._3J8KAxlf7V82ZuYMds3p81 {
  display: none;
}

.PM4X9Afys17nrn7--ms2x {
  display: inline-block;
}
.W7TlTBy3ZV_1gGOWxT_f0 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 23px;
}
._3Uhn2e0oeuUJkoOqc2JaGZ {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 12px;
  margin-top: 10px;
  color: var(--color-highlight-hover1);
  cursor: pointer;
}
._2gxMwG5N3AQYkumEwzvQDm {
  width: 390px;
  margin-left: 45px;
  font-size: 14px;
  color: var(--color-primary3);
}
._38-t2fzryVTqzOCE6ZEzCa {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid var(--color-primary3);
  margin-bottom: 20px;
}
._3rFlp6E-IfPIzw6qLDm_2h {
  width: 400px;
}
.Collapsible__trigger ._1GMSZWdyeHmeaGgddoWlkR {
  width: 20px;
  height: 20px;
  transform: rotate(-270deg);
}

.Collapsible__trigger ._1GMSZWdyeHmeaGgddoWlkR path {
  stroke: var(--color-content-default1);
}

.Collapsible__trigger.is-open ._1GMSZWdyeHmeaGgddoWlkR {
  transform: rotate(270deg);
}
.XD3fNgVRJlluBxhbAkSo2 {
  min-height: calc(100vh - 225px); /* margin-top + margin-bottom */
  overflow: hidden;
  user-select: none;
  text-decoration: none;
}

.XD3fNgVRJlluBxhbAkSo2 .react-tabs__tab {
  padding: 0 10px 20px 10px;
  border: none;
  background-color: transparent;
  color: var(--color-content-default3);
  font-weight: bold;
}

.XD3fNgVRJlluBxhbAkSo2 .react-tabs__tab--selected {
  border-bottom: 4px solid var(--color-content-highlight-hover);
  background-color: transparent;
  color: var(--color-content-default1);
}

.XD3fNgVRJlluBxhbAkSo2 .react-tabs__tab-list {
  margin: 0 0 20px 0;
}

.XD3fNgVRJlluBxhbAkSo2 .react-tabs__tab-panel {
  opacity: 0;
}

.XD3fNgVRJlluBxhbAkSo2 .react-tabs__tab-panel--selected {
  opacity: 1;
  animation: _3PmbpekyJiHQNTAmrydA6t 300ms linear;
}

@keyframes _3PmbpekyJiHQNTAmrydA6t {
  0% {
    transform: translateX(50vw);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

._2ouFPtqG146FVMYtnGwUK5 {
  margin: 40px 0 10px 0;
  color: var(--color-content-default1);
  font-weight: bold;
}

.-gCpV8Lu_whK7M5IR9VYi {
  max-width: 490px;
  color: var(--color-content-default1);
}
label._3j9IrsfzAel3G_yS7QXLtj {
  position: relative;
  height: 100%;
  min-height: 100px;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-overlay3);
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.o04V2sgjRXQwylxYYQ_9L {
  height: 110px;
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  left: 140px;
  top: 15px;
}
._1XQaZv1EIGmArl7tIuPpxH {
  position: absolute;
  /* top: 30px; */
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1R6ILKTX7yUGRfxzaSgRMI {
  display: block;
  width: fit-content;
  margin: 40px auto;
  text-transform: none;
  font-weight: normal;
  position: absolute;
  right: 25px;
}
._2VNs1jwrlemhPTROVvpR90 {
  display: block;
  width: 80px;
  height: 80px;
  margin: 20px auto;
}

._2VNs1jwrlemhPTROVvpR90 circle {
  fill: var(--color-content-highlight-hover);
}

._2VNs1jwrlemhPTROVvpR90 path {
  fill: var(--color-content-overlay3);
}

.vYIbxC8cy89QYeKgVbAdd {
  margin-top: 0;
  margin-bottom: 20px;
}

.s5iSnpCdJdSVnHsC1BT18 {
  margin: 20px 0 5px 0;
}

._1qJ5p_TFSg8Iis8uqwCHNF {
  display: block;
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-highlight-hover);
}

._13ScmY4C3UMGwEgichNNDM {
  margin: 20px 0;
  border-bottom: 1px solid var(--color-content-default3);
}

._1KPoLsOZp8ld4crD2uZnDS {
  display: block;
  width: 50%;
  margin: 10px auto;
  text-transform: none;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

._3wzx51b9LcHnHx3sJqRRXn {
  margin: 50px 0 0 0;
  text-align: center;
}

._2tLDoVjg1DoHBCScAYDCfW {
  display: block;
  margin-top: 20px;
  text-align: center;
}

._24Lj5Me6EZSIYjku8MYwjN {
  display: flex;
  min-height: 222px;
}

.btZ39d--55iPp8RVnMovY {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._1-AfCFlOeOt9xuOnYHBN23 {
  position: relative;
  top: 10px;
}

._3qVOPjjNh_DCxeOh8JTBZ- {
  color: var(--color-content-highlight-hover);
  margin-bottom: 15px;
  font-size: var(--fontSize-xs);
}

@media (max-width: 450px) {
  .btZ39d--55iPp8RVnMovY {
    align-items: baseline;
    flex-direction: column;
  }

  .btZ39d--55iPp8RVnMovY > ._1-AfCFlOeOt9xuOnYHBN23 {
    margin-bottom: 20px;
    top: 0px;
  }

  .XD3fNgVRJlluBxhbAkSo2 > ul > li:nth-child(2) {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .-gCpV8Lu_whK7M5IR9VYi {
    width: 300px;
  }

  .s5iSnpCdJdSVnHsC1BT18 {
    font-size: var(--fontSize-l);
  }

  ._1qJ5p_TFSg8Iis8uqwCHNF {
    font-size: var(--fontSize-m);
  }

  ._24Lj5Me6EZSIYjku8MYwjN {
    min-height: 163px;
  }
}
