._2xyHMURhBWSbPWXsj3DkD4 {
  max-width: 490px;
  color: var(--color-content-default1);
}

.fsXobMfXTTwTeC8L4FDPY {
  margin-top: 0;
  margin-bottom: 20px;
}

._3L7YiMUvfUW-4zbifp9Shs {
  margin: 50px 0 0 0;
  text-align: center;
}

._3qIRtkdqi4vrP2dFHR3kxe {
  display: block;
  margin: 20px 0 30px 0;
  text-align: center;
}

._1JJOU5CVyzjnL_4x3RQZ1A {
  display: block;
  width: fit-content;
  margin: 10px auto;
  text-transform: none;
  font-weight: normal;
}
