:root {
  --height: 40px;
  --fontSize: var(--fontSize-m);
}

._3xqZB3iQVIqvBS94Mgt8MA {
  color: var(--color-button-normal-font);
  /*min-width: 250px;*/
  display: inline-block;
  padding: 5px 20px;
  text-align: center;
  margin: 0 10px 10px 0;
  height: var(--height);
  line-height: 27px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: var(--borderRadius-button-normal);
  border: var(--borderSize-button-normal) solid
    var(--color-button-normal-border);
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
}

._3xqZB3iQVIqvBS94Mgt8MA:hover {
  background: var(--color-button-normal-bg-hover);
}

._3xqZB3iQVIqvBS94Mgt8MA:active {
  background: var(--color-button-normal-bg);
}

._3i1BT5Ako0ieSBrIIBq5Ka {
  background: var(--color-button-normal-bg);
}

._3MxhP5E7IkhLyppXrlQPvG {
  color: var(--color-button-ghost-font);
  background: var(--color-button-ghost-bg);
  border: var(--borderSize-button-ghost) solid var(--color-button-ghost-border);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

._3MxhP5E7IkhLyppXrlQPvG:hover {
  color: var(--color-button-ghost-font-hover);
  background: var(--color-button-ghost-bg-hover);
  border: var(--borderSize-button-ghost) solid
    var(--color-button-ghost-border-hover);
}

.zPab1iFA5rl8KHne7-FY_ {
  color: var(--color-button-bordered-font);
  background: var(--color-button-bordered-bg);
  border-radius: var(--borderRadius-button-bordered);
  border: var(--borderSize-button-bordered) solid
    var(--color-button-bordered-border);
}

.zPab1iFA5rl8KHne7-FY_:hover {
  color: var(--color-button-bordered-font-hover);
  background-color: var(--color-button-bordered-bg-hover);
  border: var(--borderSize-button-bordered) solid
    var(--color-button-bordered-border-hover);
}

.WrPY96gj_8y207uYFMPMM,
.WrPY96gj_8y207uYFMPMM:hover {
  color: var(--color-button-disabled-font);
  background-color: var(--color-button-disabled-bg);
  border: var(--borderSize-button-disabled) solid
    var(--color-button-disabled-border);
  cursor: auto;
}

._3iqEa5rbq4kWyQ6619JSU4 {
  color: var(--color-button-link-font);
  padding: 0;
  min-width: unset;
  cursor: pointer;
  text-transform: none;
  border: 0;
  text-decoration: none;
  font-size: initial;
  height: auto;
}

._3iqEa5rbq4kWyQ6619JSU4:hover {
  color: var(--color-button-link-font-hover);
  background: none;
}

._1pFLveso9MAU_GgWJc0Cog svg {
  margin-right: 10px;
  vertical-align: middle;
  width: 24px;
  height: 24px;
}

._1pFLveso9MAU_GgWJc0Cog svg path {
  fill: var(--color-button-bordered-font);
}

.zPab1iFA5rl8KHne7-FY_:hover ._1pFLveso9MAU_GgWJc0Cog svg path {
  fill: var(--color-button-bordered-font-hover);
}

._1ipsqjKxQ6-lA997XjTblO {
  color: var(--color-button-login-normal-font);
  background-color: var(--color-button-login-normal-bg);
  border: var(--borderSize-button-login-normal) solid
    var(--color-button-login-normal-border);
  border-radius: var(--borderRadius-button-login-normal);
}

._1ipsqjKxQ6-lA997XjTblO:hover {
  color: var(--color-button-login-normal-font-hover);
  background-color: var(--color-button-login-normal-bg-hover);
  border: var(--borderSize-button-login-normal) solid
    var(--color-button-login-normal-border-hover);
}

._104768wZg8nledTcaXC1NH {
  color: var(--color-button-login-ghost-font);
  background-color: var(--color-button-login-ghost-bg);
  border: var(--borderSize-button-login-ghost) solid
    var(--color-button-login-ghost-border);
  border-radius: var(--borderRadius-button-login-ghost);
}

._104768wZg8nledTcaXC1NH:hover {
  color: var(--color-button-login-ghost-font-hover);
  background-color: var(--color-button-login-ghost-bg-hover);
  border: var(--borderSize-button-login-ghost) solid
    var(--color-button-login-ghost-border-hover);
}

._34kUeqPSTHdUnC88XrPHDW {
  color: var(--color-button-login-bordered-font);
  background-color: var(--color-button-login-bordered-bg);
  border: var(--borderSize-button-login-bordered) solid
    var(--color-button-login-bordered-border);
  border-radius: var(--borderRadius-button-login-bordered);
}

._34kUeqPSTHdUnC88XrPHDW:hover {
  color: var(--color-button-login-bordered-font-hover);
  background-color: var(--color-button-login-bordered-bg-hover);
  border: var(--borderSize-button-login-bordered) solid
    var(--color-button-login-bordered-border-hover);
}

._3lJilY_SM702PR_FQyO0By {
  color: var(--color-button-login-link-font);
  padding: 0;
  min-width: unset;
  cursor: pointer;
  text-transform: none;
  border: 0;
  text-decoration: none;
  font-size: initial;
  height: auto;
}

._3lJilY_SM702PR_FQyO0By:hover {
  color: var(--color-button-login-link-font-hover);
  background: none;
}

._1N-Msa0tr-GzSRdLhnZHWn,
._1N-Msa0tr-GzSRdLhnZHWn:hover {
  color: var(--color-button-login-disabled-font);
  background-color: var(--color-button-login-disabled-bg);
  border: var(--borderSize-button-login-disabled) solid
    var(--color-button-login-disabled-border);
  border-radius: var(--borderRadius-button-login-disabled);
  cursor: auto;
}
