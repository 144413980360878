:root {
  --PageFavorites-grid-width: 390px;
  --PageFavorites-grid-cover-width: 500px;
  --grid-column-gap: 60px;
  --grid-row-gap: 20px;
}

@media (max-width: 768px) {
  :root {
    --PageFavorites-grid-width: 390px;
    --PageFavorites-grid-cover-width: 300px;
    --grid-column-gap: 30px;
    --grid-row-gap: 10px;
  }
}
@media (max-width: 320px) {
  :root {
    --PageFavorites-grid-width: 280px;
    --PageFavorites-grid-cover-width: 300px;
    --grid-column-gap: 30px;
    --grid-row-gap: 10px;
  }
  ._2zmpMIwDuxqt5yITBAJXSp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 389px) {
  ._36HnPYkHR2K8Bnrwa3Tyyd > * {
    width: 88%;
    left: 5px;
  }
}

@media (max-width: 359px) {
  ._36HnPYkHR2K8Bnrwa3Tyyd > * {
    width: 79%;
  }
}

@media (max-width: 320px) {
  ._36HnPYkHR2K8Bnrwa3Tyyd > * {
    width: 100%;
  }
}

._3Vyk50EUnNtC-wRPlQeJlA {
  min-height: calc(100vh - 130px); /* margin-top + margin-bottom */
}

._36HnPYkHR2K8Bnrwa3Tyyd {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageFavorites-grid-width), 1fr)
  );
  grid-column-gap: 30px;
  grid-row-gap: 40px;
}
._2zmpMIwDuxqt5yITBAJXSp {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageFavorites-grid-cover-width), 1fr)
  );
  grid-column-gap: var(--grid-column-gap);
  grid-row-gap: var(--grid-row-gap);
}

._3Wnukv0A2KlDjTaf0m1prQ h1 {
  margin-top: 50px;
  font-size: var(--fontSize-l);
  font-weight: bold;
  color: var(--color-content-default1);
}
