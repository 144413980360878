.Nz-MTyY8aS_fTo0QV3RjV {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px 20px 40px;
    font-weight: bold;
}

._1jFI8UEA_6QiUVzxCVkTXs {
    color: var(--color-login-primary1);
    font-weight: bold;
}


@media screen and (max-width: 768px) {
    .Nz-MTyY8aS_fTo0QV3RjV {
        /* padding-left: 0; */
    }
}

._2Mgt9KjATO4ILqRc6QnrXN {
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

._2vAjsgcWnZ0l9FeA7iL0tq {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center,
}

._39UuKWCa0tImIlJjJDaO_K {
    font-size: var(--fontSize-s);
    width: 100%;
    margin: 0;
    margin-top: 15px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-button-login-bordered-font);
    background-color: var(--color-button-login-bordered-bg);
    /* border: var(--borderSize-button-login-bordered) solid var(--color-button-login-bordered-border); */
    border-radius: var(--borderRadius-button-login-bordered);
}

._3P49f3Y06Z1-od91bS79B3 {
    font-size: var(--fontSize-s);
    width: 100%;
    margin: 10px 0;
    padding: 0;
    line-height: 50px;
    /* margin-top: 50px; */
    height: 50px;
}