._11pgEpt6ynaotimcp0m9tL {
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
}

._3y_J_miykd8TvkldBWyBDy {
  font-weight: 600;
  margin-bottom: 70px;
}

._2GzJW_gsuqOLL099LSykP5 {
  margin: 0 20px;
}

._3mJXjwdSK9adq0tfV0ZtJn {
  font-size: var(--fontSize-m);
  font-weight: normal;
  color: var(--color-content-default1);
  margin-bottom: 50px;
}

._3mJXjwdSK9adq0tfV0ZtJn:nth-child(2n) {
  margin-bottom: 20px;
  color: var(--color-content-highlight-hover);
  font-weight: bold;
}

@media(max-width: 767px) {
  ._3y_J_miykd8TvkldBWyBDy {
    margin-bottom: 40px;
  }

  ._3mJXjwdSK9adq0tfV0ZtJn {
    font-size: var(--fontSize-s);
    margin-bottom: 30px;
  }

  ._3mJXjwdSK9adq0tfV0ZtJn:nth-child(2n) {
    margin-bottom: 10px;
  }
}
