._15q0pahT8wWxCGhA75w17r {
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
}

hr {
  border-color: var(--color-primary3);
}

._2nJp85XmBTjAhoa4qmEONX {
  font-size: var(--fontSize-l);
  font-weight: normal;
  color: var(--color-primary1);
  margin-bottom: 50px;
}

._3D1GSrQuL0C5C6XgQpzl-K {
  font-size: var(--fontSize-s);
  font-weight: normal;
  color: var(--color-primary1);
  margin: 20px 0;
}

._3hL26a52qi9kQKzi4qwNVc {
  display: flex;
  justify-content: space-between;
}

._3QFYSvHVnrucl1If_anhMR {
  width: 100px;
}

._2bdqlXaliCXEZ6_cnAG23o {
  margin-right: 60px;
  z-index: 9999;
}

.wl-0bGGcZuXyHwc3R6KXW {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.sEd3Vv32nqMzlqIqCk2ag {
  flex-direction: row-reverse;
}

._2cP0wwXgJqDNT9A2rgUkMH {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

._3faziAY7Gq1v2NqdJULlbE {
  font-size: var(--fontSize-s);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-content-highlight-hover);
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

._1KqXp7LW3DuFZS_2DaMycO a {
  margin-left: 20px;
}

@media (max-width: 767px) {
  ._1KqXp7LW3DuFZS_2DaMycO {
    display: flex;
    margin-left: 0;
  }
  ._1KqXp7LW3DuFZS_2DaMycO a {
    margin: 0 10px 0 0 !important;
  }
}

._2cQK8_pk7ALF3jWKpkdYrx {
  height: 40px;
  margin-bottom: 5px;
  float: left;
  width: 94%;
}

._2cQK8_pk7ALF3jWKpkdYrx p {
  display: inline-block;
  margin-top: 5px;
}

._27r1P7OBZpVqywP1SgbV0T {
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary1);
}

._2empwrFpPzvmn9NpO4FYZg .Collapsible {
  border: 1px solid var(--color-primary3);
  padding: 5px 20px 0;
}

._2empwrFpPzvmn9NpO4FYZg .Collapsible__trigger ._21zzXxqF34qI3opcZp460d {
  width: 20px;
  height: 20px;
  transform: rotate(-270deg);
}

._2empwrFpPzvmn9NpO4FYZg .Collapsible__trigger ._21zzXxqF34qI3opcZp460d path {
  stroke: var(--color-content-default1);
}

._2empwrFpPzvmn9NpO4FYZg .Collapsible__trigger.is-open ._21zzXxqF34qI3opcZp460d {
  transform: rotate(270deg);
  margin-top: 2px;
}

._2empwrFpPzvmn9NpO4FYZg .Collapsible__trigger.is-closed ._21zzXxqF34qI3opcZp460d {
  float: right;
  padding-bottom: 10px;
  margin: 0;
}

._2empwrFpPzvmn9NpO4FYZg .Collapsible__contentOuter {
  width: 100%;
}

._2empwrFpPzvmn9NpO4FYZg .Collapsible__trigger {
  margin-top: 5px !important;
  float: right;
  width: 5%;
  flex-direction: row-reverse;
}

._2empwrFpPzvmn9NpO4FYZg .arrow {
  float: right;
}

._3vuvjWwqLGKJo8wWSrvM9H {
  min-height: 80px;
  width: 100%;
  display: grid;
  color: var(--color-primary1);
}

.fPtGWe5Bgk2dIA0nF14dz {
  display: inline-block;
  float: right;
}

._21zzXxqF34qI3opcZp460d svg {
  float: right;
}

._1yZh1PSNeLKOlrG8cHd-2P {
  color: var(--color-primary3);
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

._2cNKWU76Rz9GSLI36t1l9- {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  color: var(--color-content-highlight-hover);
}

.bqOieS_DfNoJyBcbctvDX {
  display: flex;
}

._1Jt257EahDvENTue9rsDut {
  margin: auto 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-primary1);
  text-decoration: none;
  cursor: pointer;
}

._1Jt257EahDvENTue9rsDut > span {
  bottom: 9px;
  position: relative;
}

._1eWVkZW49jfoRvLd4NV5Op {
  width: 24px;
  height: 32px;
  margin: auto 0;
  transform: rotate(180deg);
  stroke: var(--color-player-default1);
  stroke-width: 2px;
  transition: stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}
