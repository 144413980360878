._5Ty_ueZrrH6Fvo3HUETB0 {
    margin-top: 20px;
    padding-bottom: 10px;
    text-align: center;
}

._3RTUO_w7STQ2wrzTuo-lra {
    color: var(--color-login-primary2);
    padding-right: 10px;
  }

._2Rpvwf1N2zJP2eIbrT59jm {
  text-decoration: none;
  color: var(--color-login-highlight-hover1);
  font-weight: bold;
  margin-bottom: 20px;
}
