*,
*:after,
*:before {
  box-sizing: border-box;
}

fieldset {
  border: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  z-index: 1;
}

details {
  width: max-content;
}

._29pAKkbPxPm3ykaZPtB56- {
  max-width: 148px;
}

.G4GxH3TDJnjc2VeUjU4cx {
  max-width: max-content;
}

._112qO58vXMQRc4ykQKNtJC {
  width: 40px;
  height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

._112qO58vXMQRc4ykQKNtJC details {
  width: 40px;
  height: 40px;
}

._112qO58vXMQRc4ykQKNtJC summary {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0px;
  line-height: initial;
}

._112qO58vXMQRc4ykQKNtJC .Akn3qEsKu3HwnRc_dxjks {
  line-height: 14px;
}

details {
  z-index: 9999;
  position: relative;
  height: 40px;
}

details summary {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  background-color: var(--color-secondary3);
  padding: 0px 1rem;
  line-height: 40px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  list-style: none;
  width: auto;
  height: 40px;
}

details summary.NNttdRoGRLU1vfwI6ALnU {
  background-color: transparent;
  width: auto;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary:hover {
  background-color: var(--color-highlight-hover1);
  color: var(--color-button-normal-font-hover);
}

details summary.NNttdRoGRLU1vfwI6ALnU:hover {
  background-color: transparent;
  color: var(--color-highlight-hover1);
}

.VjTpf2-rLedUribDdvavt > svg,
._1CFsYQ06ZRowndND4-81BT > svg,
.AbOnetkyyN8CR2_0JGG9y > svg,
._1tHZWyRIEbYVAAwgKYDfFT > svg {
  height: 24px;
  width: 24px;
  stroke: var(--color-primary1);
  margin: 8px 0;
}

._2UFKPiA6QDVEU7UXamnaQ7 {
  display: flex;
  align-items: center;
  gap: 5px;
}
._3WJ0U3DILwXecyWeP1wjCw {
  margin: 5px 0;
}
._1MeUaCNsWa-RyCWKk4od5H {
  height: 24px;
  width: 24px;
  fill: var(--color-primary1);
  margin: 8px 0;
  display: block;
}

._1CFsYQ06ZRowndND4-81BT > svg {
  transform: rotate(-90deg);
}

.AbOnetkyyN8CR2_0JGG9y > svg {
  margin: 0px;
}

.VjTpf2-rLedUribDdvavt > svg {
  transform: rotate(90deg);
}

._1tHZWyRIEbYVAAwgKYDfFT > svg {
  transform: rotate(180deg);
  margin: 0px;
}

details summary:hover ._1CFsYQ06ZRowndND4-81BT > svg,
details label:hover .AbOnetkyyN8CR2_0JGG9y > svg,
details label:hover ._1tHZWyRIEbYVAAwgKYDfFT > svg,
details summary:hover .VjTpf2-rLedUribDdvavt > svg {
  stroke: var(--color-button-normal-font-hover);
}

details summary:hover ._1MeUaCNsWa-RyCWKk4od5H svg path {
  fill: var(--color-button-normal-font-hover);
}

details summary.NNttdRoGRLU1vfwI6ALnU:hover ._1MeUaCNsWa-RyCWKk4od5H svg path {
  fill: var(--color-highlight-hover1);
}

details summary i {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  transform-origin: center center;
}

details[open]summary i {
  transform: scaleY(-1) translateY(50%);
}

details > div {
  margin-top: 8px;
  background-color: var(--color-secondary3);
  display: flex;
  position: absolute;
  flex-direction: column;
  /* height: 280px; */
  overflow-y: auto;
  text-align: left;
  min-width: 100%;
  width: max-content;
  max-width: 300px;
  max-height: 300px;
  border-radius: var(--borderRadius-select-normal);
  box-shadow: var(--boxShadow-select-normal);
}

details > div._1HlgnSFV5Q6XSURX6F930y{
  top: -98px;
  right: 3px;
}

details > div._1pYNh67zPHFzcFKKkDTS2x{
  top: -46px;
  right: 8px;
}

details > div ._2Xrl4kXzRZrUq37KAkb-EA {
  color: var(--color-primary3);
  display: flex;
  padding: 0 1rem;
  line-height: 40px;
  justify-content: space-between;
}

details > div ._21F2laKnTzZvepxFIbzDj_ {
  color: var(--color-primary1);
  position: relative;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
}

details > div ._21F2laKnTzZvepxFIbzDj_._3f6hcLvggyYshGb-K9gIK {
  color: var(--color-highlight-hover1);
}

details > div ._21F2laKnTzZvepxFIbzDj_ ._4z1eAOkx5nzgOhzuUCjxG path {
  fill: var(--color-primary1);
}

details > div ._21F2laKnTzZvepxFIbzDj_._3f6hcLvggyYshGb-K9gIK ._4z1eAOkx5nzgOhzuUCjxG path {
  fill: var(--color-highlight-hover1);
}

details > div ._21F2laKnTzZvepxFIbzDj_:hover ._4z1eAOkx5nzgOhzuUCjxG path {
  fill: var(--color-primary1);
}

details > div ._21F2laKnTzZvepxFIbzDj_:hover {
  background-color: var(--color-highlight-hover1);
  color: var(--color-button-normal-font-hover);
}

details > div ._21F2laKnTzZvepxFIbzDj_:hover path {
  fill: var(--color-button-normal-font-hover);
}

details > div ._21F2laKnTzZvepxFIbzDj_:checked + span {
  color: var(--color-highlight-hover1);
}

details > div ._3s9lgcRJKz3diIGqVsv_F1 {
  position: relative;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-primary3);
}
details > div ._21F2laKnTzZvepxFIbzDj_ > span,
details > div ._3s9lgcRJKz3diIGqVsv_F1 > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

details > div ._3s9lgcRJKz3diIGqVsv_F1:hover {
  background-color: var(--color-highlight-hover1);
  color: var(--color-button-normal-font-hover);
}

details > div ._3s9lgcRJKz3diIGqVsv_F1:checked + span {
  color: var(--color-highlight-hover1);
}

details > div span:hover {
  background-color: var(--color-highlight-hover1);
  color: var(--color-button-normal-font-hover) !important;
}

details > div ._1NVL-5c9Gmt5x_UK9Nxf1A span:hover {
  background-color: var(--color-secondary3);
  color: var(--color-button-normal-font-hover) !important;
}

details > div label > span {
  display: block;
  padding: 0 1rem;
  line-height: 40px;
}

details > div ._2ZtROsEBd03nqsFOLRUSEb {
  background-color: var(--color-secondary1) !important;
  color: var(--color-primary2) !important;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  margin-top: 10px;
  margin-right: 10px;
  padding: 0 6px;
}

details > div ._2ZtROsEBd03nqsFOLRUSEb:hover {
  color: var(--color-primary2) !important;
}

details > div ._4z1eAOkx5nzgOhzuUCjxG {
  color: var(--color-primary2) !important;
  margin-top: 5px;
  padding: 0px;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

details > div ._3tp4o_sefvc5lnAXww1ckh {
  width: auto;
  height: auto;
  line-height: 20px;
  margin-top: 8px;
  /* margin-right: 10px; */
  padding: 0 6px;
}

details > div ._3tp4o_sefvc5lnAXww1ckh:hover {
  color: var(--color-primary2) !important;
}

details:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  left: 4px;
  width: calc(100% - 16px);
  height: 50px;
  flex-shrink: 0;
  pointer-events: none;
}

.Akn3qEsKu3HwnRc_dxjks {
  padding: 8px;
}
._2bgoen16jOvIjHdIQP11kQ{
  padding: 10px 18px
}
._3EZHpbHZtwDkEw8-zjKRIG{
  margin: 11px -2px;
}

._1NVL-5c9Gmt5x_UK9Nxf1A {
  color: var(--color-primary3);
}

.DDvRlI1L10Y4xan0C1wSP {
  display: flex;
}

details summary:hover svg path {
  fill: var(--color-button-normal-font-hover);
}

@media (max-width: 767px) {
  details > div._1HlgnSFV5Q6XSURX6F930y{
    top: -58px;
    right: 23px;
  }
  ._2bgoen16jOvIjHdIQP11kQ{
    padding: 0px 21px
  }
  ._3EZHpbHZtwDkEw8-zjKRIG{
    margin: -2px 0 0 23px
  }
}
