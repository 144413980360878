._1-7BrAW2VYGeX7KZRmDdZ_ {
  position: absolute;
  bottom: -103px;
  width: 100%;
  height: 80px;
  background-color: var(--color-content-overlay2);
  line-height: 80px;
  border-bottom: 3px solid var(--color-content-overlay2);
}

._1R9pBnUkoMW-tousHGGTv- {
  float: right;
  margin-right: 20px;
}

._2g48fJrZgDMU7PmZs2D6TU {
  color: var(--color-content-default3);
  font-size: var(--fontSize-xxs);
  text-transform: uppercase;
  margin-right: 10px;
}

.reb0X1ASgS-fqFJaTqykV {
  width: 100px;
  height: 50px;
  vertical-align: middle;
}
