._24sBOOEfgjnVhrTV-YV9fo {
  color: var(--color-content-default1);
  background-color: var(--color-epg-header-grid-bg);
  height: 50px;
  text-align: center;
  box-sizing: border-box;
}

._24sBOOEfgjnVhrTV-YV9fo:hover {
  background-color: var(--color-epg-header-grid-bg-hover);
}

._1YnRNQP8JBMZ52WwUnXp8f {
  display: block;
  height: 45px;
  cursor: pointer;
}

.qlKHPWinjOnBlWfoa6tdj {
  height: 45px;
  width: 45px;
  margin: 0 auto;
}

._1cK6ZUKRwHVyzYXn0p3Kvr {
  position: absolute;
  top: 18px;
  left: 30px;
  width: 12px;
  height: 11px;
  display: flex;
  align-items: center;
  /* padding-top: 3px; */
  object-fit: contain;
  fill: var(--color-primary1);
}
._20T50jE1Ts7-UKotYX-Qz6 {
  position: absolute;
  top: 13px;
  left: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  /* padding-top: 3px; */
  object-fit: contain;
}

._9rXAJB3jiq_WUTblVsW6b {
  transform: rotate(90deg);
  fill: var(--color-primary1);
}
._20T50jE1Ts7-UKotYX-Qz6:hover ._9rXAJB3jiq_WUTblVsW6b {
  transform: rotate(90deg);
  fill: var(--color-highlight-hover1);
}

._20T50jE1Ts7-UKotYX-Qz6:hover ._9rXAJB3jiq_WUTblVsW6b circle {
  fill: var(--color-highlight-hover1);
}
._9rXAJB3jiq_WUTblVsW6b circle {
  fill: var(--color-primary1);
}

.ZMmHXSVb1ETBU_GArZpGK {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

.ZMmHXSVb1ETBU_GArZpGK::before,
.ZMmHXSVb1ETBU_GArZpGK::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

.ZMmHXSVb1ETBU_GArZpGK::before {
  border-width: 20px;
}

.ZMmHXSVb1ETBU_GArZpGK::after {
  border-width: 20px;
  border-right-color: var(--color-content-highlight-hover);
  border-top-color: var(--color-content-highlight-hover);
}

._3Fj6_DFLFkeP40H3T4DQI- {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}

._2mf7hl7-1hbx6RoKm0rK8j > svg {
  width: 16px;
  fill: var(--color-content-default1);
}
._3TgTPiZzpHSkTti4q3bR90 {
  position: absolute;
  top: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
  right: 0px;
}

._3QUR6LQA7vwol7AkiL2sMN {
  left: 0px;
}
._2TcsvzN_NIpBeU0j7JLA6q {
  right: 0px;
}
._3HJ4_5uYco1n6mLmw1E-Us {
  fill: var(--color-thumbnail-highlight-hover1);
}
