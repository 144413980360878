._1fsEkgGuQaA6njMsueunSh {
  cursor: pointer;
  vertical-align: middle;
}

._3yglfy_Kx1TjVb2yjmMLjD,
._2lTynyeV8pPICOCn0CYcDZ {
  height: 24px;
  width: 24px;
}

._3yglfy_Kx1TjVb2yjmMLjD {
  fill: var(--color-content-default1);
}

._2lTynyeV8pPICOCn0CYcDZ {
  fill: var(--color-content-highlight-hover);
}

._22i7RQaowmU6jOdAyAZno9 {
  line-height: 23px;
}
.gF8lut5R4gFNW3vi-ABKO {
  font-size: var(--fontSize-s);
  font-weight: normal;
}

@media (max-width: 767px) {
  ._1Qyf8DoEiMPj4mUBc9SIi7 {
    display: block;
    text-align: center;
    height: 110px;
    width: 70px;
  }

  ._1Q3wPUj4T-0Zb5_Lt_FTAk {
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    padding: 0;
    margin: 0 auto;
  }

  ._2BQs6q1Axe3duDsqT9aEye,
  ._1_vfF_A0mvDcFPQtcZL9lB {
    height: 24px;
    width: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ._1_vfF_A0mvDcFPQtcZL9lB {
    fill: var(--color-content-default1);
  }

  ._2lTynyeV8pPICOCn0CYcDZ {
    fill: var(--color-content-highlight-hover);
  }

  ._1TUlZPXgw2R6NHV07rArh-,
  .gF8lut5R4gFNW3vi-ABKO {
    padding-top: 10px;
    font-size: var(--fontSize-s);
  }
}
