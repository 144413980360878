._2pjI_SSwsa6yQ9fA9WVCDc, ._1s7VZVByBmXkv7R5B68g9s>p:nth-child(1) {
  color: var(--color-login-primary1);
  font-weight: bold;
}

._1s7VZVByBmXkv7R5B68g9s {
  display: inline-block;
  width: 100%;
}

._2XGHJDQfUvFK6MmhI0gujk {
  width: 140px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

._2XGHJDQfUvFK6MmhI0gujk>img {
  max-width: 100px;
  max-height: 100px;
  margin: 30px auto 3px;
}

._3RyXEzyZgICVIurnSiYA5K ._2j2_6DcAspFwfpgGAEGwMe, ._3RyXEzyZgICVIurnSiYA5K ._66kMClAAGeAMssqfK2jYU, ._3RyXEzyZgICVIurnSiYA5K ._1s7VZVByBmXkv7R5B68g9s {
  display: block;
  width: 100%;
}

._34_dV2aE3GqKU2EMbIe8Y9 {
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

._3ZVHsYxdClpjZq2jDx1k_L {
  width: 100%;
  text-align: center;
}

._2iqPXeO8H4umgCjyihwHI {
  font-size: var(--fontSize-s);
  width: 240px;
  margin: 0;
  margin-top: 50px;
  height: auto;
}

@media (max-width: 767px) {
  ._2iqPXeO8H4umgCjyihwHI {
    width: 180px;
  }
}

._1qVoIg8y4FuXI-ArLXDELO {
  text-align: left;
  display: flex;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  padding-bottom: 20px;
  cursor: pointer;
}

._1qVoIg8y4FuXI-ArLXDELO > img {
  width: 60px;
    height: 60px;
}

._1qVoIg8y4FuXI-ArLXDELO > p {
  font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-primary3);
    margin: auto 144px auto 30px;
}
