._6Z-5pRmwPAP76KnKuwdOb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 0;
  min-height: 100vh;
  text-align: center;
}

._182BxQBerYaawZXLAMyBDM {
  font-size: var(--fontSize-m);
  font-weight: bold;
  color: var(--color-content-default1);
  margin: 50px 0 10px 0;
}

._3yL0WcB0gelMT5JRpoJ0vG {
  position: absolute;
  z-index: 1000;
  min-width: 350px;
}

._1BRkffqm_MqKgYIdQ7q40d {
  width: 100px;
  margin: 20px;
}

.HAxe1bXwKvU75GhF7P9aU {
  margin-top: 10px;
}

._4SoKnP1dIJ9_NGrH8w_WK {
  margin: 15px 0;
}

._2kgWfD33HYw2WJDy1Tcx-c {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
  padding-left: 10px;
}

._2necvHVWmh8VfM38-YJdT6 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
}

._2necvHVWmh8VfM38-YJdT6 > span {
  color: var(--color-content-default1);
  font-size: var(--fontSize-m);
  font-weight: normal;
  margin-left: 10px;
}

.OSB6SoH1bL3Zofz8Daku5 {
  height: 40px;
}

.OSB6SoH1bL3Zofz8Daku5 circle {
  fill: var(--color-content-highlight-hover);
}

.OSB6SoH1bL3Zofz8Daku5 path {
  fill: var(--color-content-overlay3);
}

.j_kOri-u7dJhj5WVBJjdD {
  font-size: var(--fontSize-s)
}

.QkFUH4IRJookPqjnvZISe {
  margin-bottom: 40px;
}

._2COaR36jU5NjzVOnSphv40 {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  padding-left: 20px;
  margin-block-start: 0;
  margin-block-end: 0;
}

@media(max-width: 767px) {

  ._3zrXxLTTHzHhikCGRxtBOu:before {
    top: -200px;
    left: -50px;
  }

  ._3zrXxLTTHzHhikCGRxtBOu:after {
    width: 100px;
  }

}

.lAOuevcz_QLZUxk7a_s8I{
  color:var(--color-input-password-valid-policy);
}