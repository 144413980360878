._3BvqH7x7G_L8wPtCituWl3 {
  color: var(--color-content-default1);
  width: 330px;
  height: auto;
}

._3BeqtekcWoFr7NLA-ANYvA {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  margin-bottom: 55px;
}

._3y414U2AwwRvqsMiTpGx9x {
  font-size: var(--fontSize-s);
  display: block;
  margin: auto;
}

._8VIpdTAXS9ChNvnpQiTte {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  text-align: center;
}

._1rz0ULA22kQKXWBWkdt_yT {
  font-size: var(--fontSize-s);
  color: var(--color-content-highlight-hover);
  font-weight: bold;
  cursor: pointer;
}

.qR9nv-xcU5ywCkSnMKvAZ {
  color: var(--color-content-error);
  font-size: var(--fontSize-s);
  font-weight: 600;
}
