:root {
  --PageCatchupChannel-linkBackPosition: 20px;
  --PageCatchupChannel-linkBackPositionWithoutSlide: 0px;
  --PageCatchupChannel-headerTitle: 80px;
}

._138HUjjt4bO8HaGMvPGmND {
  position: absolute;
  z-index: 20;
  left: 40px;
  top: var(--PageCatchupChannel-linkBackPosition);
}

.P45nKD1NVLIZdAc9u_3mf {
  position: absolute;
  top: 20px;
  right: var(--PageAssetTVShow-linkBackPosition);
  z-index: 20;
}

._2t9N6n0PJjW-3F0bs74xVd {
  position: absolute;
  z-index: 20;
  left: 40px;
  top: var(--PageCatchupChannel-linkBackPositionWithoutSlide);
}

.fcbtj1uCvxTo8_PdY2ThO {
  padding-top: var(--PageCatchupChannel-headerTitle);
}

._1iWYTW5enHtfH80P3gXypd {
  padding-top: 100px;
}

@media (max-width: 767px) {
  :root {
    --PageCatchupChannel-linkBackPosition: 20px;
  }

  ._138HUjjt4bO8HaGMvPGmND {
    left: 16px;
  }
}
